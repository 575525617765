module.exports = {
    addTop: 1,
    addParent: 2,
    addChild: 3,
    addTopCustomize: 4,
    editTop: 5,
    editParent: 6,
    editChild: 7,
    editTopCustomize: 8
}
