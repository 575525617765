<template>
    <div class="content_wrap">
        <div class="page_title">
            <span>푸시전송 내역</span>
        </div>
        <div class="h_scroll pd_15">
            <div class="table_title">
                <p class="dgray_txt">총 <span class="black_txt">21</span>개</p>
            </div>
            <div class="table_header_fix">
                <table class="table_default">
                    <thead>
                        <tr>
                            <th>푸시 설정일</th>
                            <th>연결 페이지(No.제목)</th>
                            <th>푸시 제목</th>
                            <th>푸시 내용</th>
                            <th>전송대상</th>
                            <th>전송일시</th>
                            <th>전송확인</th>
                        </tr>
                    </thead>
                    <tr>
                        <td>2022-11-28 09:00</td>
                        <td><span class="txt_el">25. 문손잡이 못만지는 어쩌고 저쩌공ㄹㅇㄹ</span></td>
                        <td><span class="txt_el">푸시 타이틀 어쩌고 저쩌고 전ㅇㄹㄴㅇㄹㄴㅇㄹ</span></td>
                        <td><span class="txt_el">푸시 내용보여ㅛ주기 ㄴㄹㄴㅇㄹㄴㅇㄹㅇㄴㄹ</span></td>
                        <td>전체</td>
                        <td>2022-11-28 09:30</td>
                        <td>대기</td>
                    </tr>
                    <tr>
                        <td>2022-11-28 09:00</td>
                        <td><span class="txt_el">25. 문손잡이 못만지는 어쩌고 저쩌공ㄹㅇㄹ</span></td>
                        <td><span class="txt_el">푸시 타이틀 어쩌고 저쩌고 전ㅇㄹㄴㅇㄹㄴㅇㄹ</span></td>
                        <td><span class="txt_el">푸시 내용보여ㅛ주기 ㄴㄹㄴㅇㄹㄴㅇㄹㅇㄴㄹ</span></td>
                        <td>전체</td>
                        <td>2022-11-28 09:30</td>
                        <td>전송완료</td>
                    </tr>
                </table>
            </div>
            여기 페이지네이션
        </div>
        <!-- 영상 업로드 팝업
        <div class="pop_overlay_bg">
            <div class="pop_wrap" style="width: 500px;">
                <div class="pop_title">
                    <span class="title">영상 업로드</span>
                    <a class="mgl_auto"><v-icon large>mdi-close</v-icon></a>
                </div>
                <div class="pop_cont">
                    <div class="form_list">
                        <div class="title">영상업로드</div>
                        <div class="cont"><input type="file" name="" id="" class="input_s w_full"></div>
                    </div>
                    <div class="btn_wrap">
                        <button class="btn btn_l btn_blue">등록</button>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {}
</script>
