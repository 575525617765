<template>
    <div class="snb">
        <div class="snb_title">앱 관리</div>
        <a @click="action_AppVersionSetting" class="snb_menu on">앱 버전 관리</a>
    </div>
</template>

<script>
export default {
    methods: {
        action_AppVersionSetting() {
            this.$router.push({ name: 'AppVersionSetting' }).catch(() => {})
        }
    }
}
</script>

<style scoped></style>
