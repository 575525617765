import axios from 'axios'
import URL from './URL'
import Util from './Util'

const find = async function (m) {
    return axios({
        method: 'GET',
        url: URL.VCONTENT_FIND,
        params: m,
        headers: Util.getHeaders()
    })
}

const create = async function (m) {
    return axios({
        method: 'POST',
        url: URL.VCONTENT_CREATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const update = async function (m) {
    return axios({
        method: 'POST',
        url: URL.VCONTENT_UPDATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const remove = async function (m) {
    return axios({
        method: 'POST',
        url: URL.VCONTENT_REMOVE,
        data: m,
        headers: Util.getHeaders()
    })
}

const count = async function (m) {
    return axios({
        method: 'GET',
        url: URL.VCONTENT_COUNT,
        params: m,
        headers: Util.getHeaders()
    })
}

export default {
    find: find,
    create: create,
    update: update,
    remove: remove,
    count: count
}
