import axios from 'axios'
import URL from './URL'
import Util from './Util'

const count = async function (m) {
    return axios({
        method: 'GET',
        url: URL.NOTIFICATION_SOUND_COUNT,
        params: m,
        headers: Util.getHeaders()
    })
}

const find = async function (m) {
    return axios({
        method: 'GET',
        url: URL.NOTIFICATION_SOUND_FIND,
        params: m,
        headers: Util.getHeaders()
    })
}

const create = async function (m) {
    return axios({
        method: 'POST',
        url: URL.NOTIFICATION_SOUND_CREATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const update = async function (m) {
    return axios({
        method: 'POST',
        url: URL.NOTIFICATION_SOUND_UPDATE,
        data: m,
        headers: Util.getHeaders()
    })
}

export default {
    count: count,
    find: find,
    create: create,
    update: update
}
