<template>
    <div class="pop_overlay_bg" v-if="hospitalsuspendpopup.isShow == true">
        <LoadingIndicate :isLoading="isLoading" />
        <div class="hospital_suspend_pop_wrap">
            <PopupTitleSection title="이용 중단" @action_Close="action_closePopup" />
            <div class="pop_cont">
                <div class="form_list">
                    <div class="title">병원명</div>
                    <div class="cont">{{ hospitalName }}</div>
                </div>
                <div class="form_list">
                    <div class="title">서비스 이용중단</div>
                    <div class="cont">
                        <label class="radio_wrap"
                            ><input type="radio" name="radioIsClosed" v-model="radioIsClosed" :value="true" /> <span class="checkmark"></span> 중단</label
                        >
                        <label class="radio_wrap"
                            ><input type="radio" name="radioIsClosed" v-model="radioIsClosed" :value="false" /> <span class="checkmark"></span> 사용</label
                        >
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">전체 서비스 종료</div>
                    <div class="cont">
                        <label class="check_wrap">
                            <input
                                type="checkbox"
                                name="isSetHServiceCloseReason"
                                v-model="isSetHServiceCloseReason"
                                :disabled="hospitalsuspendpopup.isHServiceLeft == false"
                            />
                            <span class="checkmark"></span> 모든 환자 이용중단 (이용 중단 시 수정불가)
                        </label>
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">병원 ID</div>
                    <div class="cont">
                        <input v-model="inputHospitalID" type="text" class="input_s w_full" placeholder="병원 ID를 입력하여 이용 중단" />
                    </div>
                </div>
                <small v-if="isError == true" class="red_txt">{{ errorMessage }}</small>
            </div>
            <div class="btn_wrap">
                <button
                    class="btn_l"
                    :class="{
                        btn_blue: radioIsClosed == true,
                        btn_gray: radioIsClosed == false
                    }"
                    @click="action_suspendHospital()"
                    :disabled="radioIsClosed == false"
                >
                    수정
                </button>
                <button class="btn_l btn_gray" @click="action_closePopup()">닫기</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PopupTitleSection from '@/views/main/ShareComponents/PopupTitleSection.vue'
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'

export default {
    name: 'hospitalSuspendPopup',
    components: {
        LoadingIndicate,
        PopupTitleSection
    },
    data() {
        return {
            hospitalName: '', // hospitalName
            hospitalID: '',
            radioIsClosed: false, // 서비스 이용 중단 radio
            isSetHServiceCloseReason: false, // 전체 서비스 종료 checkbox
            inputHospitalID: '', // 병원 ID 사용자입력

            isError: false,
            errorMessage: '',

            isLoading: false
        }
    },
    watch: {
        'hospitalsuspendpopup.isShow': {
            immediate: true,
            handler: function (newValue) {
                if (newValue == true) {
                    this.initPopup()
                }
            }
        },
        inputHospitalID: {
            immediate: true,
            handler: function () {
                this.isError = false
                this.errorMessage = ''
            }
        }
    },
    computed: {
        ...mapState(['hospitalsuspendpopup'])
    },
    methods: {
        ...mapActions(['setHospitalSuspendPopupState']),

        initPopup() {
            if (this.hospitalsuspendpopup.isShow == true) {
                this.hospitalName = this.hospitalsuspendpopup.selectedHospital.shortName
                this.hospitalID = this.hospitalsuspendpopup.selectedHospital._id
                this.radioIsClosed = this.hospitalsuspendpopup.selectedHospital.isClosed
                this.isSetHServiceCloseReason = false
            } else {
                this.hospitalName = ''
                this.hospitalID = ''
                this.radioIsClosed = false
                ;(this.isSetHServiceCloseReason = false), (this.inputHospitalID = '')
            }
        },

        action_closePopup() {
            this.setHospitalSuspendPopupState({
                isShow: false
            })
            this.initPopup()
        },

        async action_suspendHospital() {
            // check inputHospitalID null, not equal
            if (this.inputHospitalID == null || this.inputHospitalID == '') {
                this.isError = true
                this.errorMessage = '병원 정보를 변경하려면 병원 ID를 정확히 입력해주세요.'
                return
            } else if (this.inputHospitalID != this.hospitalID) {
                this.isError = true
                this.errorMessage = '병원 ID가 일치하지 않습니다.'
                return
            }

            this.isLoading = true

            // 1. set hospital isClosed to true
            console.log('서비스 종료하기')
            let hModel = {
                _id: this.hospitalID,
                isClosed: this.radioIsClosed
            }
            let hResult = await this.API.Hospital.updateHospital(hModel)
            if (hResult.data.code != 0) {
                console.log(hResult)
                this.isLoading = false
                return
            }

            // 2. set hservices push closeReason
            if (this.isSetHServiceCloseReason == true) {
                console.log('전체 서비스 종료 수행')
                let hsModel = { hospitalID: this.hospitalID }
                let hsResult = await this.API.HService.suspendAllHServicesByHospitalID(hsModel)
                if (hsResult.data.code != 0) {
                    console.log(hsResult)
                    this.isLoading = false
                    return
                }
                console.log(hsResult.data.data)
            } else {
                console.log('전체 서비스 종료는 수행하지 않음')
            }
            this.isLoading = false
            this.$emit('initData')
            this.action_closePopup()
        }
    }
}
</script>
