const moment = require('moment')
require('moment-timezone')
moment.tz.setDefault('Asia/Seoul')
moment.locale('ko')

moment.prototype.toString = function (format = undefined) {
    if (format == undefined) {
        return this.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    }
    return this.format(format)
}

module.exports = moment
