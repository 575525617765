<template>
    <div class="cc">
        <Navigation />
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script>
import Navigation from './Navigation/ElectronNavivation'
export default {
    components: { Navigation }
}
</script>
