<template>
    <div class="pop_overlay_bg" v-if="isShow">
        <Loading :isLoading=isLoading />
        <div class="pop_wrap">
            <div class="pop_title">
                <span class="title">상세보기</span>
                    <a class="mgl_auto">
                        <i @click="action_Close" aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light" style="font-size: 36px;">
                        </i>
                    </a>
            </div>
            <div class="notice_preview_wrap">
                <div class="info">
                    <b>미리보기</b>
                    <div> 고객 앱에 노출되는 이벤트 화면을 미리 확인하실 수 있습니다. 미리보기는 참고용이며, 사용자의 디바이스에 따라 다소 차이가 있을 수 있습니다.</div>
                </div>
                <div class="notice_preview">
                    <div class="device_wrap">
                        <div class="statusbar">
                            <small>11:45</small>
                            <span class="mgl_auto">
                                <i aria-hidden="true" class="v-icon notranslate mgl_5 mdi mdi-volume theme--light" style="font-size: 16px;">
                                </i>
                                <i aria-hidden="true" class="v-icon notranslate mgl_5 mdi mdi-signal theme--light" style="font-size: 16px;">
                                </i>
                                <i aria-hidden="true" class="v-icon notranslate mgl_5 mdi mdi-wifi theme--light" style="font-size: 16px;"></i><i aria-hidden="true" class="v-icon notranslate mgl_5 mdi mdi-battery-70 theme--light" style="font-size: 16px;">
                                </i>
                            </span>
                        </div>
                        <div class="topbar">
                            <i aria-hidden="true" class="v-icon notranslate mdi mdi-keyboard-backspace theme--light">
                            </i>
                            <span class="mgl_auto" style="font-size: 16px;">공유하기
                            </span>
                        </div>
                        <div class="view">
                            <div class="content">
                                <div class="event_wrap">
                                    <div class="event_top">
                                        <div class="event_title_wrap">
                                            <div class="title">{{ getTitle }}
                                            </div>
                                            <div class="sub_txt">{{ getDescription }}
                                            </div>
                                        </div>
                                        <div class="price_through">{{ getOriginPrice }}
                                        </div>
                                        <div class="price_wrap">
                                            <div class="left">
                                                <span>{{ getSalePrice }}</span>
                                                <span class="red_txt">{{ getSaleRate }}</span>
                                            </div>
                                            <div class="right">{{ getDate }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="event_cont">
                                        <div>
                                            <img :src="representImageURL" style="width: 100%;">
                                        </div>
                                        <div class="pdt_15" v-if="type=='text'" v-html="contentString"></div>
                                        <div class="event_cont_image" v-if="type=='image'">
                                            <div class="empty_wrap" v-if="contentImageURL == undefined">
                                                <div class="empty"><v-icon>mdi-image</v-icon><br>이미지를 등록해주세요.</div>
                                            </div>
                                            <div v-if="contentImageURL != undefined">
                                                <img :src="contentImageURL" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn_wrap">
                <a class="btn btn_l btn_blue" @click="action_Close">닫기</a>
            </div>
        </div>
    </div>    
</template>

<script>
import Loading from '@/views/main/ShareComponents/LoadingIndicate.vue'
const moment = require('moment')

export default {
    components: { Loading },
    props: { isShow: Boolean, item: String },
    data() {
        return {
            data : undefined
        }
    },
    watch: {
		isShow: {
			immediate: true,
			handler: function(newValue) {
				if(newValue) {
					this.loadEventDetail()
				}
			}
		}
	},
    computed: {
        getTitle: function() {
            if(this.data == undefined || this.data == null ) { return }
            return this.data.title
        },
        getDescription: function() {
            if(this.data == undefined || this.data == null ) { return }
            return this.data.description
        },
        getDate:function() {
            if(this.data == undefined || this.data == null ) { return }
            if(!this.data.isLimitedTime) { return '' }
            let startDate = this.data.startDate
            let endDate = this.data.endDate
            startDate = moment(startDate).format('YYYY-MM-DD')
            endDate = moment(endDate).format('YYYY-MM-DD')
            return `${startDate} ~ ${endDate}`
        },
        getOriginPrice: function() {
            if(this.data == undefined || this.data == null ) { return }
            if(this.data.salePrice == undefined ) { return }
            if(this.data.saleRate == 0) { return }
            return this.data.price.toLocaleString()
        },
        getSalePrice: function() {
            if(this.data == undefined || this.data == null ) { return }
            if(this.data.salePrice == undefined ) { return this.data.price }
            let value = this.data.price - this.data.salePrice
            return `${value.toLocaleString()}원`
        },
        getSaleRate: function() {
            if(this.data == undefined || this.data == null ) { return }
            if(this.data.saleRate == undefined || this.data.saleRate == 0) { return }
            return `${this.data.saleRate}%`
        },
        representImageURL: function() {
            if(this.data == undefined || this.data == null ) {return }
            if (this.data.representImage == undefined) { return undefined }
            if (this.data.representImage.paths == undefined || this.data.representImage.paths.length == 0) { return undefined }
            return this.data.representImage.paths[0]
        },
        contentImageURL: function() {
            if (this.data == undefined || this.data == null ) { return }
            if (this.data.fileIDs == undefined || this.data.fileIDs.length == undefined) { return undefined }
            if (this.data.fileIDs[0] == undefined || this.data.fileIDs[0]._id == undefined || this.data.fileIDs[0]._id == ''){ return undefined }
            if (this.data.fileIDs[0].paths == undefined || this.data.fileIDs[0].paths.length == 0) { return undefined }

            return this.data.fileIDs[0].paths[0]
        },
        contentString: function() {
            if(this.data == undefined || this.data == null ) {return}
            if (this.data.content == undefined || this.data.content == '') { return '<p>내용을 입력해주세요.</p>' }
            return this.data.content
        },
        type: function() {
            if(this.data == undefined || this.data == null ) { return } 
            if(this.data.type == undefined ) { return }
            return this.data.type
        }
    },
    methods: {
        async loadEventDetail() {
			this.isLoading = true
			if(this.item == undefined) { return }
			let model = {
				hospitalEventID: this.item
			}
			let result = await this.API.HospitalEvent.findOne(model)
            
			if(result.data.code != 0) {
				console.log(result)
			}
            this.data = result.data.data
			this.isLoading = false
		},
        action_Close() {
            this.$emit('action_Close');
        }
    }
}
</script>