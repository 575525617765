<template>
    <div class="snb">
        <div class="snb_title">온보딩 설정</div>
        <a @click="action_HospitalType" class="snb_menu on">병원유형 설정</a>
    </div>
</template>

<script>
export default {
    methods: {
        action_HospitalType() {
            this.$router.push({ name: 'HospitalTypeSetting' }).catch(() => {})
        }
    }
}
</script>

<style scoped></style>
