const state = {
    isShow: false,
    selectedHospital: undefined,
    serviceSetting: undefined
}

const actions = {
    setHospitalTreatmentCategoryPopupState: (store, param) => {
        store.commit('muHospitalTreatmentCategoryPopupState', param)
    }
}

const mutations = {
    muHospitalTreatmentCategoryPopupState: (state, param) => {
        state.isShow = param.isShow
        state.selectedHospital = param.selectedHospital
        state.serviceSetting = param.serviceSetting
    }
}

export default { state, actions, mutations }
