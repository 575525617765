<template>
    <div class="pop_overlay_bg" v-if="alfeatureconfigpopup.isShow">
        <ToastComponent :isShow="showErrorMessage" :noticeMessage="`저장되었습니다.`" />
        <div class="featureSetting_pop_wrap">
            <PopupTitleSection title="기능 설정" @action_Close="action_Close" />
            <div class="inner_wrap">
                    <div class="content_wrap"><div class="title_l">전능 문자 발송</div><div class="content">
                        <input type="radio" v-model="isRegEnabled" :value="true" :disabled="isDisabled.isRemoved" />
                        <span class="checkmark"></span> 사용
                        <input type="radio" v-model="isRegEnabled" :value="false" :disabled="isDisabled.isRemoved"/>
                        <span class="checkmark"></span> 미사용
                    </div></div>

                    <div class="content_wrap"><div class="title">예약 관리</div></div>
                    <div class="content_wrap" style="border-top: 1px solid #dcdcdc">
                        <div class="title_l">예약시트 노출</div>
                            <div class="content">
                            <input type="radio" v-model="isReserveEnabled" :value="true" :disabled="isDisabled.isRemoved" />
                            <span class="checkmark"></span> 사용

                            <input type="radio" v-model="isReserveEnabled" :value="false" :disabled="isDisabled.isRemoved" />
                            <span class="checkmark"></span> 미사용
                        </div>
                    </div>

                    <div class="content_wrap">
                        <div class="title_l">예약상태 기본값</div>
                            <div class="custom_select" v-if="isLabelListEmpty">
                                <div class="input_xs w_110" style="background-color:#f2f2f3">
                                    <span class="txt">선택</span>
                                    <span class="chevron"><v-icon>mdi-chevron-down</v-icon></span>
                            </div>
                        </div>
                        <div class="content" v-if="isShowLabel">
                            <AlLabelSelector
                                :value="reservationLabel"
                                :list="labelList"
                                :keyTitle="'name'"
                                @update="updateLabel"
                            />
                        </div>
                    </div>
                    <div class="content_wrap">
                        <div class="title_l">예약취소 필터값</div>
                            <div class="custom_select" v-if="isLabelListEmpty">
                                <div class="input_xs w_110" style="background-color:#f2f2f3">
                                    <span class="txt">선택</span>
                                    <span class="chevron"><v-icon>mdi-chevron-down</v-icon></span>
                                </div>
                            </div>
                        <div class="content_list" v-if="isShowLabel">
                            <AlLabelSelector
                                :value="cancelLabel"
                                :list="labelList"
                                :keyTitle="'name'"
                                @update="updateCancelLabel"
                            />
                            <div class="label_list_wrap" v-if="selectCancelList[0] != [0]"> 
                                <div class="label_list" v-for="(item, idx) in selectCancelList" :key="idx">
                                        <span class="color_circle" :style="selectedItemColorString(item?.color)"></span>{{item?.name}} <v-icon @click="deleteCancelLabel(item)">mdi-close</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content_wrap"><div class="title">대시보드</div></div>
                        <div class="content_wrap" style="border-top: 1px solid #dcdcdc">
                            <div class="title_l">대시보드 노출</div>
                            <div class="content">
                                <input type="radio" v-model="isDashEnabled" :value="true" :disabled="isDisabled.isRemoved"/>
                                <span class="checkmark"></span> 사용
                                <input type="radio" v-model="isDashEnabled" :value="false" :disabled="isDisabled.isRemoved"/>
                                <span class="checkmark"></span> 미사용
                            </div>
                        </div>
                    </div>

            <div class="btn_wrap">
                <button class="btn btn_l btn_gray" @click="action_Close">닫기</button>
                <button class="btn btn_l btn_white" v-if="isDisabled.isRemoved">저장</button>
                <button class="btn btn_l btn_blue" v-if="!isDisabled.isRemoved" @click="action_Save">저장</button> 
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import AlLabelSelector from '../AlLabelSelector.vue'
import ToastComponent from '@/views/main/ShareComponents/ToastComponent.vue'
import PopupTitleSection from '@/views/main/ShareComponents/PopupTitleSection.vue'

export default {
    components : { AlLabelSelector, PopupTitleSection, ToastComponent },

    data() {
        return {
            hospitalmapId: undefined,

            keyTitle: '',
            labelList: [],
            selectCancelList: [],
            cancelLabel: undefined, 
            reservationLabel: undefined,

            // 기능 사용 설정
            isRegEnabled: false,        // 고객 등록 기능 사용 여부 usingRegist
            isReserveEnabled: false,    // 예약 시트 기능 사용 여부 usingReservation
            isDashEnabled: false,       // 대시보드 기능 사용 여부

            isDisabled: {
                isRemoved: false,
                isResDisabled: false
            },

            showErrorMessage: false
        }
    },
    watch: {
        'alfeatureconfigpopup.isShow': {
            immediate: true,
            handler: function(newValue) {
                if(newValue == true) {
                    this.loadIntegration()
                    this.loadAlLabel()
                    this.addWindowEvent()
                }
            }
        },
        cancelLabel: {
            immediate: true,
            deep: true,
            handler: function(newValue, oldValue) {
                if(newValue != oldValue) {
                    for (let i = this.selectCancelList.length - 1; i >= 0; i--) {
                        if (typeof this.selectCancelList[i] !== 'object') { 
                            this.selectCancelList.splice(i, 1)
                        }
                    }

                    let duplicateId = this.selectCancelList.some(item => item.id == newValue.id);
                    if(!duplicateId) {
                        this.selectCancelList.push(newValue) 
                    }
                }
            }
        },
        isReserveEnabled: {
            immediate: true,
            deep: true, 
            handler: function(newValue) {
                if(newValue == true) {
                    this.loadAlLabel()
                    this.isDisabled.isResDisabled = true
                }
                if(newValue == false) {this.isDisabled.isResDisabled = false}
            }
        }
    },
    computed: {
        ...mapState(['alfeatureconfigpopup']),

        hospital: function() {
            let alhospital = this.alfeatureconfigpopup
            if(alhospital == undefined) { return }
            if(alhospital.selectedHospital == undefined) {return}
            return alhospital.selectedHospital
        }, 
        hospitalID: function() {
            if(this.hospital == undefined) {return}
            if(this.hospital._id == undefined) {return }
            return this.hospital._id
        },
        hospitalintegrationSetting: function() {
            if(this.hospital == undefined) {return}
            if(this.hospital.integrationSetting == undefined) {return}
            return this.hospital.integrationSetting
        },
        isShowLabel: function() {
            if(this.labelList.length > 0 && this.isDisabled.isRemoved == false && this.isDisabled.isResDisabled == true) { return true}
            return false
        }, 
        isLabelListEmpty: function() {
            if(this.labelList.length == 0 || this.isDisabled.isRemoved || this.isDisabled.isResDisabled == false) {
               return true
            }  return false
        }
    },
    methods: {
        ...mapActions(['setAlFeatureConfigPopupState']),

        // MARK: - Network
        
        async loadIntegration() {
            let model = {
                hospitalID: this.hospitalID
            }
            let result = await this.API.AlService.getIntegrationSetting(model)
                result = result.data.data
            
            if(result == null) {
                this.isDisabled.isRemoved = true
            } 
            if(result != null) {
                this.hospitalmapId = result._id
                this.isDisabled.isRemoved = result.isRemoved
                this.isRegEnabled = result.usingRegist
                this.isReserveEnabled = result.usingReservation
                this.isDashEnabled = result.usingDashboard

                if(result.usingRegist == undefined || result.usingReservation == undefined || result.usingDashboard == undefined) {
                    this.isReserveEnabled = false
                    this.isDashEnabled = false
                    this.isRegEnabled = false
                }
            }
        },

        // 병원 기능 사용 설정 update
        async API_ALHospitalFeatureConfig() {
            let res = 0
            let resLabel = []

            if (typeof this.reservationLabel == 'object') { res = this.reservationLabel.id } 
            else { res = this.reservationLabel }

            if(res == undefined) { resLabel = [] } 
            else { resLabel.push(res) }

            let cancelLabel = this.selectCancelList.map(item => item.id)
            
            if(this.hospital == undefined) {return}
            if(this.hospital.integrationSetting == undefined) {return}

            let model = {
                _id: this.hospitalmapId,
                isRemoved: this.hospitalintegrationSetting.isRemoved, 
                usingRegist: this.isRegEnabled,
                usingReservation: this.isReserveEnabled,
                usingDashboard: this.isDashEnabled,
                customObject: {
                    defaultLabelID: resLabel,
                    defaultCancelID: cancelLabel
                }
            }

            let result = await this.API.AlService.updateIntegrationSetting(model)
            this.show_ToastMessage()
            if(result.data.code != 0 ) { throw result }
        },

        async loadAlLabel() {
            try {
                if(this.hospitalintegrationSetting == undefined) { return }
                if(this.hospitalintegrationSetting.customObject == undefined) { return }
                if(this.hospitalintegrationSetting.customObject.defaultLabelID == undefined) { return }
                if(this.hospitalintegrationSetting.customObject.defaultCancelID == undefined) { return }                

                let result = await this.API.AlService.getAlLabels()

                if(result.status == 200) {
                    this.$nextTick(() => { 
                        this.labelList = result.data.labels
                        
                        let reservationLavelList = this.hospitalintegrationSetting.customObject.defaultLabelID
                        let selectCancelArray = this.hospitalintegrationSetting.customObject.defaultCancelID
                        reservationLavelList = this.labelList.filter(item => reservationLavelList.includes(item.id));
                        selectCancelArray = this.labelList.filter(item => selectCancelArray.includes(item.id));

                        this.cancelLabel = selectCancelArray[0]
                        this.selectCancelList = selectCancelArray
                        this.reservationLabel = reservationLavelList[0]
                    })
                }
            } catch(err) { console.log(err) }
        },
    
        // MARK: - Action
        async action_Save() {
            try { this.API_ALHospitalFeatureConfig() } 
            catch (error) {console.log('error:', error )}
            
            setTimeout(() => {
                this.action_Close()
                this.$emit('action_Close')
            }, 500)
        },

        async action_Close() {
            this.labelList = []
            this.selectCancelList = []
            this.isRegEnabled = false
            this.isDashEnabled = false
            this.isReserveEnabled = false
            this.isDisabled.isRemoved = true
            this.reservationLabel = undefined

            await this.setAlFeatureConfigPopupState({
                isShow: false,
                selectedHospital: undefined,
                token: undefined
            })
        },
        show_ToastMessage() {
            this.showErrorMessage = true

            setTimeout(() => {
                this.showErrorMessage = false
            }, 1000)
        },

        updateLabel(data) { this.reservationLabel = data },

        updateCancelLabel(data) { this.cancelLabel = data },

        deleteCancelLabel(data) {
            const deleteLabel = this.selectCancelList.findIndex(item => item.id == data.id);
            if (deleteLabel !== -1) { this.selectCancelList.splice(deleteLabel, 1) }
        },

        selectedItemColorString(data) {
            let string = 'background-color: ' + data + ';'
            return string
        },
        
        keyDownHandler(event) { if (event.keyCode == 27) { this.action_Close() } },

        addWindowEvent() { window.addEventListener('keyup', this.keyDownHandler) },
    }
}
</script>

