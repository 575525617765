import File from './File'
import Auth from './Auth'
import Hospital from './Hospital'
import HospitalAuthorizationType from './HospitalAuthorizationType'
import HService from './HService'
import ServiceSetting from './ServiceSetting'
import Nurse from './Nurse'
import TreatmentCategory from './TreatmentCategory'
import HLSVideo from './HLSVideo'
import VideoContentGlobalSetting from './VideoContentGlobalSetting'
import VideoContent from './VideoContent'
import VideoContentHospital from './VideoContentHospital'
import VideoContentCategory from './VideoContentCategory'
import HealthContentPush from './HealthContentPush'
import HealthContent from './HealthContent'
import HealthContentCategory from './HealthContentCategory'
import HospitalContentCategory from './HospitalContentCategory'
import VersionSetting from './VersionSetting'
import ElectronNotification from './ElectronNotification'
import TreatmentCaution from './TreatmentCaution'
import AlService from './AlService'
import AnsweringChat from './AnsweringChat'
import HospitalEvent from './HospitalEvent'
import HospitalEventPush from './HospitalEventPush'
import ReservedSMS from './ReservedSMS'
import CAReservedChat from './CAReservedChat'
import User from './User'

export default {
    File: File,
    Auth: Auth,
    Hospital: Hospital,
    HospitalAuthorizationType: HospitalAuthorizationType,
    HService: HService,
    ServiceSetting: ServiceSetting,
    Nurse: Nurse,
    TreatmentCategory: TreatmentCategory,
    HLSVideo: HLSVideo,
    VideoContentGlobalSetting: VideoContentGlobalSetting,
    VideoContent: VideoContent,
    VideoContentHospital: VideoContentHospital,
    VideoContentCategory: VideoContentCategory,
    HealthContentPush: HealthContentPush,
    HealthContent: HealthContent,
    HealthContentCategory: HealthContentCategory,
    HospitalContentCategory: HospitalContentCategory,
    VersionSetting: VersionSetting,
    ElectronNotification: ElectronNotification,
    TreatmentCaution: TreatmentCaution,
    AlService: AlService,
    AnsweringChat: AnsweringChat,
    HospitalEvent: HospitalEvent,
    HospitalEventPush: HospitalEventPush,
    ReservedSMS: ReservedSMS,
    CAReservedChat: CAReservedChat,
    User: User
}
