import axios from 'axios'
import URL from './URL'
import Util from './Util'


const findOne = async function (m) {
    return axios({
        method: 'GET',
        url: URL.EL_USER_FIND_ONE,
        params: m,
        headers: Util.getHeaders()
    })
}


export default {
    findOne: findOne,
}
