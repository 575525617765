<template>
    <div class="treatment_category_wrap">
        <LoadingIndicate :isLoading="isUploading" />
        <div class="treatment_category_name_section">
            <div class="show_pick_color" :style="getCategoryColor(modifyCategory)" @click="action_ShowPalette" @click.stop="action_ShowPalette">
                <div class="color_palette" v-click-outside="action_HidePalette" v-if="modifyCategory.isShowPalette == true">
                    <div
                        class="color_palette_picker"
                        v-for="(color, i) in basicPalette"
                        :key="i"
                        :style="getPaletteStyle(color, i)"
                        @click="action_SelectColor(i)"
                    />
                </div>
            </div>
            <div class="name">
                <input type="text" :value="modifyCategory.categoryName" @input="action_ChangeName($event.target.value)" placeholder="유형 이름 입력" />
            </div>
            <a @click="action_RemoveCategory"><v-icon>mdi-minus-circle</v-icon></a>
        </div>
        <div v-if="hasCaution == true" class="treatment_category_image_section">
            <div class="file_name">
                <span class="name">{{ cautionFileName }}</span>
                <span class="file_preview">
                    <img :src="cautionFilePath" alt="Treatment type image exists" />
                </span>
            </div>
            <!-- 원래 저장되어 있던 상태 -->
            <a class="file_delete_button" @click="action_DeleteCaution">
                <v-icon>mdi-close-circle</v-icon>
            </a>
        </div>

        <div v-if="hasCaution == false" class="treatment_category_image_section">
            <span v-if="selectedFileViewOnly != null" class="file_name">
                <span class="name">{{ cautionFileName }}</span>
                <span class="file_preview"><img :src="cautionFilePath" alt="Non treatment type img " /></span>

                <a v-if="hasCaution" class="file_delete_button" @click="action_DeleteSelectedFileViewOnly">
                    <v-icon>mdi-close-circle</v-icon>
                </a></span
            >
            <a v-if="hasCaution == false" class="file_add_button" @click="action_CreateCaution">
                <v-icon>mdi-plus</v-icon>
                <small>등록</small>
            </a>
        </div>
        <FilePicker v-model="selectFile" v-bind:only-image="true" v-bind:is-multiple="false" v-bind:is-open="isShowFilePicker" />
    </div>
</template>

<script>
import { FileUploadController } from '@/controller/FileUploadController'
import FilePicker from '@/views/main/ShareComponents/FilePicker.vue'
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'

export default {
    components: {
        FilePicker,
        LoadingIndicate
    },
    props: {
        setting: Object,
        category: Object,
        index: Number
    },
    watch: {
        category: {
            immediate: true,
            deep: true,
            handler: function (newValue) {
                this.modifyCategory = JSON.parse(JSON.stringify(newValue))
            }
        },
        selectFile: {
            immediate: true,
            deep: true,
            handler: async function (newValue) {
                if (newValue == undefined) {
                    return
                }
                this.selectedFileViewOnly = newValue[0]?.name
                await this.changeImageFile(newValue)
                this.isShowFilePicker = false
            }
        }
    },

    computed: {
        hasCaution: function () {
            if (this.modifyCategory == undefined) {
                return false
            }
            if (this.modifyCategory.treatmentCautionID == undefined) {
                return false
            }
            if (this.modifyCategory.treatmentCautionID == null) {
                return false
            }
            return true
        },
        cautionFileName: function () {
            if (this.modifyCategory == undefined) {
                return ''
            }
            if (this.modifyCategory.treatmentCautionID == undefined) {
                return ''
            }
            let caution = this.modifyCategory.treatmentCautionID
            let files = caution.fileIDs
            if (files == undefined || files.length == 0) {
                return ''
            }
            let file = files[0]
            let fileInfos = file.aws
            if (fileInfos == undefined || fileInfos.length == 0) {
                return ''
            }
            let fileInfo = fileInfos[0]
            return fileInfo.fileName
        },
        cautionFilePath: function () {
            if (this.modifyCategory == undefined) {
                return false
            }
            if (this.modifyCategory.treatmentCautionID == undefined) {
                return ''
            }
            let caution = this.modifyCategory.treatmentCautionID
            let files = caution.fileIDs
            if (files == undefined || files.length == 0) {
                return ''
            }
            let file = files[0]
            let fileInfos = file.aws
            if (fileInfos == undefined || fileInfos.length == 0) {
                return ''
            }
            let fileInfo = fileInfos[0]

            return fileInfo.Location
        },
        cautionAWS: function () {
            if (this.caution == undefined) {
                return undefined
            }
            if (this.caution.fileIDs == undefined || this.caution.fileIDs.length <= 0) {
                return
            }
            return this.caution.fileIDs[0].aws
        },
        basicPalette: function () {
            if (this.setting == undefined) {
                return undefined
            }
            if (this.setting.colorPalette == undefined) {
                return undefined
            } else {
                return this.setting.colorPalette.basicPalette
            }
        }
    },
    data() {
        return {
            isUploading: false,
            isShowFilePicker: false,
            selectFile: undefined,
            selectedFileViewOnly: '',
            modifyCategory: {}
        }
    },
    methods: {
        action_ChangeName(name) {
            this.modifyCategory.categoryName = name
            this.$emit('updateItem', this.modifyCategory, this.index)
        },
        action_ShowPalette() {
            this.modifyCategory.isShowPalette = true
            this.$nextTick(() => {
                this.$forceUpdate()
            })
        },
        action_HidePalette() {
            this.modifyCategory.isShowPalette = false

            this.$nextTick(() => {
                this.$forceUpdate()
            })
        },
        action_DeleteCaution() {
            this.modifyCategory.treatmentCautionID = null
            this.$emit('updateItem', this.modifyCategory, this.index)
        },
        action_DeleteSelectedFileViewOnly() {
            this.selectedFileViewOnly = null
            this.modifyCategory.treatmentCautionID = null
            this.$emit('updateItem', this.modifyCategory, this.index)
        },

        action_SelectColor(index) {
            this.modifyCategory.paletteIndex = index
            this.$emit('updateItem', this.modifyCategory, this.index)
            this.modifyCategory.isShowPalette = false
        },

        action_RemoveCategory() {
            this.modifyCategory.isShowPalette = false
            this.$emit('removeCategory', this.modifyCategory, this.index)
        },
        action_CreateCaution() {
            this.isShowFilePicker = true
        },
        changeImageFile(files) {
            if (files == undefined || files.length == 0) {
                return
            }
            let file = files[0]
            this.createTreatmentCaution(file)
        },

        async createTreatmentCaution(file) {
            this.isUploading = true
            let fileModel = await this.uploadFile(file)

            let treatmentCautionModel = {
                hospitalID: this.modifyCategory.hospitalID,
                fileIDs: [fileModel]
            }

            let result = await this.API.TreatmentCaution.create(treatmentCautionModel)
            if (result.data.code != 0) {
                this.isUploading = false
                return
            }

            this.modifyCategory.treatmentCautionID = result.data.data
            this.isUploading = false
            this.$emit('updateItem', this.modifyCategory, this.index)
        },

        async uploadFile(file) {
            return new Promise(function (resolve, reject) {
                let files = [file]
                FileUploadController.uploadFiles(files, [], async (results) => {
                    let datas = results.data
                    if (datas.length == 0) {
                        reject()
                        return
                    }

                    let result = datas[0]
                    if (result.data.code != 0) {
                        reject()
                        return
                    }
                    resolve(result.data.data)
                })
            })
        },

        getCategoryColor(c) {
            let i = c.paletteIndex
            if (i == undefined || i < 0) {
                i = 0
            }
            if (this.basicPalette == undefined) {
                return
            }
            let hex = this.basicPalette[i]
            let border = hex
            if (hex == '#FFFFFF') {
                border = '#dfdfe0'
            }

            return 'background-color: ' + hex + '; border: 1px solid' + border
        },
        getPaletteStyle(_, index) {
            let hex = this.basicPalette[index]
            let border = hex
            if (hex == '#FFFFFF') {
                border = '#dfdfe0'
            }

            return 'background-color: ' + hex + '; border: 1px solid ' + border
        }
    }
}
</script>
