import axios from 'axios'
import URL from './URL'
import Util from './Util'

const findNotClosedHServiceByHospitalID = async function (m) {
    return axios({
        method: 'GET',
        url: URL.HSERVICE_FIND_NOT_CLOSED_BY_HOSPITALID,
        params: m,
        headers: Util.getHeaders()
    })
}

const suspendAllHServicesByHospitalID = async function (m) {
    return axios({
        method: 'POST',
        url: URL.HSERVICE_UPDATE_SUSPEND_ALL_BY_HOSPITALID,
        data: m,
        headers: Util.getHeaders()
    })
}

export default {
    findNotClosedHServiceByHospitalID: findNotClosedHServiceByHospitalID,
    suspendAllHServicesByHospitalID: suspendAllHServicesByHospitalID
}
