<template>
    <div class="content_wrap">
        <Loading :isLoading="isLoading" />
        <div class="page_title"><span>이벤트 푸시 알림 현황</span></div>
        <div class="h_scroll" style="justify-content: space-between">
            <div>
                <div class="table_title">
                    <div class="cont">
                        <p class="dgray_txt">
                            총 <span class="black_txt">{{ hTotalCount }}</span
                            >개
                        </p>
                    </div>
                    <div class="table_title_btn">
                        <input type="text" class="input_s" placeholder="병원명" v-model="keyword.hospital" @keyup.enter="loadData(0)" />
                        <input type="text" class="input_s" placeholder="이벤트 제목" v-model="keyword.event" @keyup.enter="loadData(0)" />
                        <button class="btn_s btn_blue_line mgl_5" @click="loadData(0)">검색</button>
                        <select class="input_s mgl_5" v-model="viewType">
                            <option value="100개씩 보기">100개씩 보기</option>
                            <option value="150개씩 보기">150개씩 보기</option>
                            <option value="200개씩 보기">200개씩 보기</option>
                        </select>
                    </div>
                </div>
                <div id="sot7" class="sot" data-sot-top="1" data-sot-left="3" data-sot-bottom="0" data-sot-right="0">
                    <table class="table_default">
                        <colgroup>
                            <col width="150px" />
                            <col width="150px" />
                            <col width="300px" />
                            <col width="100px" />
                            <col width="80px" />
                            <col width="100px" />
                            <col width="180px" />
                            <col width="140px" />
                            <col width="100px" />
                            <col width="130px" />
                            <col width="110px" />
                            <col width="130px" />
                            <col width="130px" />
                            <col width="110px" />
                            <col width="140px" />
                            <col width="100px" />
                            <col width="120px" />
                            <col width="100px" />
                            <col width="120px" />
                            <col width="120px" />
                            <col width="110px" />
                        </colgroup>
                        <tbody>
                            <tr class="no_hover">
                                <th
                                    @click="action_TableHeader('updatedAt')"
                                    data-row-idx="0"
                                    data-cell-idx="0"
                                    class="sot-top sot-left"
                                    style="top: 0px; left: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >발송일시 <v-icon class="sort_icon ascending_order">{{ changeSortIcon('updatedAt') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    @click="action_TableHeader('hospitalName')"
                                    data-row-idx="0"
                                    data-cell-idx="1"
                                    class="sot-top sot-left"
                                    style="top: 0px; left: 150px"
                                >
                                    <span style="vertical-align: middle"
                                        >병원명 <v-icon class="sort_icon">{{ changeSortIcon('hospitalName') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    @click="action_TableHeader('body')"
                                    data-row-idx="0"
                                    data-cell-idx="2"
                                    class="sot-top sot-left"
                                    style="top: 0px; left: 300px"
                                >
                                    <span style="vertical-align: middle"
                                        >이벤트 제목 <v-icon class="sort_icon">{{ changeSortIcon('body') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    @click="action_TableHeader('pushSendCount')"
                                    data-row-idx="0"
                                    data-cell-idx="3"
                                    class="sot-top sot-left"
                                    style="top: 0px; left: 600px"
                                >
                                    <span style="vertical-align: middle"
                                        >발송회차 <v-icon class="sort_icon">{{ changeSortIcon('pushSendCount') }}</v-icon></span
                                    >
                                </th>
                                <th @click="action_TableHeader('isSendToHospital')" data-row-idx="0" data-cell-idx="4" class="sot-top" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >대상 <v-icon class="sort_icon">{{ changeSortIcon('isSendToHospital') }}</v-icon></span
                                    >
                                </th>
                                <th @click="action_TableHeader('filterTitle')" data-row-idx="0" data-cell-idx="5" class="sot-top" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >조건타입 <v-icon class="sort_icon">{{ changeSortIcon('filterTitle') }}</v-icon></span
                                    >
                                </th>
                                <th data-row-idx="0" data-cell-idx="6" class="sot-top" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >상세조건</span
                                    >
                                </th>
                                <th data-row-idx="0" data-cell-idx="7" class="sot-top" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >고객 가입 일자</span
                                    >
                                </th>
                                <th @click="action_TableHeader('sendUserCount')" data-row-idx="0" data-cell-idx="8" class="sot-top" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >알림 대상 <v-icon class="sort_icon">{{ changeSortIcon('sendUserCount') }}</v-icon></span
                                    >
                                </th>
                                <th @click="action_TableHeader('receiveUserCount')" data-row-idx="0" data-cell-idx="9" class="sot-top" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >알림 받은 유저 <v-icon class="sort_icon">{{ changeSortIcon('receiveUserCount') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    @click="action_TableHeader('receiveUserCountPerSendUserCount')"
                                    data-row-idx="0"
                                    data-cell-idx="10"
                                    class="sot-top"
                                    style="top: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >알림 받은 유저 / 알림 대상<v-icon class="sort_icon">
                                            {{ changeSortIcon('receiveUserCountPerSendUserCount') }}</v-icon
                                        ></span
                                    >
                                </th>
                                <th @click="action_TableHeader('clickPushUserCount')" data-row-idx="0" data-cell-idx="11" class="sot-top" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >알림 클릭 유저<v-icon class="sort_icon">{{ changeSortIcon('clickPushUserCount') }}</v-icon>
                                    </span>
                                </th>
                                <th
                                    @click="action_TableHeader('clickPushUserCountPerReceiveUserCount')"
                                    data-row-idx="0"
                                    data-cell-idx="12"
                                    class="sot-top"
                                    style="top: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >알림 클릭 유저 / 알림 받은 유저
                                        <v-icon class="sort_icon">{{ changeSortIcon('clickPushUserCountPerReceiveUserCount') }}</v-icon></span
                                    >
                                </th>
                                <th @click="action_TableHeader('chatUserCount')" data-row-idx="0" data-cell-idx="13" class="sot-top" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >상담버튼 클릭 유저<v-icon class="sort_icon">{{ changeSortIcon('chatUserCount') }}</v-icon>
                                    </span>
                                </th>
                                <th
                                    @click="action_TableHeader('chatUserCountPerReceiveUserCount')"
                                    data-row-idx="0"
                                    data-cell-idx="14"
                                    class="sot-top"
                                    style="top: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >상담버튼 클릭 유저 / 알림 받은 유저
                                        <v-icon class="sort_icon">{{ changeSortIcon('chatUserCountPerReceiveUserCount') }}</v-icon></span
                                    >
                                </th>
                                <th @click="action_TableHeader('purchaseUserCount')" data-row-idx="0" data-cell-idx="15" class="sot-top" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >구매유저<v-icon class="sort_icon">{{ changeSortIcon('purchaseUserCount') }}</v-icon>
                                    </span>
                                </th>
                                <th
                                    @click="action_TableHeader('purchaseUserCountPerReceiveUserCount')"
                                    data-row-idx="0"
                                    data-cell-idx="16"
                                    class="sot-top"
                                    style="top: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >구매 유저 / 알림 받은 유저<v-icon class="sort_icon">{{
                                            changeSortIcon('purchaseUserCountPerReceiveUserCount')
                                        }}</v-icon>
                                    </span>
                                </th>
                                <th @click="action_TableHeader('purchaseCount')" data-row-idx="0" data-cell-idx="17" class="sot-top" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >구매 횟수<v-icon class="sort_icon"> {{ changeSortIcon('purchaseCount') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    @click="action_TableHeader('purchaseCountPerReceiveUserCount')"
                                    data-row-idx="0"
                                    data-cell-idx="18"
                                    class="sot-top"
                                    style="top: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >구매 횟수 / 알림 받은 유저
                                        <v-icon class="sort_icon">{{ changeSortIcon('purchaseCountPerReceiveUserCount') }}</v-icon></span
                                    >
                                </th>
                                <th @click="action_TableHeader('totalPrice')" data-row-idx="0" data-cell-idx="19" class="sot-top" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >총 금액 <v-icon class="sort_icon">{{ changeSortIcon('totalPrice') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    @click="action_TableHeader('totalPricePerReceiveUserCount')"
                                    data-row-idx="0"
                                    data-cell-idx="20"
                                    class="sot-top"
                                    style="top: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >총 금액 / 알림 받은 유저 <v-icon class="sort_icon">{{ changeSortIcon('totalPricePerReceiveUserCount') }}</v-icon></span
                                    >
                                </th>
                            </tr>
                            <Item v-for="(data, idx) in hData" :key="idx" :data="data" />
                            <tr v-if="hData.length == 0">
                                <td data-row-idx="1" data-cell-idx="0" class="sot-left txt_center" colspan="10">조회된 값이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Pagenation
                v-bind:totalCount="hTotalCount"
                v-bind:currentPage="hCurrentPage"
                v-bind:countPerPage="hCountPerPage"
                v-bind:countPerRow="5"
                v-on:changePage="hChangePage"
            />
        </div>
    </div>
</template>

<script>
import Item from './SubComponent/EventPushStatusListItem.vue'
import PageController from '@/controller/PageController.js'
import Loading from '@/views/main/ShareComponents/LoadingIndicate.vue'
import Pagenation from '@/views/main/ShareComponents/PagenationComponent.vue'

export default {
    components: { Loading, Pagenation, Item },
    data() {
        return {
            isLoading: false,
            keyword: {
                hospital: '',
                event: ''
            },
            viewType: '100개씩 보기',
            sortKey: undefined,
            sortWay: undefined,
            
            // pagenation
            pageController: undefined,
            hData: [],
            hTotalCount: 0,
            hCurrentPage: 0,
            hCountPerPage: 100,
        }
    },
    watch: {
        viewType: {
            immediate: true,
            handler: function (newValue, oldValue) {
                if (oldValue == undefined || newValue == oldValue) {
                    return
                }
                if (newValue == '100개씩 보기') {
                    this.hCountPerPage = 100
                }
                if (newValue == '150개씩 보기') {
                    this.hCountPerPage = 150
                }
                if (newValue == '200개씩 보기') {
                    this.hCountPerPage = 200
                }
                this.hCurrentPage = 0
                this.pageController.countPerPage = this.hCountPerPage
                this.loadData(this.hCurrentPage)
            }
        }
    },
    mounted() {
        this.pageController = new PageController(this.API.HospitalEventPush.find)
        this.pageController.countPerPage = this.hCountPerPage
        this.loadData(this.hCurrentPage)
    },
    methods: {
        // load
        async loadData(page) {
            this.isLoading = true
            this.pageController.filter = {}
            this.pageController.filter.hospitalName = this.keyword.hospital
            this.pageController.filter.eventName = this.keyword.event
            this.pageController.filter.key = this.sortKey,
            this.pageController.filter.way = this.sortWay
            
            await this.pageController.loadPageIndex(page)
            await this.loadPageCount()

            this.hData = this.pageController.data
            this.isLoading = false
        },
        async loadPageCount() {
            this.isLoading = true
            let result = await this.API.HospitalEventPush.count(this.pageController.filter)
            if (result.data.code != 0) {
                console.log(result)
            }
            this.hTotalCount = result.data.data
            this.isLoading = false
        },

        // action
        action_TableHeader(key) {
            if (this.sortKey == key) {
                if (this.sortWay == 1) {
                    this.sortWay = -1
                } else if (this.sortWay == -1) {
                    this.sortKey = undefined
                    this.sortWay = undefined
                } else {
                    this.sortWay = 1
                }
            } else {
                this.sortKey = key
                this.sortWay = 1
            }
            this.loadData(this.hCurrentPage)
        },

        // handler
        hChangePage(page) {
            this.hCurrentPage = page
            this.loadData(this.hCurrentPage)
        },
        changeSortIcon(sort) {
            if (this.sortKey != sort) {
                return ''
            }
            if (this.sortWay == 1) {
                return 'mdi-menu-up'
            }
            return 'mdi-menu-down'
        }
    }
}
</script>
