<template>
    <div class="gnb">
        <div class="logo">
            <img :src="require(`@/assets/img/BaseLogo.png`)" alt="afterdoc_logo" />
        </div>
        <a
            @click="action_HospitalManagement"
            class="gnb_menu"
            :class="{
                on: $router.currentRoute.name == 'HospitalSetting' || $router.currentRoute.name == 'AccountSetting'
            }"
            >병원 관리</a
        >
        <a @click="action_ANurseManagement" class="gnb_menu" :class="{ on: $router.currentRoute.name == 'ANurseAccountSetting' }">서비스 매니저 관리</a>
        <a
            @click="action_VideoContentManagement"
            class="gnb_menu"
            :class="{
                on:
                    $router.currentRoute.name == 'VideoUpload' ||
                    $router.currentRoute.name == 'VideoContentSetting' ||
                    $router.currentRoute.name == 'CategorySetting' ||
                    $router.currentRoute.name == 'ApplicationSetting' ||
                    $router.currentRoute.name == 'DefaultSetting'
            }"
            >홈케어콘텐츠 관리</a
        >
        <!-- <a
            @click="action_HealthcareNewsManagement"
            class="gnb_menu"
            :class="{
                on: $router.currentRoute.name == 'NewsSetting' || $router.currentRoute.name == 'PushHistory'
            }"
            >건강관리뉴스 관리</a
        > -->
        <a @click="action_OnboardingSetting" class="gnb_menu" :class="{ on: $router.currentRoute.name == 'HospitalTypeSetting' }">온보딩 설정</a>
        <a @click="action_AfterdocAppSetting" class="gnb_menu" :class="{ on: $router.currentRoute.name == 'AppVersionSetting' }">앱 관리</a>
        <a
            @click="action_AfterdocElectronSetting"
            class="gnb_menu"
            :class="{
                on: $router.currentRoute.name.includes('NotificationSoundSetting')
            }"
            >일렉트론 설정</a
        >
        <a @click="action_ALManagement" class="gnb_menu" :class="{ on: $router.currentRoute.name == 'ALManagement' }">연동 관리</a>
        <a 
            @click="action_Monitoring" 
            class="gnb_menu" 
            :class="{ 
                on: 
                    $router.currentRoute.name == 'ALManagementMonitoring'  ||
                    $router.currentRoute.name == 'AutoReservedSMS' 
            }"
            >모니터링</a>
        <a @click="action_EventManagement" class="gnb_menu" :class="{ on: $router.currentRoute.name == 'EventState' ||  $router.currentRoute.name == 'EventPushAlarmState'}">이벤트 관리</a>
        <a @click="action_CAReservedChatManagement" class="gnb_menu" :class="{ on: $router.currentRoute.name == 'CAReservedChat' }">예약채팅 관리</a>
    </div>
</template>

<script>
export default {
    methods: {
        action_HospitalManagement() {
            this.$router.push({ name: 'HospitalSetting' }).catch(() => {})
        },
        action_ANurseManagement() {
            this.$router.push({ name: 'ANurseAccountSetting' }).catch(() => {})
        },
        action_VideoContentManagement() {
            this.$router.push({ name: 'VideoUpload' }).catch(() => {})
        },
        // action_HealthcareNewsManagement() {
        //     this.$router.push({ name: 'NewsSetting' }).catch(() => {})
        // },
        action_OnboardingSetting() {
            this.$router.push({ name: 'HospitalTypeSetting' }).catch(() => {})
        },
        action_AfterdocAppSetting() {
            this.$router.push({ name: 'AppVersionSetting' }).catch(() => {})
        },
        action_AfterdocElectronSetting() {
            this.$router.push({ name: 'NotificationSoundSetting' }).catch(() => {})
        },
        action_ALManagement() {
            this.$router.push({ name: 'ALManagement' }).catch(() => {})
        },
        action_Monitoring() {
            this.$router.push({ name: 'ALManagementMonitoring' }).catch(() => {})
        },
        action_EventManagement() {
            this.$router.push({ name: 'EventState'}).catch(() => {})
        },
        action_CAReservedChatManagement() {
            this.$router.push({ name: 'CAReservedChat'}).catch(() => {})
        }
    }
}
</script>

<style scoped></style>
