<template>
    <tr>
        <td>{{ getHospitalName }}</td>
        <td>{{ getHServiceID }}</td>
        <td>{{ getReceiverName }}</td>
        <td :title="this.data.contents?.[0]?.content">{{ getContent }}</td>
        <td>{{ getCaName }}</td>
        <td>{{ getCaCycle }}</td>
        <td>{{ getStatus }}</td>
        <td>{{ getCancelReason }}</td>
        <td :title="this.data.description">{{ getDescription }}</td>
        <td>{{ getCreatedAt }}</td>
        <td>{{ getSendDateTime }}</td>
        <td>{{ getSendedAt }}</td>
        <td>{{ getCanceledAt }}</td>
    </tr>
</template>

<script>
const moment = require('moment')

export default {
    props: {
        data: Object
    },
    data() {
        return {

        }
    },
    computed: {
        getHospitalName: function() {
            return this.data.hospitalName ?? '-'
        },
        getHServiceID: function() {
            return this.data.hserviceID ?? '-'
        },
        getReceiverName: function() {
            return this.data.receiverName ?? '-'
        },
        getReceiverPhone: function() {
            return this.data.receiverPhone ?? '-'
        },
        getContent: function() {
            return  this.getSubStringValue(this.data.contents?.[0]?.content, 25)
        },
        getCaName: function() {
            return this.data.caName ?? '-'
        },
        getCaCycle: function() {
            return this.data.caCycle ?? '-'
        },
        getStatus: function() {
            switch (this.data.status) {
                case 'waiting':     
                    return '대기'
                case 'sended':      
                    return '발송'
                case 'canceled':    
                    return '취소'
                default:            
                    return '-'
            }
        },
        getCancelReason: function() {
            return this.data.cancelReason ?? '-'
        },
        getDescription: function() {
            return  this.getSubStringValue(this.data.description, 25)
        },
        getCreatedAt: function() {
            return this.getDateFormatString(this.data.createdAt)
        },
        getSendDateTime: function() {
            return this.getDateFormatString(this.data.sendDateTime)
        },
        getSendedAt: function() {
            return this.getDateFormatString(this.data.sendedAt)
        },
        getCanceledAt: function() {
            return this.getDateFormatString(this.data.canceledAt)
        },

    },
    methods: {
        getSubStringValue(str, maxLength) {
            let count = str?.length ?? 0
            let returnValue = str?.substring(0, maxLength)?.trimEnd() ?? '-'
            if (count > maxLength) { returnValue += '...' }
            return returnValue
        },
        getDateFormatString(date) {
            if(!date) { return '-' }
            return moment(date).format('YYYY-MM-DD HH:mm')
        }
    }
}
</script>