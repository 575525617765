<template>
    <div class="cc">
        <div class="content_wrap">
            <div class="monitoring_page_title">
                <span class="title">자동응답 메세지 발송 기록</span>
                <span class="description">환자가 채팅예약 후 일정 시간동안 서비스 매니저측에서 응대가 없을 시 발송되는 자동 응답에 대한 기록입니다. </span>
            </div>
            <div class="h_scroll" style="justify-content: space-between">
                <LoadingComponent :isLoading=isLoading />
                <div class="monitoring_wrap">
                    <div class="wrap_header">
                        <div class="wrap_content">
                            <div>총 {{ this.hTotalCount }}건</div>
                        </div>
                        <div class="wrap_content">
                            <button :class="settingInfoStyle" :disabled="selectedHospitalInfo == '' || selectedHospitalInfo == undefined" @click="action_ShowSettingPopup">설정 정보 확인하기</button>
                            <select class="input_s" v-model="selectedStatus">
                                <option :value="null" selected>--선택--</option>
                                <option v-for="(hc, index) in statusData" :key="index" :value="index">
                                    {{ hc.text ? hc.text : '-' }}
                                </option>
                            </select>
                            <div class="search_wrap">
                                <div class="wrap_content">
                                    <div class="title">병원 선택</div>
                                    <input type="text" placeholder="병원명 검색" v-model="keyword" @keyup.enter="searchHospitalName" />
                                </div>
                                <select class="input_s" v-model="selectedHospitalInfo" v-if="searchHospitalList.length != 0">
                                    <option value="" selected>--선택--</option>
                                    <option v-for="(hc, index) in searchHospitalList" :key="index" :value="hc">
                                        {{ hc.shortName }}
                                    </option>
                                </select>
                                <button class="btn_s btn_blue_line mgl_5" @click="searchHospitalName">검색</button>
                            </div>
                        </div>
                    </div>
                    <div class="monitoring_content">
                        <table class="table_default">
                            <thead>
                                <tr>
                                    <th width="100">발송 상태</th>
                                    <th width="150">자동응대 채팅 발송 대상 채팅방 ID</th>
                                    <th>전송된 메세지 내용</th>
                                    <th width="150">발송 생성 시간</th>
                                    <th width="150">목표 발송 시간</th>
                                </tr>
                            </thead>
                            <thead v-if="selectedHospitalInfo == '' || selectedHospitalInfo == undefined">
                                <tr>
                                    <td colspan="5" style="padding: 6px 10px">병원을 선택해주세요.</td>
                                </tr>
                            </thead>
                            <thead v-if="selectedHospitalInfo != '' && selectedHospitalInfo != undefined && hTotalCount == []">
                                <tr>
                                    <td colspan="5" style="padding: 6px 10px">검색된 결과가 존재하지 않습니다.</td>
                                </tr>
                            </thead>
                            <thead v-if="selectedHospitalInfo != '' && selectedHospitalInfo != undefined">
                                <tr v-for="(item, index) in hData" :key="index">
                                    <td>{{ item.status }}</td>
                                    <td>{{ item.chatRoomID }}</td>
                                    <td :title="item.chatObject.content">{{ item.chatObject.content.substring(0, 50) }}...</td>
                                    <td>{{ getDefineMessageTime(item.createdAt) }}</td>
                                    <td>{{ getDefineMessageTime(item.answeringTime) }}</td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <PagenationComponent
                    v-bind:totalCount="hTotalCount"
                    v-bind:currentPage="hCurrentPage"
                    v-bind:countPerPage="hCountPerPage"
                    v-bind:countPerRow="5"
                    v-on:changePage="hChangePage"
                />
            </div>
        </div>
        <Popup :isShow="isShowPopup" :selectedHospital="setSearchHospital" @action_Close="action_Close" />
    </div>
</template>


<script>
import PageController from '../../../../../controller/PageController'
import PagenationComponent from '../../../ShareComponents/PagenationComponent.vue'
import Popup from './Popup/MonitoringSettingPopup.vue'
import LoadingComponent from '@/views/main/ShareComponents/LoadingIndicate.vue'

export default {
    components: { PagenationComponent, Popup, LoadingComponent },
    data() {
        return {
            keyword: '',
            isLoading: false,
            isShowPopup: false,
            selectedStatus: 0,
            searchHospitalList: [],
            setSearchHospital: undefined,
            selectedHospitalInfo: undefined,

            statusData: [
                {
                    status: 'pendig',
                    text: '발송 대기 중'
                },
                {
                    status: 'processing',
                    text: '발송 중'
                },
                { status: 'done', text: '발송완료' },
                { status: 'canceled', text: '발송취소' }
            ],

            pageController: undefined,
            hData: [],
            hTotalCount: 0,
            hCurrentPage: 0,
            hCountPerPage: 10,
        }
    },
    watch: {
        keyword: {
            immediate: true,
            handler: function(newValue) {
                if(newValue == '') {
                    this.searchHospitalList = []
                    this.selectedHospitalInfo = undefined
                    this.hTotalCount = 0
                }
            }
        },
        selectedHospitalInfo: {
            handler: function () {
                this.loadHospital(0)
            }
        },
        selectedStatus: {
            handler: function () {
                this.loadHospital(0)
            }
        }
    },
    mounted() {
        this.pageController = new PageController(this.API.AnsweringChat.checkFind)
        this.pageController.countPerPage = this.hCountPerPage
        this.loadHospital(this.hCurrentPage)
    },
    computed: {
        settingInfoStyle: function() {
            if(this.selectedHospitalInfo != '' && this.selectedHospitalInfo != undefined)
                return 'check_info'
            else {
                return 'unCheck_info'
            }
        }
    },
    methods: {
        async searchHospitalName() {
            let model = {
                keyword: this.keyword
            }
            if (this.keyword == '') { 
                return 
            }
            this.isLoading = true
            let result = await this.API.Hospital.getHospitals(model)
            if (result.data.code != 0) {
                return
            }
            result = result.data.data
            let list = []
            for (let i = 0; i < result.length; i++) {
                list.push({
                    _id: result[i]?._id,
                    shortName: result[i]?.shortName
                })
            }
            this.searchHospitalList = list
            this.selectedHospitalInfo = this.searchHospitalList[0]
            this.isLoading = false
        },
        selectedHospital() {
            this.loadHospital(this.hCurrentPage)
        },
        async loadHospital(page) {
            this.isLoading = true
            this.pageController.filter = {}
            if (this.keyword != '') {
                this.pageController.filter.hospitalID = this.selectedHospitalInfo?._id
                this.pageController.filter.status = this.statusData[this.selectedStatus]?.status
            }
            await this.pageController.loadPageIndex(page)
            await this.loadHospitalCount()
            this.hData = this.pageController.data
            this.isLoading = false
        },
        async loadHospitalCount() {
            this.isLoading = true
            if (this.selectedHospitalInfo != undefined) {
                let result = await this.API.AnsweringChat.checkCount(this.pageController.filter)
                if (result.data.code != 0) {
                    console.log(result)
                    return
                }
                this.hTotalCount = result.data.data
            }
            this.isLoading = false
        },
        hChangePage(page) {
            this.hCurrentPage = page
            this.loadHospital(this.hCurrentPage)
        },
        getDefineMessageTime(u) {
            const utcDate = new Date(u)
            const koreaTime = new Intl.DateTimeFormat('ko-KR', {
                timeZone: 'Asia/Seoul',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                fractionalSecondDigits: 3,
                hour12: false
            }).format(utcDate)
            return koreaTime.slice(0, -7)
        },
        action_ShowSettingPopup() {
            this.setSearchHospital = this.selectedHospitalInfo
            this.isShowPopup = !this.isShowPopup
        },
        action_Close() {
            this.setSearchHospital = undefined
            this.isShowPopup = false
        }
    }
}
</script>

<style>
.monitoring_page_title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 65px;
    padding: 8px 15px;
    background: transparent;
    border-bottom: 1px solid #dfdfe0;
}
.monitoring_page_title .title {
    font-size: 18px;
    font-weight: 600;
}
.monitoring_page_title .description {
    color: #929292;
}
</style>

