<template>
    <div class="snb">
        <div class="snb_title">홈케어콘텐츠 관리</div>
        <a @click="action_VideoUpload" class="snb_menu" :class="{ on: $router.currentRoute.name == 'VideoUpload' }">영상 업로드</a>
        <a @click="action_ContentSetting" class="snb_menu" :class="{ on: $router.currentRoute.name == 'VideoContentSetting' }">홈케어콘텐츠 설정</a>
        <a @click="action_AppSetting" class="snb_menu" :class="{ on: $router.currentRoute.name == 'ApplicationSetting' }">앱 관련 설정</a>
        <hr />
        <a @click="action_CategorySetting" class="snb_menu" :class="{ on: $router.currentRoute.name == 'CategorySetting' }">카테고리 설정</a>
        <a @click="action_DefaultSetting" class="snb_menu" :class="{ on: $router.currentRoute.name == 'DefaultSetting' }">병원별 홈케어 설정</a>
    </div>
</template>

<script>
export default {
    methods: {
        action_VideoUpload() {
            this.$router.push({ name: 'VideoUpload' }).catch(() => {})
        },
        action_ContentSetting() {
            this.$router.push({ name: 'VideoContentSetting' }).catch(() => {})
        },
        action_AppSetting() {
            this.$router.push({ name: 'ApplicationSetting' }).catch(() => {})
        },
        action_CategorySetting() {
            this.$router.push({ name: 'CategorySetting' }).catch(() => {})
        },
        action_DefaultSetting() {
            this.$router.push({ name: 'DefaultSetting' }).catch(() => {})
        }
    }
}
</script>

<style scoped></style>
