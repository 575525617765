<template>
    <div class="snb">
        <div class="snb_title">일렉트론 설정</div>
        <a @click="action_NotificationSound" class="snb_menu" :class="{ on: $router.currentRoute.name == 'NotificationSoundSetting' }">알림 소리 설정</a>
    </div>
</template>

<script>
export default {
    methods: {
        action_NotificationSound() {
            this.$router.push({ name: 'NotificationSoundSetting' }).catch(() => {})
        }
    }
}
</script>
