<template>
    <div class="snb">
        <div class="snb_title">건강관리뉴스 관리</div>
        <a @click="action_NewsSetting" class="snb_menu" :class="{ on: $router.currentRoute.name == 'NewsSetting' }">건강관리뉴스 설정</a>
        <a @click="action_PushHistory" class="snb_menu" :class="{ on: $router.currentRoute.name == 'PushHistory' }">푸시전송 내역</a>
    </div>
</template>

<script>
export default {
    methods: {
        action_NewsSetting() {
            this.$router.push({ name: 'NewsSetting' }).catch(() => {})
        },
        action_PushHistory() {
            this.$router.push({ name: 'PushHistory' }).catch(() => {})
        }
    }
}
</script>

<style scoped></style>
