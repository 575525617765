const state = {
    isShow: false
}

const actions = {
    setCategorySettingParentTypenamePopupState: (store, param) => {
        store.commit('muCategorySettingParentTypenamePopupState', param)
    }
}

const mutations = {
    muCategorySettingParentTypenamePopupState: (state, param) => {
        state.isShow = param.isShow
    }
}

export default { state, actions, mutations }
