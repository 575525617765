import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import LoginPage from '../views/login/LoginPage.vue'
import MainLayer from '../views/main/MainLayer.vue'
import HospitalManagement from '../views/main/SubViews/HospitalManagement/HospitalManagement'
import HospitalSetting from '../views/main/SubViews/HospitalManagement/Views/HospitalSetting'
import AccountSetting from '../views/main/SubViews/HospitalManagement/Views/AccountSetting'
import ANurseManagement from '../views/main/SubViews/ANurseManagement/ANurseManagement'
import ANurseAccountSetting from '../views/main/SubViews/ANurseManagement/Views/ANurseAccountSetting'

import VideoContentManagement from '../views/main/SubViews/VideoContentManagement/VideoContentManagement'
import VideoUpload from '../views/main/SubViews/VideoContentManagement/Views/VideoUpload'
import VideoContentSetting from '../views/main/SubViews/VideoContentManagement/Views/VideoContentSetting'
import CategorySetting from '../views/main/SubViews/VideoContentManagement/Views/CategorySetting'
import ApplicationSetting from '../views/main/SubViews/VideoContentManagement/Views/ApplicationSetting'
import DefaultSetting from '../views/main/SubViews/VideoContentManagement/Views/DefaultSetting'

import HealthcareNewsManagement from '../views/main/SubViews/HealthcareNewsManagement/HealthcareNewsManagement'
import NewsSetting from '../views/main/SubViews/HealthcareNewsManagement/Views/NewsSetting'
import PushHistory from '../views/main/SubViews/HealthcareNewsManagement/Views/PushHistory'
import OnboardingSetting from '../views/main/SubViews/OnboardingSetting/OnboardingSetting'
import HospitalTypeSetting from '../views/main/SubViews/OnboardingSetting/Views/HospitalTypeSetting'
import AfterdocAppSetting from '../views/main/SubViews/AfterdocAppManagement/AfterdocAppSetting'
import AppVersionSetting from '../views/main/SubViews/AfterdocAppManagement/Views/AppVersionSetting'
import ElectronManagement from '../views/main/SubViews/ElectronManagement/ElectronManagement'
import NotificationSoundSetting from '../views/main/SubViews/ElectronManagement/Views/NotificationSoundSetting'
import ALManagement from '../views/main/SubViews/ALManagement/ALManagement.vue'

import MonitoringView from '../views/main/SubViews/MonitoringView/MonitoringView'
import ALManagementMonitoring from '../views/main/SubViews/MonitoringView/Views/ALManagementMonitoring'
import AutoReservedSMS from '../views/main/SubViews/MonitoringView/Views/AutoReservedSMS'

import EventManagement from '../views/main/SubViews/EventManagement/EventManagement.vue'
import EventState from '../views/main/SubViews/EventManagement/Views/EventState.vue'
import EventPushAlarmState from '../views/main/SubViews/EventManagement/Views/EventPushAlarmState'

import CAReservedChatManagement from '../views/main/SubViews/CAReservedChatManagement/CAReservedChatManagement.vue'
import CAReservedChat from '../views/main/SubViews/CAReservedChatManagement/Views/CAReservedChat'

const routes = [
    {
        path: '/',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/main',
        name: 'MainLayer',
        component: MainLayer,
        children: [
            {
                path: '/hospital',
                name: 'HospitalManagement',
                component: HospitalManagement,
                children: [
                    {
                        path: '/hospitalsetting',
                        name: 'HospitalSetting',
                        component: HospitalSetting
                    },
                    {
                        path: '/accountsetting',
                        name: 'AccountSetting',
                        component: AccountSetting
                    }
                ]
            },
            {
                path: '/anurse',
                name: 'ANurseManagement',
                component: ANurseManagement,
                children: [
                    {
                        path: '/anurseaccountsetting',
                        name: 'ANurseAccountSetting',
                        component: ANurseAccountSetting
                    }
                ]
            },
            {
                path: '/videocontent',
                name: 'VideoContentManagement',
                component: VideoContentManagement,
                children: [
                    {
                        path: '/upload',
                        name: 'VideoUpload',
                        component: VideoUpload
                    },
                    {
                        path: '/contentsetting',
                        name: 'VideoContentSetting',
                        component: VideoContentSetting
                    },
                    {
                        path: '/categorysetting',
                        name: 'CategorySetting',
                        component: CategorySetting
                    },
                    {
                        path: '/applicationsetting',
                        name: 'ApplicationSetting',
                        component: ApplicationSetting
                    },
                    {
                        path: '/defaultsetting',
                        name: 'DefaultSetting',
                        component: DefaultSetting
                    }
                ]
            },
            {
                path: '/healthcarenews',
                name: 'HealthcareNewsManagement',
                component: HealthcareNewsManagement,
                children: [
                    {
                        path: '/newssetting',
                        name: 'NewsSetting',
                        component: NewsSetting
                    },
                    {
                        path: '/pushhistory',
                        name: 'PushHistory',
                        component: PushHistory
                    }
                ]
            },
            {
                path: '/onboarding',
                name: 'OnboardingSetting',
                component: OnboardingSetting,
                children: [
                    {
                        path: '/hospitaltype',
                        name: 'HospitalTypeSetting',
                        component: HospitalTypeSetting
                    }
                ]
            },
            {
                path: '/appsetting',
                name: 'AfterdocAppSetting',
                component: AfterdocAppSetting,
                children: [
                    {
                        path: '/versionsetting',
                        name: 'AppVersionSetting',
                        component: AppVersionSetting
                    }
                ]
            },
            {
                path: '/electronmanagement',
                name: 'ElectronManagement',
                component: ElectronManagement,
                children: [
                    {
                        path: '/notificationsoundsetting',
                        name: 'NotificationSoundSetting',
                        component: NotificationSoundSetting
                    }
                ]
            },
            {
                path: '/syncmanagement',
                name: 'ALManagement',
                component: ALManagement
            },
            {
                path: '/monitoringpage',
                name: 'MonitoringView',
                component: MonitoringView,
                children: [
                    {
                        path:'/almanagement',
                        name:'ALManagementMonitoring',
                        component: ALManagementMonitoring
                    },
                    {
                        path:'/autoreservedsmslog',
                        name:'AutoReservedSMS',
                        component: AutoReservedSMS
                    },
                ]
            },
            {
                path:'/eventManagement',
                name: 'EventManagement',
                component: EventManagement,
                children: [
                    {
                        path:'/eventState',
                        name: 'EventState',
                        component: EventState
                    },
                    {
                        path: 'eventPushAlarmState',
                        name: 'EventPushAlarmState',
                        component: EventPushAlarmState
                    }
                ]
            }, 
            {
                path: '/careservedchatmanagement',
                name: 'CAReservedChatManagement',
                component: CAReservedChatManagement,
                children: [
                    {
                        path:'/careservedchat',
                        name:'CAReservedChat',
                        component: CAReservedChat
                    },
                ]
            }
        ]
    }
]

export default new Router({
    // mode: 'history',
    routes
})
