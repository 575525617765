import axios from 'axios'
import URL from './URL'
import Util from './Util'

const getServiceSettings = async function (m) {
    return axios({
        method: 'GET',
        url: URL.SERVICESETTING_FIND,
        params: m,
        headers: Util.getHeaders()
    })
}

const getServiceSettingDetail = async function (m) {
    return axios({
        method: 'GET',
        url: URL.SERVICESETTING_FINDONE,
        params: m,
        headers: Util.getHeaders()
    })
}

const findUsingVC = async function (m) {
    return axios({
        method: 'GET',
        url: URL.SERVICESETTING_FINDUSINGVC,
        params: m,
        headers: Util.getHeaders()
    })
}

const createServiceSetting = async function (m) {
    return axios({
        method: 'POST',
        url: URL.SERVICESETTING_CREATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const createSampleServiceSetting = async function (m) {
    return axios({
        method: 'POST',
        url: URL.SERVICESETTING_CREATE_SAMPLE,
        data: m,
        headers: Util.getHeaders()
    })
}

const updateServiceSetting = async function (m) {
    return axios({
        method: 'POST',
        url: URL.SERVICESETTING_UPDATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const updateSampleServiceSetting = async function (m) {
    return axios({
        method: 'POST',
        url: URL.SERVICESETTING_UPDATE_SAMPLE,
        data: m,
        headers: Util.getHeaders()
    })
}

const count = async function (m) {
    return axios({
        method: 'GET',
        url: URL.SERVICESETTING_COUNT,
        params: m,
        headers: Util.getHeaders()
    })
}

export default {
    getServiceSettings: getServiceSettings,
    getServiceSettingDetail: getServiceSettingDetail,
    findUsingVC: findUsingVC,
    createServiceSetting: createServiceSetting,
    createSampleServiceSetting: createSampleServiceSetting,
    updateServiceSetting: updateServiceSetting,
    updateSampleServiceSetting: updateSampleServiceSetting,
    count: count
}
