const state = {
    isShow: false,
    selectedHospital: undefined,
}

const actions = {
    setHospitalChatPopupState: (store, param) => {
        store.commit('muHospitalChatPopupState', param)
    }
}

const mutations = {
    muHospitalChatPopupState: (state, param) => {
        state.isShow = param.isShow
        state.selectedHospital = param.selectedHospital
    }
}

export default { state, actions, mutations }
