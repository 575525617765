/* eslint-disable key-spacing */
var setValues = function (stateObject, newObject) {
    let keys = Object.keys(newObject)
    for (var i = 0; i < keys.length; i++) {
        let key = keys[i]
        stateObject[key] = newObject[key]
    }
}
const initState = {
    _id: undefined,
    token: undefined,
    phone: undefined,
    type: 0,
    name: undefined,
    gender: undefined,
    birth: undefined,
    needInitial: undefined,

    createdAt: undefined,
    updatedAt: undefined,
    photo: undefined,
    badgeCount: 0,

    deviceTokens: [],
    deviceInfos: {}
}
// user model
const state = {
    _id: undefined,
    token: undefined,
    phone: undefined,
    type: 0,
    name: undefined,
    gender: undefined,
    birth: undefined,

    createdAt: undefined,
    updatedAt: undefined,
    photo: undefined,
    badgeCount: 0,

    deviceTokens: [],
    deviceInfos: {},

    HOSPITAL_ID: undefined
}

const getters = {
    isManager: function (state) {
        if (state.type >= 3000) {
            return true
        } else return false
    }
}

const actions = {
    AC_SetUser: async (store, param) => {
        store.commit('MU_SetUser', param)
    },
    AC_GetUser: (store) => {
        return store.state
    },
    AC_ResetUser: (store, state) => {
        return store.commit('MU_ResetUser', state)
    },
    AC_SetHospitalID: (store, HOSPITAL_ID) => {
        return store.commit('MU_SetHospitalID', HOSPITAL_ID)
    }
}

const mutations = {
    MU_SetUser: (state, param) => {
        setValues(state, param)
    },
    MU_ResetUser: (state) => {
        Object.assign(state, initState)
    },
    MU_SetHospitalID: (state, HOSPITAL_ID) => {
        state.HOSPITAL_ID = HOSPITAL_ID
    }
}

export default { state, getters, actions, mutations }
