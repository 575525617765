import axios from 'axios'
import URL from './URL'
import Util from './Util'

const find = async function (m) {
    return axios({
        method: 'GET',
        url: URL.EVENT_PUSH_FIND,
        params: m,
        headers: Util.getHeaders()
    })
}
const count = async function (m) {
    return axios({
        method: 'GET',
        url: URL.EVENT_PUSH_COUNT,
        params: m,
        headers: Util.getHeaders()
    })
}

export default {
    find: find,
    count: count
}
