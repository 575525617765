<template>
    <div class="pop_overlay_bg" v-if="anurseaccountsettingpopup.isShow == true">
        <div class="nurse_account_pop_wrap">
            <PopupTitleSection :title="popupTitle" @action_Close="action_closePopup" />
            <div class="pop_cont pop_scroll_wrap">
                <div class="pop_scroll">
                    <div class="form_list">
                        <div class="title">아이디</div>
                        <div class="cont">
                            <input v-model="id" type="text" class="input_s" placeholder="영문, 숫자 3~20자리" />
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">비밀번호</div>
                        <div class="cont">
                            <input v-model="password" type="password" class="input_s" placeholder="영문, 숫자 3~20자리" />
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">이름</div>
                        <div class="cont">
                            <input v-model="realName" type="text" class="input_s" placeholder="이름" />
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">고객이 보는 이름</div>
                        <div class="cont">
                            <input v-model="name" type="text" class="input_s" placeholder="고객이 보는 이름" />
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">호칭</div>
                        <div class="cont">
                            <input v-model="jobType" type="text" class="input_s" placeholder="간호사" />
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">유저타입<br />(3000: 서비스 매니저)</div>
                        <div class="cont">
                            <input v-model="type" type="text" class="input_s" disabled />
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">문자 수신 전화번호</div>
                        <div class="cont">
                            <input v-model="phone" type="text" class="input_s" placeholder="예) 07051804070" />
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">프로필 사진</div>
                        <div class="cont">
                            <input type="file" accept="image/*" name="" id="" class="input_s" @change="action_uploadProfilePhoto($event)" />
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">프로필 미리보기</div>
                        <div class="cont">
                            <img
                                v-if="photo != undefined && photo != '' && photoURL != undefined && photoURL != ''"
                                style="max-width: 200px"
                                :src="photoURL"
                                alt=""
                            />
                        </div>
                    </div>
                    <small v-show="isError == true" class="red_txt">{{ errorMessage }}</small>
                    <div class="btn_wrap">
                        <button class="btn btn_l btn_blue" v-if="anurseaccountsettingpopup.selectedANurseID == ''" @click="action_createANurse()">등록</button>
                        <button class="btn btn_l btn_blue" v-if="anurseaccountsettingpopup.selectedANurseID != ''" @click="action_updateANurse()">수정</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PopupTitleSection from '@/views/main/ShareComponents/PopupTitleSection.vue'

export default {
    name: 'ANurseAccountSettingPopup',
    components: {
        PopupTitleSection
    },
    data() {
        return {
            id: '',
            password: '',
            realName: '',
            name: '',
            phone: '',
            photo: '',
            photoURL: '',
            type: 3000,
            jobType: '',

            isError: false,
            errorMessage: ''
        }
    },
    watch: {
        photo: {
            handler: function (newValue) {
                if (newValue != undefined && newValue != '') {
                    this.loadProfilePhoto()
                } else {
                    console.log('current photo is empty')
                }
            }
        },
        'anurseaccountsettingpopup.selectedANurseID': {
            handler: function () {
                this.initPopup()
            }
        }
    },
    computed: {
        ...mapState(['anurseaccountsettingpopup']),
        popupTitle: function () {
            if (this.anurseaccountsettingpopup.selectedANurseID == '') {
                return '서비스 매니저 생성'
            }
            return '서비스 매니저 수정'
        }
    },
    mounted() {},
    methods: {
        ...mapActions(['setANurseAccountSettingPopupState']),
        initPopup() {
            this.id = ''
            this.password = ''
            this.realName = ''
            this.name = ''
            this.phone = ''
            this.photo = ''
            this.photoURL = ''
            this.type = 3000
            this.jobType = '간호사'

            this.isError = false
            this.errorMessage = ''

            // case: update
            if (this.anurseaccountsettingpopup.selectedANurseID != undefined && this.anurseaccountsettingpopup.selectedANurseID != '') {
                this.id = this.anurseaccountsettingpopup.selectedANurse.id
                this.realName = this.anurseaccountsettingpopup.selectedANurse.realName
                this.name = this.anurseaccountsettingpopup.selectedANurse.name

                if (this.anurseaccountsettingpopup.selectedANurse.phone != undefined && this.anurseaccountsettingpopup.selectedANurse.phone != '') {
                    this.phone = this.anurseaccountsettingpopup.selectedANurse.phone
                }
                if (this.anurseaccountsettingpopup.selectedANurse.photo != undefined && this.anurseaccountsettingpopup.selectedANurse.photo != '') {
                    this.photo = this.anurseaccountsettingpopup.selectedANurse.photo
                }
            }
        },

        async loadProfilePhoto() {
            let model = {
                _id: this.photo
            }

            let result = await this.API.File.getFile(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            let file = undefined
            if (result.data.data != undefined && result.data.data.length > 0) {
                file = result.data.data[0]
            }
            if (file.paths != undefined && file.paths.length > 0) {
                this.photoURL = file.paths[0]
            }
            console.log('this.photo is : ' + this.photo)
        },

        async action_uploadProfilePhoto(event) {
            let result = await this.action_UploadImageFile(event)
            if (result != undefined && result != '') {
                this.photo = result._id
            }
        },

        //common upload image method
        async action_UploadImageFile(event) {
            let files = event.currentTarget.files
            let sizes = []

            for (let i = 0; i < files.length; i++) {
                let file = files[i]
                var imageReader = new FileReader()
                imageReader.onload = function (e) {
                    var img = new Image()
                    img.src = e.target.result

                    img.onload = function () {
                        var w = this.width
                        var h = this.height
                        let size = {
                            width: w,
                            height: h
                        }
                        sizes.push(size)
                    }
                }
                // console.log(file)
                imageReader.readAsDataURL(file)
            }
            let mimeType = 'image/*'
            let type = 300
            let userID = this.$store.state.user._id
            let result = await this.MPAWS.uploadFiles(files, sizes, mimeType, type, userID)

            if (result.data.code != 0) {
                console.log(result.data.message)
                return
            }
            console.log(result.data.data)
            // return result.data.data._id
            return result.data.data
        },

        async action_createANurse() {
            // create blabla
            // set Model
            let model = {
                id: this.id,
                password: this.password,
                realName: this.realName,
                name: this.name,
                type: this.type
            }

            if (this.phone != '') {
                model.phone = this.phone
            }

            if (this.jobType == '') {
                this.jobType = '간호사'
            }
            model.jobType = this.jobType

            if (this.photo != '') {
                model.photo = this.photo
            } else {
                // set default profile
                if (process.env.NODE_ENV == 'development') {
                    model.photo = '63dcd6c6db992534cc2601fa'
                }
                if (process.env.NODE_ENV == 'production') {
                    model.photo = '63dceebee8ae550e20576f5f'
                }
            }

            let result = await this.API.Nurse.create(model)
            if (result.data.code != 0) {
                console.log(result)
                this.isError = true
                this.errorMessage = result.data.code.message
                return
            }
            console.log(result.data.data)

            this.$emit('loadANurse')
            this.$emit('loadTotalANurseCount')
            this.closePopup()
        },
        async action_updateANurse() {
            let model = {
                _id: this.anurseaccountsettingpopup.selectedANurseID,
                id: this.id,
                realName: this.realName,
                name: this.name
            }

            if (this.phone != '') {
                model.phone = this.phone
            }

            if (this.password != undefined && this.password != '') {
                model.password = this.password
            }

            if (this.jobType == '') {
                this.jobType = '간호사'
            }
            model.jobType = this.jobType

            if (this.photo != '') {
                model.photo = this.photo
            } else {
                // set default profile
                if (process.env.NODE_ENV == 'development') {
                    model.photo = '63dcd6c6db992534cc2601fa'
                }
                if (process.env.NODE_ENV == 'production') {
                    model.photo = '63dceebee8ae550e20576f5f'
                }
            }

            let result = await this.API.Nurse.update(model)
            if (result.data.code != 0) {
                console.log(result)
                this.isError = true
                this.errorMessage = result.data.code.message
                return
            }
            console.log(result.data.data)

            this.$emit('loadANurse')
            this.$emit('loadTotalANurseCount')
            this.closePopup()
        },

        closePopup() {
            this.initPopup()
            this.setANurseAccountSettingPopupState({
                isShow: false
            })
        },

        action_closePopup() {
            this.closePopup()
        }
    }
}
</script>
