<template>
    <div class="snb">
        <div class="snb_title">모니터링</div>
        <a @click="action_ALManagement" class="snb_menu" :class="{ on: $router.currentRoute.name == 'ALManagementMonitoring' }">자동응답 메세지 발송 기록</a>
        <a @click="action_AutoReservedSMS" class="snb_menu" :class="{ on: $router.currentRoute.name == 'AutoReservedSMS' }">자동 재가입 문자 발송 기록</a>
    </div>
</template>


<script>
export default {
    methods: {
        action_ALManagement() {
            this.$router.push({ name: 'ALManagementMonitoring' }).catch(() => {})
        },
        action_AutoReservedSMS() {
            this.$router.push({ name: 'AutoReservedSMS' }).catch(() => {})
        }
    }
}
</script>

<style scoped></style>