<template>
    <div class="pop_overlay_bg" v-if="isShow">
        <div class="edit_notification_sound_pop_wrap">
            <PopupTitleSection :title="popupTitle" @action_Close="action_Close" />
            <div class="pop_cont">
                <div class="form_list">
                    <div class="title">이름</div>
                    <div class="cont">
                        <input type="text" class="input_s" v-model="name" placeholder="사용자에게 노출될 알림음 이름" />
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">소리파일</div>
                    <div class="cont">
                        <input type="file" accept="audio/*" class="input_s w_full" @change="action_UploadAudio($event)" />
                        <small class="lgray_txt mgt_10 img_preview_wrap"> 현재 저장된 FileID : {{ fileID }} </small>
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">노출여부</div>
                    <div class="cont">
                        <label class="radio_wrap"
                            ><input type="radio" name="isShowInList" v-model="isHidden" :value="false" /> <span class="checkmark"></span> 노출
                        </label>
                        <label class="radio_wrap"
                            ><input type="radio" name="isShowInList" v-model="isHidden" :value="true" /> <span class="checkmark"></span> 미노출
                        </label>
                    </div>
                </div>
                <div class="btn_wrap">
                    <button class="btn btn_l btn_blue" @click="action_Save">저장</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PopupTitleSection from '@/views/main/ShareComponents/PopupTitleSection.vue'

export default {
    props: {
        isShow: Boolean,
        sound: Object
    },
    components: {
        PopupTitleSection
    },
    data() {
        return {
            name: '',
            file: {},
            isHidden: true
        }
    },
    watch: {
        sound: {
            immediate: true,
            deep: true,
            handler: function (newValue) {
                if (newValue != undefined) {
                    this.name = newValue.name
                    this.file = { _id: newValue.fileID }
                    this.Hidden = newValue.isHidden
                } else {
                    this.name = ''
                    this.file = {}
                    this.isHidden = false
                }
            }
        }
    },
    computed: {
        popupTitle: function () {
            if (this.isModify) {
                return '알림 소리 추가'
            }
            return '알림 소리 수정'
        },
        isModify: function () {
            if (this.sound == undefined || this.sound._id == undefined) {
                return false
            }
            return true
        },
        fileID: function () {
            if (this.file == undefined || this.file._id == undefined) {
                return ''
            }
            return this.file._id
        },
        fileName: function () {
            if (this.file == undefined || this.file._id == undefined) {
                return ''
            }
            return 'asdfasdfasdfsa'
        }
    },
    methods: {
        action_Close(needRefresh) {
            this.$emit('close', needRefresh)
        },
        action_Save() {
            if (this.name == undefined || this.name == '') {
                return
            }
            if (this.fileID == undefined) {
                return
            }
            if (this.isModify == false) {
                this.createSound()
            } else {
                this.updateSound()
            }
        },
        async action_UploadAudio(event) {
            let files = event.currentTarget.files
            let mimeType = 'audio/*'
            let type = 500
            let userID = this.$store.state.user._id
            let result = await this.MPAWS.uploadFiles(files, [], mimeType, type, userID)
            if (result.data.code != 0) {
                console.log(result.data.message)
                return
            }
            this.file = result.data.data
        },

        // MARK: - Network
        async createSound() {
            if (this.name == undefined || this.name == '') {
                return
            }
            if (this.file == undefined || this.file._id == undefined || this.file._id == '') {
                return
            }
            let model = {
                name: this.name,
                fileID: this.file._id,
                path: this.file.paths[0],
                isHidden: this.isHIdden
            }
            let result = await this.API.ElectronNotification.create(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.action_Close(true)
        },
        async updateSound() {
            if (this.name == undefined || this.name == '') {
                return
            }
            if (this.file == undefined || this.file._id == undefined || this.file._id == '') {
                return
            }
            let model = {
                _id: this.sound._id,
                name: this.name,
                isHidden: this.isHidden
            }
            if (this.fileID != undefined) {
                model.fileID = this.file._id
                model.path = this.file.paths[0]
            }
            let result = await this.API.ElectronNotification.update(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.action_Close(true)
        }

        // MARK: - FileUpload
    }
}
</script>
