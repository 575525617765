<template>
    <tr :class="{ lgray: sound.isRemoved }">
        <td>{{ name }}</td>
        <td>{{ fileName }}</td>
        <td>{{ isRemoved }}</td>
        <td><a class="btn btn_s btn_gray" @click="action_Modify">수정</a></td>
        <td><a class="btn btn_s btn_gray">삭제</a></td>
    </tr>
</template>

<script>
export default {
    props: {
        sound: Object
    },
    computed: {
        name: function () {
            if (this.sound == undefined) {
                return
            }
            return this.sound.name
        },
        fileName: function () {
            if (this.sound == undefined) {
                return
            }
            if (this.sound.path == undefined) {
                return
            }
            let path = this.sound.path
            let split = path.split('/')
            let lastOne = split[split.length - 1]
            return lastOne
        },
        isRemoved: function () {
            if (this.sound.isRemoved == true) {
                return '미사용'
            }
            return '사용'
        }
    },
    methods: {
        action_Modify() {
            this.$emit('modify', this.sound)
        }
    }
}
</script>
