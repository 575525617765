<template>
    <div class="pop_overlay_bg" v-if="accountsettingpopup.isShow == true">
        <div class="pop_wrap" style="width: 550px">
            <PopupTitleSection :title="popupTitle" @action_Close="action_closePopup" />
            <div class="pop_cont">
                <div class="form_list">
                    <div class="title">병원명</div>
                    <div class="cont">
                        <b>{{ hospitalName }}</b>
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">사용자명(이름)</div>
                    <div class="cont"><input v-model="realName" type="text" class="input_s" placeholder="이름" /></div>
                </div>
                <div class="form_list">
                    <div class="title">고객이 보는 이름</div>
                    <div class="cont"><input v-model="name" type="text" class="input_s" placeholder="고객이 보는 이름" /></div>
                </div>
                <div class="form_list">
                    <div class="title">휴대폰번호<br />(마스터만)</div>
                    <div class="cont"><input v-model="phone" type="text" class="input_s" :disabled="isMaster != true" placeholder="휴대폰번호" /></div>
                </div>
                <div class="form_list">
                    <div class="title">아이디</div>
                    <div class="cont"><input v-model="id" type="text" class="input_s" placeholder="영문, 숫자 3~20자리" /></div>
                </div>
                <div class="form_list">
                    <div class="title">비밀번호</div>
                    <div class="cont" v-if="accountsettingpopup.selectedHWorkerID == undefined || accountsettingpopup.selectedHWorkerID == ''">
                        <input v-model="password" type="password" class="input_s" placeholder="영문, 숫자 3~20자리" />
                    </div>
                    <div class="cont" v-if="accountsettingpopup.selectedHWorkerID != undefined && accountsettingpopup.selectedHWorkerID != ''">
                        <input v-model="password" type="password" class="input_s" placeholder="비밀번호 변경 시 입력" />
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">직책/직급</div>
                    <div class="cont">
                        <select v-model="inputJobType" class="input_s w_150">
                            <option :value="null" selected>--선택--</option>
                            <option value="대표원장님">대표원장님</option>
                            <option value="원장님">원장님</option>
                            <option value="팀장">팀장</option>
                            <option value="실장">실장</option>
                            <option value="직원">직원</option>
                            <option value="상담실장">상담실장</option>
                            <option value="치료사">치료사</option>
                            <option value="(병원)">(병원)</option>
                            <option value="직접입력">직접입력</option>
                        </select>
                        <input type="text" class="input_s w_150" placeholder="직접입력" :disabled="inputJobType != '직접입력'" v-model="textInputJobType" />
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">유저타입<br />(2200: 의사)</div>
                    <div class="cont"><input v-model="type" type="text" class="input_s" disabled /></div>
                </div>
                <div class="form_list">
                    <div class="title">권한</div>
                    <div v-if="authorizationTypeID == undefined || authorizationTypeID == ''" class="cont">
                        <label class="radio_wrap" v-for="at in authorizationTypeIDs" :key="at._id">
                            <input v-model="authorizationTypeID" type="radio" :value="at._id" />
                            <span class="checkmark"></span>{{ at.name }}
                        </label>
                    </div>
                    <div v-if="authorizationTypeID != undefined && authorizationTypeID != ''" class="cont">
                        <label class="radio_wrap" v-for="at in authorizationTypeIDs" :key="at._id">
                            <input v-model="authorizationTypeID" type="radio" :value="at._id" :checked="at._id == authorizationTypeID" />
                            <span class="checkmark"></span>{{ at.name }}
                        </label>
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">담당의 구분</div>
                    <div class="cont">
                        <label class="radio_wrap">
                            <input type="radio" name="isDoctor" v-model="isDoctor" :value="true" />
                            <span class="checkmark"></span>네
                        </label>
                        <label class="radio_wrap">
                            <input type="radio" name="isDoctor" v-model="isDoctor" :value="false" />
                            <span class="checkmark"></span>아니오
                        </label>
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">초기계정설정<br />(마스터만)</div>
                    <div class="cont">
                        <label class="check_wrap">
                            <input type="checkbox" name="needInitial" v-model="needInitial" :disabled="isMaster != true" />
                            <span class="checkmark"></span>초기 설정으로 돌아갑니다.
                        </label>
                    </div>
                </div>
                <small v-show="isError == true" class="red_txt">{{ errorMessage }}</small>
                <div class="btn_wrap">
                    <button class="btn btn_l btn_blue" v-if="accountsettingpopup.selectedHWorkerID == undefined" @click="action_createAccount()">추가</button>
                    <button class="btn btn_l btn_blue" v-if="accountsettingpopup.selectedHWorkerID != undefined" @click="action_updateAccount()">수정</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import PopupTitleSection from '@/views/main/ShareComponents/PopupTitleSection.vue'

export default {
    name: 'accountSettingPopup',

    data() {
        return {
            hospitalName: '',
            name: '',
            id: '',
            password: '',
            phone: '',

            inputJobType: '',
            textInputJobType: '',
            selectedJobType: '',

            type: 2200,
            authorizationTypeIDs: [],
            authorizationTypeID: '',
            // isMaster: false,
            isDoctor: false,
            needInitial: false, // 계정 생성 시 초기 설정 기본값

            isError: false,
            errorMessage: ''
        }
    },
    components: {
        PopupTitleSection
    },
    watch: {
        inputJobType: {
            immediate: true,
            handler: function (newValue) {
                switch (newValue) {
                    case '대표원장님':
                    case '원장님':
                    case '팀장':
                    case '실장':
                    case '직원':
                    case '상담실장':
                    case '치료사':
                    case '(병원)':
                        this.inputJobType = newValue
                        this.textInputJobType = ''
                        this.selectedJobType = newValue
                        break
                    case '직접입력':
                        this.inputJobType = '직접입력'
                        break
                    default:
                        this.inputJobType = null
                        this.textInputJobType = ''
                        this.selectedJobType = '대표원장님'
                }
                this.$forceUpdate()
            }
        },
        textInputJobType: {
            immediate: true,
            handler: function (newValue) {
                if (this.inputJobType == '직접입력') {
                    this.selectedJobType = newValue
                }
            }
        },
        'accountsettingpopup.selectedHospital': {
            handler: function () {
                this.loadAuthorizationType()
                this.initPopup()
            }
        },
        'accountsettingpopup.selectedHWorkerID': {
            handler: function () {
                this.initPopup()
            }
        }
    },
    computed: {
        ...mapState(['accountsettingpopup']),
        popupTitle: function () {
            if (this.accountsettingpopup.selectedHWorkerID == undefined) {
                return '계정 추가'
            }
            return '계정 수정'
        },
        isMaster: function () {
            for (let i = 0; i < this.authorizationTypeIDs.length; i++) {
                if (this.authorizationTypeID == this.authorizationTypeIDs[i]._id) {
                    if (this.authorizationTypeIDs[i].name == '마스터') {
                        return true
                    }
                }
            }
            return false
        }
    },
    mounted() {},
    methods: {
        ...mapActions(['setAccountSettingPopupState']),
        initPopup() {
            // 팝업에 공통적으로 들어가는것들. 권한 로드, 병원이름,
            this.hospitalName = this.accountsettingpopup.selectedHospital.shortName
            this.realName = ''
            this.name = ''
            this.phone = ''
            this.id = ''
            this.password = ''
            this.inputJobType = null
            this.textInputJobType = ''
            this.selectedJobType = ''

            this.isDoctor = true
            this.needInitial = false

            this.isError = false
            this.errorMessage = ''

            // this.authorizationTypeIDs = []
            this.authorizationTypeID = ''
            this.authorizationType = {}

            // 수정일 경우 데이터 입력
            if (this.accountsettingpopup.selectedHWorkerID != undefined && this.accountsettingpopup.selectedHWorkerID != '') {
                this.realName = this.accountsettingpopup.selectedHWorker.realName
                this.name = this.accountsettingpopup.selectedHWorker.name
                this.id = this.accountsettingpopup.selectedHWorker.id
                // password 는 새로 입력만 가능하게끔.

                if (this.accountsettingpopup.selectedHWorker.phone != undefined && this.accountsettingpopup.selectedHWorker.phone != '') {
                    this.phone = this.accountsettingpopup.selectedHWorker.phone
                }

                // set jobtype
                let HWorkerJobType = this.accountsettingpopup.selectedHWorker.jobType
                if (HWorkerJobType == undefined || HWorkerJobType == '') {
                    this.inputJobType = '대표원장님'
                    this.textInputJobType = ''
                    this.selectedJobType = '대표원장님'
                } else if (
                    HWorkerJobType == '대표원장님' ||
                    HWorkerJobType == '원장님' ||
                    HWorkerJobType == '팀장' ||
                    HWorkerJobType == '실장' ||
                    HWorkerJobType == '직원' ||
                    HWorkerJobType == '상담실장' ||
                    HWorkerJobType == '치료사' ||
                    HWorkerJobType == '(병원)'
                ) {
                    this.inputJobType = HWorkerJobType
                    this.textInputJobType = ''
                    this.selectedJobType = HWorkerJobType
                } else {
                    this.inputJobType = '직접입력'
                    this.textInputJobType = HWorkerJobType
                    this.selectedJobType = HWorkerJobType
                }

                if (
                    this.accountsettingpopup.selectedHWorker.authorizationTypeID != undefined &&
                    this.accountsettingpopup.selectedHWorker.authorizationTypeID != ''
                ) {
                    this.authorizationTypeID = this.accountsettingpopup.selectedHWorker.authorizationTypeID._id.toString()
                }

                this.isDoctor = this.accountsettingpopup.selectedHWorker.isDoctor
                this.needInitial = this.accountsettingpopup.selectedHWorker.needInitial
            }
            this.$forceUpdate()
        },
        closePopup() {
            this.initPopup()
            this.setAccountSettingPopupState({
                selectedHospital: this.accountsettingpopup.selectedHospital,
                selectedHospitalID: this.accountsettingpopup.selectedHospitalID,
                isShow: false
            })
            // this.accountsettingpopup.isShow = false
        },
        async loadAuthorizationType() {
            let model = { hospitalID: this.accountsettingpopup.selectedHospitalID }
            let result = await this.API.HospitalAuthorizationType.find(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data.data)
            this.authorizationTypeIDs = result.data.data
            this.$forceUpdate()
        },
        action_closePopup() {
            this.closePopup()
        },
        async action_createAccount() {
            // TODO : null check, id/pw/phone regex

            // set model
            let model = {
                hospitalID: this.accountsettingpopup.selectedHospitalID,
                realName: this.realName,
                name: this.name,
                id: this.id,
                password: this.password,
                type: this.type, // is this default? any select option?
                jobType: this.selectedJobType,
                authorizationTypeID: this.authorizationTypeID,
                isDoctor: this.isDoctor,
                needInitial: this.needInitial
            }

            // set default user photo
            // TODO : file findById, if result does not exist, upload file and set
            // this.API.File.findOne({_id:FileID})
            if (process.env.NODE_ENV == 'development') {
                model.photo = '63dcd6c6db992534cc2601fa'
            }
            if (process.env.NODE_ENV == 'production') {
                model.photo = '63dceebee8ae550e20576f5f'
            }

            // master 계정일때만 phone 입력 가능
            if (this.isMaster) {
                model.phone = this.phone
            }

            let result = await this.API.Hospital.createDoctor(model)
            if (result.data.code != 0) {
                console.log(result)
                console.log('err')
                this.isError = true
                this.errorMessage = result.data.message
                return
            }
            console.log(result.data.data)

            // reload Hospital, HWorkers
            this.$emit('loadHospitals')
            this.$emit('loadHWorkersByHospital', this.accountsettingpopup.selectedHospital)
            this.closePopup()
        },
        async action_updateAccount() {
            // TODO : null check, id/pw/phone regex

            // set model
            let model = {
                _id: this.accountsettingpopup.selectedHWorkerID,
                realName: this.realName,
                name: this.name,
                id: this.id,
                jobType: this.selectedJobType,
                authorizationTypeID: this.authorizationTypeID,
                isDoctor: this.isDoctor,
                needInitial: this.needInitial
            }

            // master 계정일때만 phone 입력
            if (this.isMaster) {
                model.phone = this.phone
            }

            // 비밀번호 입력되어 수정했을 때만 업데이트
            if (this.password != undefined && this.password != '') {
                model.password = this.password
            }

            let result = await this.API.Hospital.updateDoctor(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data.data)

            // reload Hospital, HWorkers
            this.$emit('loadHospitals')
            this.$emit('loadHWorkersByHospital', this.accountsettingpopup.selectedHospital)
            this.closePopup()
        }
    }
}
</script>
