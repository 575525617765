<template>
    <div class="pop_overlay_bg" v-show="categorysettingparenttypenamepopup.isShow == true">
        <div class="pop_wrap pop_noti">
            <div class="cont">선택한 대 카테고리에 속한 중 카테고리들의 명칭이 '{{ typeName }}' 값으로 변경됩니다. 계속하시겠습니까?</div>
            <div class="pop_noti_btn_wrap">
                <button class="btn btn_s btn_gray" @click="action_Left">아니오</button>
                <button class="btn btn_s btn_blue" @click="action_Right">네</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    name: 'categorySettingParentTypenamePopup',
    props: {
        selectedTopCategory: String,
        parentCategories: Array,
        typeName: String
    },
    watch: {},
    computed: {
        ...mapState(['categorysettingparenttypenamepopup'])
    },
    methods: {
        ...mapActions(['setCategorySettingParentTypenamePopupState']),
        // closePopup
        closePopup() {
            this.setCategorySettingParentTypenamePopupState({
                isShow: false
            })
        },
        action_Left() {
            this.closePopup()
        },
        // updateCategoryTypeName
        async action_Right() {
            // pull _ids from parent categories
            let parentCategoryIDs = this.parentCategories.map((category) => category._id.toString())
            let model = {
                _id: parentCategoryIDs, // parentIDs
                typeName: this.typeName
            }
            // updateMany API call
            let result = await this.API.VideoContentCategory.updateTypeName(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data.data)

            // reload ParentCategories
            this.$emit('loadParentCategories', this.selectedTopCategory)
            this.closePopup()
        }
        // ...mapActions(['set'])
    }
}
</script>
