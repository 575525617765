<template>
    <div class="snb">
        <div class="snb_title">서비스 매니저 관리</div>
        <a @click="action_ANurseAccountSetting" class="snb_menu" :class="{ on: $router.currentRoute.name == 'ANurseAccountSetting' }">계정 설정</a>
    </div>
</template>

<script>
export default {
    methods: {
        action_ANurseAccountSetting() {
            this.$router.push({ name: 'ANurseAccountSetting' }).catch(() => {})
        }
    }
}
</script>

<style scoped></style>
