<template>
    <div id="app">
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script>
export default {
    name: 'App'
}
</script>

<style lang="scss">
@import './scss/main.scss';
</style>
