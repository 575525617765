<template>
    <div class="loading_background" v-if="isLoading">
        <div class="loading_wrap">
            <div class="loading_obj">
                <svg width="60px" height="60px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg
                ><br />
                <p>잠시만 기다려주세요.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isLoading: Boolean
    }
}
</script>
