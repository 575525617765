const state = {
    isShow: false,
    selectedHospital: {},
    selectedHospitalID: '',
    selectedHWorker: {},
    selectedHWorkerID: ''
}

const actions = {
    setAccountSettingPopupState: (store, param) => {
        store.commit('muAccountSettingPopupState', param)
    }
}

const mutations = {
    muAccountSettingPopupState: (state, param) => {
        state.isShow = param.isShow
        state.selectedHospital = param.selectedHospital
        state.selectedHospitalID = param.selectedHospitalID
        state.selectedHWorker = param.selectedHWorker
        state.selectedHWorkerID = param.selectedHWorkerID
    }
}

export default { state, actions, mutations }
