<template>
    <div class="content_wrap">
        <div class="page_title">
            <span>홈케어콘텐츠 설정</span>
        </div>

        <!-- video content list -->
        <div class="h_scroll pd_15">
            <div class="table_title">
                <select class="input_s w_270" v-if="videoContentCategories.length == 0">
                    <option value="전체" selected>전체</option>
                    <option value="전체">컨디셔닝</option>
                    <option value="전체">통증 및 재활</option>
                    <option value="전체">여성건강</option>
                    <option value="전체">소아청소년성장</option>
                </select>
                <select
                    class="input_s w_270"
                    v-if="videoContentCategories.length > 0"
                    v-model="selectedVideoContentCategoryID"
                    @change="action_SelectVideoContentCategory($event)"
                >
                    <option :value="null" selected>전체</option>
                    <option v-for="vcc in videoContentCategories" :key="vcc._id" :value="vcc._id">{{ vcc.categoryName }}</option>
                </select>
                <!-- video content category list-->
                <div class="table_title_btn">
                    <input type="text" class="input_s" v-model="keyword" placeholder="메인/서브타이틀 검색" @keyup.enter="action_SearchByKeyword($event)" />
                    <button class="btn_s btn_blue_line mgl_5" @click="action_SearchByKeyword($event)">검색</button>
                    <button class="btn btn_s btn_blue" @click="action_ShowCreateVideoContentPopup">추가</button>
                </div>
            </div>
            <div class="table_header_fix">
                <table class="table_default" v-if="vcData.length == 0">
                    <thead>
                        <tr>
                            <th width="60px">No.</th>
                            <th>서브타이틀</th>
                            <th>메인타이틀</th>
                            <th>영상명</th>
                            <th>대카테고리</th>
                            <th>중카테고리</th>
                            <th>소카테고리</th>
                            <th>노출여부</th>
                            <th>최초 작성</th>
                            <th>최종 수정</th>
                            <th>수정</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tr>
                        <td colspan="12">등록된 홈케어 콘텐츠 또는 검색 내용이 없습니다.</td>
                    </tr>
                </table>
                <table class="table_default" v-if="vcData.length > 0">
                    <thead>
                        <tr>
                            <th width="60px">No.</th>
                            <th>서브타이틀</th>
                            <th>메인타이틀</th>
                            <th>영상명</th>
                            <th>대카테고리</th>
                            <th>중카테고리</th>
                            <th>소카테고리</th>
                            <th>노출여부</th>
                            <th>최초 작성</th>
                            <th>최종 수정</th>
                            <th>수정</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tr v-for="vc in vcData" :key="vc._id" :class="{ lgray: vc.isHidden }">
                        <td>{{ vc.seq }}</td>
                        <td>{{ vc.subtitle }}</td>
                        <td>{{ vc.title }}</td>
                        <td>{{ vc.videoFileID.title }}</td>
                        <td>{{ findVideoContentCategoryName('top', vc.categories) }}</td>
                        <td>{{ findVideoContentCategoryName('parent', vc.categories) }}</td>
                        <td>{{ findVideoContentCategoryName('child', vc.categories) }}</td>
                        <td>
                            <label :for="vc._id" class="switch_wrap">
                                <input
                                    type="checkbox"
                                    :id="vc._id"
                                    v-model="vc.isHidden"
                                    true-value="false"
                                    false-value="true"
                                    @change="action_updateHidden(vc)" />
                                <span class="slider round"> </span
                            ></label>
                        </td>
                        <td>{{ ISODatetoDate(vc.createdAt) }}</td>
                        <td>{{ ISODatetoDate(vc.updatedAt) }}</td>
                        <td><button class="btn btn_s btn_blue_line" @click="action_ShowEditVideoContentPopup(vc)">수정</button></td>
                        <td><button class="btn btn_s btn_gray" @click="action_ShowRemoveVideoContentPopup()">삭제</button></td>
                    </tr>
                </table>
            </div>
            <Pagenation
                v-bind:totalCount="vcTotalCount"
                v-bind:currentPage="vcCurrentPage"
                v-bind:countPerPage="vcCountPerPage"
                v-bind:countPerRow="5"
                v-on:changePage="vcChangePage"
            />
        </div>
        <!-- add, edit popup -->
        <div class="pop_full" v-if="popup_isShow">
            <div class="content_wrap">
                <div class="page_title">
                    <span v-if="isEmptyObject(selectedVideoContent)">홈케어 콘텐츠 추가</span>
                    <span v-if="!isEmptyObject(selectedVideoContent)">홈케어 콘텐츠 수정</span>
                    <a class="close mgl_auto" @click="action_ClosePopup"><v-icon>mdi-close</v-icon> 닫기</a>
                </div>
                <div class="cc">
                    <div class="content_wrap">
                        <div class="h_scroll_fix">
                            <div class="w_940">
                                <table class="table_default no_hover pd_0 none_border">
                                    <tr>
                                        <td>
                                            <b class="dgray_txt">등록된 홈케어 선택<span class="red_txt">*</span></b>
                                        </td>
                                        <td>
                                            <select name="" id="" class="input_s w_full" v-if="hlsvideos.length == 0">
                                                <option value="">홈케어 콘텐츠 선택</option>
                                                <option value="">영상 파일 로드 실패. 새로고침 필요</option>
                                            </select>
                                            <select
                                                name=""
                                                id=""
                                                class="input_s w_full"
                                                v-if="hlsvideos.length > 0"
                                                v-model="popup_selectedHLSVideoID"
                                                @change="action_SelectPopupHLSVideo($event)"
                                            >
                                                <option value="">홈케어 콘텐츠 선택</option>
                                                <option v-for="v in hlsvideos" :key="v._id" :value="v._id">{{ v.title }}</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b class="dgray_txt">썸네일 이미지 업로드<span class="red_txt">*</span></b>
                                        </td>
                                        <td>
                                            <input type="file" accept="image/*" class="input_s w_full" @change="action_uploadThumbnailFile($event)" />
                                            <small
                                                @mouseenter="popupThumbnailFileHover('on')"
                                                @mouseleave="popupThumbnailFileHover('off')"
                                                class="lgray_txt mgt_10 img_preview_wrap"
                                            >
                                                현재 저장된 FileID : {{ popup_thumbnailFileID }}
                                                <span class="img_preview">
                                                    <img
                                                        v-if="popup_thumbnailFileHoverStatus == true && popup_thumbnailFilePaths != ''"
                                                        :src="popup_thumbnailFilePaths"
                                                        alt=""
                                                    />
                                                </span>
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b class="dgray_txt">서브 제목<span class="red_txt">*</span></b>
                                        </td>
                                        <td>
                                            <input type="text" class="input_s w_full" placeholder="서브 제목 입력" v-model="popup_subtitle" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b class="dgray_txt">메인 제목<span class="red_txt">*</span></b>
                                        </td>
                                        <td>
                                            <input type="text" class="input_s w_full" placeholder="메인 제목 입력" v-model="popup_title" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b class="dgray_txt">대 카테고리<span class="red_txt">*</span></b>
                                        </td>
                                        <td>
                                            <select
                                                name=""
                                                id=""
                                                class="input_s w_270"
                                                v-model="popup_selectedTopCategory"
                                                @change="action_SelectPopupTopVCCategory($event)"
                                            >
                                                <option :value="null" selected>--선택--</option>
                                                <option v-for="tc in popup_topCategory" :key="tc._id" :value="tc._id">
                                                    {{ tc.categoryName }}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b class="dgray_txt">중 카테고리<span class="red_txt">*</span></b>
                                        </td>
                                        <td>
                                            <select
                                                name=""
                                                id=""
                                                class="input_s w_270"
                                                v-model="popup_selectedParentCategory"
                                                @change="action_SelectPopupParentVCCategory($event)"
                                            >
                                                <option :value="null" selected>--선택--</option>
                                                <option v-show="popup_parentCategory != undefined && popup_parentCategory.length == 0" disabled>
                                                    카테고리 없음
                                                </option>
                                                <option v-for="pc in popup_parentCategory" :key="pc._id" :value="pc._id">
                                                    {{ pc.categoryName }}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b class="dgray_txt">소 카테고리<span class="red_txt">*</span></b>
                                        </td>
                                        <td>
                                            <select name="" id="" class="input_s w_270" v-model="popup_selectedChildCategory">
                                                <option :value="null" selected>--선택--</option>
                                                <option v-show="popup_childCategory != undefined && popup_childCategory.length == 0" disabled>
                                                    카테고리 없음
                                                </option>
                                                <option v-for="cc in popup_childCategory" :key="cc._id" :value="cc._id">
                                                    {{ cc.categoryName }}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b class="dgray_txt">관련질환<span class="red_txt">*</span></b>
                                        </td>
                                        <td>
                                            <input type="text" class="input_s w_270" v-model="popup_relatedDiseases" placeholder="관련질환 입력" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b class="dgray_txt">홈케어 종류<span class="red_txt">*</span></b>
                                        </td>
                                        <td>
                                            <input type="text" class="input_s w_270" v-model="popup_exerciseType" placeholder="홈케어 종류 입력" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b class="dgray_txt">동작별 영상 여부<span class="red_txt">*</span></b>
                                        </td>
                                        <td>
                                            <label class="radio_wrap"
                                                ><input type="radio" name="popup_isDetail" v-model="popup_isDetail" :value="false" /><span
                                                    class="checkmark"
                                                ></span>
                                                아니오</label
                                            >
                                            <label class="radio_wrap"
                                                ><input type="radio" name="popup_isDetail" v-model="popup_isDetail" :value="true" /><span
                                                    class="checkmark"
                                                ></span>
                                                네</label
                                            >
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="150px"><b class="dgray_txt">동작1</b></td>
                                        <td>
                                            <div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    name=""
                                                    id=""
                                                    class="input_s"
                                                    @change="action_uploadExerciseDetailFileOne($event)"
                                                />
                                                <input
                                                    type="text"
                                                    class="input_s"
                                                    placeholder="시작 시간 예) 06:15"
                                                    v-model="popup_exerciseDetailOne.rawTimestamp"
                                                />
                                                <small
                                                    @mouseenter="popupExerciseDetailOneHover('on')"
                                                    @mouseleave="popupExerciseDetailOneHover('off')"
                                                    class="lgray_txt mgt_10 img_preview_wrap"
                                                >
                                                    현재 저장된 FileID :
                                                    {{ popup_exerciseDetailOne.thumbnailFileID ? popup_exerciseDetailOne.thumbnailFileID._id : '' }}
                                                    <span class="img_preview">
                                                        <img
                                                            v-if="
                                                                popup_exerciseDetailOne.thumbnailFileHoverStatus == true &&
                                                                popup_exerciseDetailOne.thumbnailFilePaths != ''
                                                            "
                                                            :src="popup_exerciseDetailOne.thumbnailFilePaths"
                                                            alt=""
                                                        />
                                                    </span>
                                                </small>
                                            </div>
                                            <input
                                                type="text"
                                                class="input_s w_full mgt_10"
                                                placeholder="한글명 입력"
                                                v-model="popup_exerciseDetailOne.title"
                                            />
                                            <input
                                                type="text"
                                                class="input_s w_full mgt_10"
                                                placeholder="영문명 입력"
                                                v-model="popup_exerciseDetailOne.titleEn"
                                            />
                                            <textarea
                                                class="input_s w_full mgt_10"
                                                rows="4"
                                                placeholder="설명 입력"
                                                v-model="popup_exerciseDetailOne.description"
                                            ></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="150px"><b class="dgray_txt">동작2</b></td>
                                        <td>
                                            <div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    name=""
                                                    id=""
                                                    class="input_s"
                                                    @change="action_uploadExerciseDetailFileTwo($event)"
                                                />
                                                <input
                                                    type="text"
                                                    class="input_s"
                                                    placeholder="시작 시간 예) 06:15"
                                                    v-model="popup_exerciseDetailTwo.rawTimestamp"
                                                />
                                                <small
                                                    @mouseenter="popupExerciseDetailTwoHover('on')"
                                                    @mouseleave="popupExerciseDetailTwoHover('off')"
                                                    class="lgray_txt mgt_10 img_preview_wrap"
                                                >
                                                    현재 저장된 FileID :
                                                    {{ popup_exerciseDetailTwo.thumbnailFileID ? popup_exerciseDetailTwo.thumbnailFileID._id : '' }}
                                                    <span class="img_preview">
                                                        <img
                                                            v-if="
                                                                popup_exerciseDetailTwo.thumbnailFileHoverStatus == true &&
                                                                popup_exerciseDetailTwo.thumbnailFilePaths != ''
                                                            "
                                                            :src="popup_exerciseDetailTwo.thumbnailFilePaths"
                                                            alt=""
                                                        />
                                                    </span>
                                                </small>
                                            </div>
                                            <input
                                                type="text"
                                                class="input_s w_full mgt_10"
                                                placeholder="한글명 입력"
                                                v-model="popup_exerciseDetailTwo.title"
                                            />
                                            <input
                                                type="text"
                                                class="input_s w_full mgt_10"
                                                placeholder="영문명 입력"
                                                v-model="popup_exerciseDetailTwo.titleEn"
                                            />
                                            <textarea
                                                class="input_s w_full mgt_10"
                                                rows="4"
                                                placeholder="설명 입력"
                                                v-model="popup_exerciseDetailTwo.description"
                                            ></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="150px"><b class="dgray_txt">동작3</b></td>
                                        <td>
                                            <div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    name=""
                                                    id=""
                                                    class="input_s"
                                                    @change="action_uploadExerciseDetailFileThree($event)"
                                                />
                                                <input
                                                    type="text"
                                                    class="input_s"
                                                    placeholder="시작 시간 예) 06:15"
                                                    v-model="popup_exerciseDetailThree.rawTimestamp"
                                                />
                                                <small
                                                    @mouseenter="popupExerciseDetailThreeHover('on')"
                                                    @mouseleave="popupExerciseDetailThreeHover('off')"
                                                    class="lgray_txt mgt_10 img_preview_wrap"
                                                >
                                                    현재 저장된 FileID :
                                                    {{ popup_exerciseDetailThree.thumbnailFileID ? popup_exerciseDetailThree.thumbnailFileID._id : '' }}
                                                    <span class="img_preview">
                                                        <img
                                                            v-if="
                                                                popup_exerciseDetailThree.thumbnailFileHoverStatus == true &&
                                                                popup_exerciseDetailThree.thumbnailFilePaths != ''
                                                            "
                                                            :src="popup_exerciseDetailThree.thumbnailFilePaths"
                                                            alt=""
                                                        />
                                                    </span>
                                                </small>
                                            </div>
                                            <input
                                                type="text"
                                                class="input_s w_full mgt_10"
                                                placeholder="한글명 입력"
                                                v-model="popup_exerciseDetailThree.title"
                                            />
                                            <input
                                                type="text"
                                                class="input_s w_full mgt_10"
                                                placeholder="영문명 입력"
                                                v-model="popup_exerciseDetailThree.titleEn"
                                            />
                                            <textarea
                                                class="input_s w_full mgt_10"
                                                rows="4"
                                                placeholder="설명 입력"
                                                v-model="popup_exerciseDetailThree.description"
                                            ></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="150px"><b class="dgray_txt">동작4</b></td>
                                        <td>
                                            <div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    name=""
                                                    id=""
                                                    class="input_s"
                                                    @change="action_uploadExerciseDetailFileFour($event)"
                                                />
                                                <input
                                                    type="text"
                                                    class="input_s"
                                                    placeholder="시작 시간 예) 06:15"
                                                    v-model="popup_exerciseDetailFour.rawTimestamp"
                                                />
                                                <small
                                                    @mouseenter="popupExerciseDetailFourHover('on')"
                                                    @mouseleave="popupExerciseDetailFourHover('off')"
                                                    class="lgray_txt mgt_10 img_preview_wrap"
                                                >
                                                    현재 저장된 FileID :
                                                    {{ popup_exerciseDetailFour.thumbnailFileID ? popup_exerciseDetailFour.thumbnailFileID._id : '' }}
                                                    <span class="img_preview">
                                                        <img
                                                            v-if="
                                                                popup_exerciseDetailFour.thumbnailFileHoverStatus == true &&
                                                                popup_exerciseDetailFour.thumbnailFilePaths != ''
                                                            "
                                                            :src="popup_exerciseDetailFour.thumbnailFilePaths"
                                                            alt=""
                                                        />
                                                    </span>
                                                </small>
                                            </div>
                                            <input
                                                type="text"
                                                class="input_s w_full mgt_10"
                                                placeholder="한글명 입력"
                                                v-model="popup_exerciseDetailFour.title"
                                            />
                                            <input
                                                type="text"
                                                class="input_s w_full mgt_10"
                                                placeholder="영문명 입력"
                                                v-model="popup_exerciseDetailFour.titleEn"
                                            />
                                            <textarea
                                                class="input_s w_full mgt_10"
                                                rows="4"
                                                placeholder="설명 입력"
                                                v-model="popup_exerciseDetailFour.description"
                                            ></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="150px"><b class="dgray_txt">동작5</b></td>
                                        <td>
                                            <div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    name=""
                                                    id=""
                                                    class="input_s"
                                                    @change="action_uploadExerciseDetailFileFive($event)"
                                                />
                                                <input
                                                    type="text"
                                                    class="input_s"
                                                    placeholder="시작 시간 예) 06:15"
                                                    v-model="popup_exerciseDetailFive.rawTimestamp"
                                                />
                                                <small
                                                    @mouseenter="popupExerciseDetailFiveHover('on')"
                                                    @mouseleave="popupExerciseDetailFiveHover('off')"
                                                    class="lgray_txt mgt_10 img_preview_wrap"
                                                >
                                                    현재 저장된 FileID :
                                                    {{ popup_exerciseDetailFive.thumbnailFileID ? popup_exerciseDetailFive.thumbnailFileID._id : '' }}
                                                    <span class="img_preview">
                                                        <img
                                                            v-if="
                                                                popup_exerciseDetailFive.thumbnailFileHoverStatus == true &&
                                                                popup_exerciseDetailFive.thumbnailFilePaths != ''
                                                            "
                                                            :src="popup_exerciseDetailFive.thumbnailFilePaths"
                                                            alt=""
                                                        />
                                                    </span>
                                                </small>
                                            </div>
                                            <input
                                                type="text"
                                                class="input_s w_full mgt_10"
                                                placeholder="한글명 입력"
                                                v-model="popup_exerciseDetailFive.title"
                                            />
                                            <input
                                                type="text"
                                                class="input_s w_full mgt_10"
                                                placeholder="영문명 입력"
                                                v-model="popup_exerciseDetailFive.titleEn"
                                            />
                                            <textarea
                                                class="input_s w_full mgt_10"
                                                rows="4"
                                                placeholder="설명 입력"
                                                v-model="popup_exerciseDetailFive.description"
                                            ></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b class="dgray_txt">홈케어 정보<span class="red_txt">*</span></b>
                                        </td>
                                        <td>
                                            <textarea
                                                class="input_s w_full mgt_10"
                                                rows="4"
                                                placeholder="홈케어 정보 입력"
                                                v-model="popup_description"
                                            ></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="150px"><b class="dgray_txt">준비물1</b></td>
                                        <td>
                                            <div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    name=""
                                                    id=""
                                                    class="input_s"
                                                    @change="action_uploadMaterialDetailFileOne($event)"
                                                />
                                                <small
                                                    @mouseenter="popupMaterialDetailOneHover('on')"
                                                    @mouseleave="popupMaterialDetailOneHover('off')"
                                                    class="lgray_txt mgt_10 img_preview_wrap"
                                                >
                                                    현재 저장된 FileID :
                                                    {{ popup_materialDetailOne.thumbnailFileID ? popup_materialDetailOne.thumbnailFileID._id : '' }}
                                                    <span class="img_preview">
                                                        <img
                                                            v-if="
                                                                popup_materialDetailOne.thumbnailFileHoverStatus == true &&
                                                                popup_materialDetailOne.thumbnailFilePaths != ''
                                                            "
                                                            :src="popup_materialDetailOne.thumbnailFilePaths"
                                                            alt=""
                                                        />
                                                    </span>
                                                </small>
                                            </div>
                                            <input
                                                type="text"
                                                class="input_s w_full mgt_10"
                                                placeholder="준비물명 입력"
                                                v-model="popup_materialDetailOne.name"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="150px"><b class="dgray_txt">준비물2</b></td>
                                        <td>
                                            <div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    name=""
                                                    id=""
                                                    class="input_s"
                                                    @change="action_uploadMaterialDetailFileTwo($event)"
                                                />
                                                <small
                                                    @mouseenter="popupMaterialDetailTwoHover('on')"
                                                    @mouseleave="popupMaterialDetailTwoHover('off')"
                                                    class="lgray_txt mgt_10 img_preview_wrap"
                                                >
                                                    현재 저장된 FileID :
                                                    {{ popup_materialDetailTwo.thumbnailFileID ? popup_materialDetailTwo.thumbnailFileID._id : '' }}
                                                    <span class="img_preview">
                                                        <img
                                                            v-if="
                                                                popup_materialDetailTwo.thumbnailFileHoverStatus == true &&
                                                                popup_materialDetailTwo.thumbnailFilePaths != ''
                                                            "
                                                            :src="popup_materialDetailTwo.thumbnailFilePaths"
                                                            alt=""
                                                        />
                                                    </span>
                                                </small>
                                            </div>
                                            <input
                                                type="text"
                                                class="input_s w_full mgt_10"
                                                placeholder="준비물명 입력"
                                                v-model="popup_materialDetailTwo.name"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="150px"><b class="dgray_txt">준비물3</b></td>
                                        <td>
                                            <div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    name=""
                                                    id=""
                                                    class="input_s"
                                                    @change="action_uploadMaterialDetailFileThree($event)"
                                                />
                                                <small
                                                    @mouseenter="popupMaterialDetailThreeHover('on')"
                                                    @mouseleave="popupMaterialDetailThreeHover('off')"
                                                    class="lgray_txt mgt_10 img_preview_wrap"
                                                >
                                                    현재 저장된 FileID :
                                                    {{ popup_materialDetailThree.thumbnailFileID ? popup_materialDetailThree.thumbnailFileID._id : '' }}
                                                    <span class="img_preview">
                                                        <img
                                                            v-if="
                                                                popup_materialDetailThree.thumbnailFileHoverStatus == true &&
                                                                popup_materialDetailThree.thumbnailFilePaths != ''
                                                            "
                                                            :src="popup_materialDetailThree.thumbnailFilePaths"
                                                            alt=""
                                                        />
                                                    </span>
                                                </small>
                                            </div>
                                            <input
                                                type="text"
                                                class="input_s w_full mgt_10"
                                                placeholder="준비물명 입력"
                                                v-model="popup_materialDetailThree.name"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b class="dgray_txt">주의사항<span class="red_txt">*</span></b>
                                        </td>
                                        <td>
                                            <textarea class="input_s w_full mgt_10" rows="4" placeholder="주의사항 입력" v-model="popup_precaution"></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b class="dgray_txt">검색키워드<span class="red_txt">*</span></b>
                                        </td>
                                        <td>
                                            <textarea
                                                class="input_s w_full mgt_10"
                                                rows="4"
                                                placeholder="검색키워드 입력"
                                                v-model="popup_searchKeyword"
                                            ></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b class="dgray_txt">자극부위<span class="red_txt">*</span></b>
                                        </td>
                                        <td>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                name=""
                                                id=""
                                                class="input_s"
                                                @change="action_uploadStimulateMusclesImageFile($event)"
                                            />
                                            <small
                                                @mouseenter="popupStimultateMusclesImageHover('on')"
                                                @mouseleave="popupStimultateMusclesImageHover('off')"
                                                class="lgray_txt mgt_10 img_preview_wrap"
                                            >
                                                현재 저장된 FileID : {{ popup_stimulateMusclesImageID }}
                                                <span class="img_preview">
                                                    <img
                                                        v-if="popup_stimulateMusclesImageHoverStatus == true && popup_stimulateMusclesImagePaths != ''"
                                                        :src="popup_stimulateMusclesImagePaths"
                                                        alt=""
                                                    />
                                                </span>
                                            </small>
                                            <textarea
                                                class="input_s w_full mgt_10"
                                                rows="4"
                                                placeholder="자극부위 입력"
                                                v-model="popup_stimulateMuscles"
                                            ></textarea>
                                        </td>
                                    </tr>
                                </table>
                                <small class="red_txt mgt_10" v-show="popup_isErrorMessage == true">
                                    {{ popup_errorMessage }}
                                </small>
                                <div class="btn_wrap">
                                    <button class="btn btn_l btn_blue" v-if="isEmptyObject(selectedVideoContent)" @click="action_createVideoContent">
                                        추가
                                    </button>
                                    <button class="btn btn_l btn_blue" v-if="!isEmptyObject(selectedVideoContent)" @click="action_updateVideoContent">
                                        수정
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from '../../../../../util/MPMoment'
// eslint-disable-next-line
// import MPAWS from '../../../../../util/MPAWS' // not in use
import Pagenation from '../../../ShareComponents/PagenationComponent.vue'
import PageController from '../../../../../controller/PageController'

export default {
    components: { Pagenation },
    data() {
        return {
            // pagenation search filter
            isSearch: false,
            filter: {},
            videoContentCategories: [],

            hlsvideos: [],
            checkboxToggle: false,
            selectedVideoContent: {},
            selectedVideoContentCategoryID: null,
            keyword: null,
            vcCurrentPage: 0,
            vcCountPerPage: 10,
            vcTotalCount: 0,
            vcData: [],
            // videoContents: [],

            // popup component below
            popup_isShow: false,

            popup_selectedHLSVideoID: '',
            popup_thumbnailFileID: '',
            popup_thumbnailFilePaths: '', // new
            popup_thumbnailFileHoverStatus: false,
            popup_subtitle: '',
            popup_title: '',
            popup_topCategory: [],
            popup_selectedTopCategory: null,
            popup_parentCategory: [],
            popup_selectedParentCategory: null,
            popup_childCategory: [],
            popup_selectedChildCategory: null,
            popup_categories: [],
            popup_relatedDiseases: '',
            popup_exerciseType: '',
            popup_isDetail: false,

            // exerciseSet detail input boxes
            popup_exerciseSet: [],
            popup_exerciseDetailOne: {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                rawTimestamp: '',
                timestamp: '',
                title: '',
                titleEn: '',
                description: ''
            },
            popup_exerciseDetailTwo: {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                rawTimestamp: '',
                timestamp: '',
                title: '',
                titleEn: '',
                description: ''
            },
            popup_exerciseDetailThree: {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                rawTimestamp: '',
                timestamp: '',
                title: '',
                titleEn: '',
                description: ''
            },
            popup_exerciseDetailFour: {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                rawTimestamp: '',
                timestamp: '',
                title: '',
                titleEn: '',
                description: ''
            },
            popup_exerciseDetailFive: {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                rawTimestamp: '',
                timestamp: '',
                title: '',
                titleEn: '',
                description: ''
            },

            popup_description: '',

            // material detail block
            popup_material: [],
            popup_materialDetailOne: {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                name: ''
            },
            popup_materialDetailTwo: {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                name: ''
            },
            popup_materialDetailThree: {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                name: ''
            },

            popup_precaution: '',
            popup_searchKeyword: '',

            popup_stimulateMusclesImageID: '',
            popup_stimulateMusclesImagePaths: '',
            popup_stimulateMusclesImageHoverStatus: false,
            popup_stimulateMuscles: '',

            popup_totalVideoLength: 0,

            popup_isErrorMessage: false,
            popup_errorMessage: '',

            // remove popup
            removePopup_isShow: false
        }
    },
    watch: {
        vcTotalCount: {
            handler: function () {
                // do nothing
            }
        },
        popup_thumbnailFileID: {
            handler: function () {
                // do nothing
            }
        },
        popup_selectedTopCategory: {
            handler: async function (value) {
                await this.action_SelectPopupTopHandler(value)
            }
        },
        popup_selectedParentCategory: {
            handler: async function (value) {
                await this.action_SelectPopupParentHandler(value)
            }
        },
        popup_exerciseDetailOne: {
            deep: true,
            handler: function () {
                // do nothing
            }
        },
        popup_exerciseDetailTwo: {
            deep: true,
            handler: function () {
                // do nothing
            }
        },
        popup_exerciseDetailThree: {
            deep: true,
            handler: function () {
                // do nothing
            }
        },
        popup_exerciseDetailFour: {
            deep: true,
            handler: function () {
                // do nothing
            }
        },
        popup_exerciseDetailFive: {
            deep: true,
            handler: function () {
                // do nothing
            }
        },
        popup_materialDetailOne: {
            deep: true,
            handler: function () {
                // do nothing
            }
        },
        popup_materialDetailTwo: {
            deep: true,
            handler: function () {
                // do nothing
            }
        },
        popup_materialDetailThree: {
            deep: true,
            handler: function () {
                // do nothing
            }
        },
        popup_stimulateMusclesImageID: {
            handler: function () {
                // do nothing
            }
        },
        keyword: {
            handler: function (newValue) {
                this.filter.keyword = newValue
            }
        },
        selectedVideoContentCategoryID: {
            handler: function (newValue) {
                this.filter.videoContentCategoryID = newValue
            }
        }
    },
    mounted() {
        this.findVideoContentCategories(true)
        this.findHLSVideos()
        // this.loadVideoContents(0, this.filter.keyword)

        this.videocontentPageController = new PageController(this.API.VideoContent.find, this.filter)
        this.videocontentPageController.countPerPage = this.vcCountPerPage
        this.loadPageIndex(this.vcCurrentPage)
    },
    methods: {
        async getThumbnailPath(fileID) {
            let model = { _id: fileID }
            let result = await this.API.File.getFile(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            let file = undefined
            let paths = ''
            if (result.data.data != undefined && result.data.data.length > 0) {
                file = result.data.data[0]
            }
            if (file.paths != undefined && file.paths.length > 0) {
                paths = file.paths[0]
            }
            return paths
        },

        // reload data
        initData() {
            this.findVideoContentCategories(true)
            this.findHLSVideos()

            this.loadPageIndex(this.vcCurrentPage)
        },

        initParams() {
            this.selectedVideoContent = {}
            this.popup_isErrorMessage = false
            this.popup_errorMessage = ''
            this.popup_isShow = false

            this.popup_selectedHLSVideoID = ''
            this.popup_thumbnailFileID = ''
            this.popup_thumbnailFilePaths = ''
            ;(this.popup_thumbnailFileHoverStatus = false), (this.popup_subtitle = '')
            this.popup_title = ''
            // this.popup_topCategory = []
            this.popup_selectedTopCategory = null
            this.popup_parentCategory = []
            this.popup_selectedParentCategory = null
            this.popup_childCategory = []
            this.popup_selectedChildCategory = null
            this.popup_categories = []
            this.popup_relatedDiseases = ''
            this.popup_exerciseType = ''
            this.popup_isDetail = false
            this.popup_exerciseSet = []
            this.popup_exerciseDetailOne = {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                rawTimestamp: '',
                timestamp: '',
                title: '',
                titleEn: '',
                description: ''
            }
            this.popup_exerciseDetailTwo = {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                rawTimestamp: '',
                timestamp: '',
                title: '',
                titleEn: '',
                description: ''
            }
            this.popup_exerciseDetailThree = {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                rawTimestamp: '',
                timestamp: '',
                title: '',
                titleEn: '',
                description: ''
            }
            this.popup_exerciseDetailFour = {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                rawTimestamp: '',
                timestamp: '',
                title: '',
                titleEn: '',
                description: ''
            }
            this.popup_exerciseDetailFive = {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                rawTimestamp: '',
                timestamp: '',
                title: '',
                titleEn: '',
                description: ''
            }

            this.popup_description = ''
            this.popup_material = []
            this.popup_materialDetailOne = {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                name: ''
            }
            this.popup_materialDetailTwo = {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                name: ''
            }
            this.popup_materialDetailThree = {
                thumbnailFileID: '',
                thumbnailFilePaths: '',
                thumbnailFileHoverStatus: false,
                name: ''
            }

            this.popup_precaution = ''
            this.popup_searchKeyword = ''

            this.popup_stimulateMusclesImageID = ''
            this.popup_stimulateMusclesImagePaths = ''
            this.popup_stimulateMusclesImageHoverStatus = false
            this.popup_stimulateMuscles = ''

            this.popup_totalVideoLength = 0
        },

        action_Init() {
            this.initParams()
        },

        // findTop : isTop, parentCategoryID
        async findVideoContentCategories(isTop) {
            let model = {}
            if (isTop != undefined && isTop != '') {
                model.isTop = isTop
            }
            let result = await this.API.VideoContentCategory.find(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.videoContentCategories = result.data.data
            this.popup_topCategory = result.data.data

            this.videoContentCategories.forEach(async (vcc) => {
                let result = await this.API.VideoContent.count({ videoContentCategoryID: vcc._id })
                if (result.data.code != 0) {
                    console.log(result)
                    return
                }
                vcc.count = result.data.data
                this.$forceUpdate()
            })
            this.$forceUpdate()
        },

        async findPopupVideoContentCategories(isTop, isParent, parentCategoryID) {
            let model = {}
            if (isTop != undefined || isTop != '') {
                model.isTop = isTop
            }
            if (isParent != undefined || isParent != '') {
                model.isParent = isParent
            }
            if (parentCategoryID != undefined || parentCategoryID != '') {
                model.parentCategoryIDs = parentCategoryID
            }
            let result = await this.API.VideoContentCategory.find(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            return result.data.data
        },

        async loadPageIndex(page) {
            this.vcCurrentPage = page

            this.filter.keyword = this.keyword
            this.filter.videoContentCategoryID = this.selectedVideoContentCategoryID

            await this.videocontentPageController.loadPageIndex(page)
            this.loadTotalVideoContent()
            this.vcData = this.videocontentPageController.data
        },

        async loadTotalVideoContent() {
            let result = await this.API.VideoContent.count(this.filter)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.vcTotalCount = result.data.data
        },

        vcChangePage(page) {
            this.vcCurrentPage = page
            this.loadPageIndex(this.vcCurrentPage)
        },

        async findHLSVideos() {
            let model = {}
            let result = await this.API.HLSVideo.find(model)
            console.log(result.data.data)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.hlsvideos = result.data.data
            this.$forceUpdate()
        },

        async action_uploadThumbnailFile(event) {
            let result = await this.action_UploadImageFile(event)
            this.popup_thumbnailFileID = result._id
            this.popup_thumbnailFilePaths = result.paths[0]
        },
        async action_uploadExerciseDetailFileOne(event) {
            let result = await this.action_UploadImageFile(event)
            this.popup_exerciseDetailOne.thumbnailFileID = result
            this.popup_exerciseDetailOne.thumbnailFilePaths = result.paths[0]
        },
        async action_uploadExerciseDetailFileTwo(event) {
            let result = await this.action_UploadImageFile(event)
            this.popup_exerciseDetailTwo.thumbnailFileID = result
            this.popup_exerciseDetailTwo.thumbnailFilePaths = result.paths[0]
        },
        async action_uploadExerciseDetailFileThree(event) {
            let result = await this.action_UploadImageFile(event)
            this.popup_exerciseDetailThree.thumbnailFileID = result
            this.popup_exerciseDetailThree.thumbnailFilePaths = result.paths[0]
        },
        async action_uploadExerciseDetailFileFour(event) {
            let result = await this.action_UploadImageFile(event)
            this.popup_exerciseDetailFour.thumbnailFileID = result
            this.popup_exerciseDetailFour.thumbnailFilePaths = result.paths[0]
        },
        async action_uploadExerciseDetailFileFive(event) {
            let result = await this.action_UploadImageFile(event)
            this.popup_exerciseDetailFive.thumbnailFileID = result
            this.popup_exerciseDetailFive.thumbnailFilePaths = result.paths[0]
        },
        async action_uploadMaterialDetailFileOne(event) {
            let result = await this.action_UploadImageFile(event)
            this.popup_materialDetailOne.thumbnailFileID = result
            this.popup_materialDetailOne.thumbnailFilePaths = result.paths[0]
        },
        async action_uploadMaterialDetailFileTwo(event) {
            let result = await this.action_UploadImageFile(event)
            this.popup_materialDetailTwo.thumbnailFileID = result
            this.popup_materialDetailTwo.thumbnailFilePaths = result.paths[0]
        },
        async action_uploadMaterialDetailFileThree(event) {
            let result = await this.action_UploadImageFile(event)
            this.popup_materialDetailThree.thumbnailFileID = result
            this.popup_materialDetailThree.thumbnailFilePaths = result.paths[0]
        },
        async action_uploadStimulateMusclesImageFile(event) {
            let result = await this.action_UploadImageFile(event)
            this.popup_stimulateMusclesImageID = result
            this.popup_stimulateMusclesImagePaths = result.paths[0]
        },

        //common upload image method
        async action_UploadImageFile(event) {
            let files = event.currentTarget.files
            let sizes = []

            for (let i = 0; i < files.length; i++) {
                let file = files[i]
                var imageReader = new FileReader()
                imageReader.onload = function (e) {
                    var img = new Image()
                    img.src = e.target.result

                    img.onload = function () {
                        var w = this.width
                        var h = this.height
                        let size = {
                            width: w,
                            height: h
                        }
                        sizes.push(size)
                    }
                }
                imageReader.readAsDataURL(file)
            }
            let mimeType = 'image/*'
            let type = 300
            let userID = this.$store.state.user._id
            let result = await this.MPAWS.uploadFiles(files, sizes, mimeType, type, userID)

            if (result.data.code != 0) {
                console.log(result.data.message)
                return
            }
            console.log(result.data.data)

            return result.data.data
        },

        action_ShowCreateVideoContentPopup() {
            this.action_Init()
            this.popup_isShow = true
        },
        async action_ShowEditVideoContentPopup(vc) {
            this.selectedVideoContent = vc
            this.popup_selectedHLSVideoID = vc.videoFileID._id
            // this.popup_thumbnailFileID = vc.thumbnailFileID

            // TODO: vc.thumbnailFileID 에서 ._id, imageURL 분리
            if (vc.thumbnailFileID != undefined && vc.thumbnailFileID != '') {
                this.popup_thumbnailFilePaths = vc.thumbnailFileID.paths[0]
                this.popup_thumbnailFileID = vc.thumbnailFileID._id
            }
            this.popup_subtitle = vc.subtitle
            this.popup_title = vc.title

            this.popup_selectedTopCategory = this.findVideoContentTopCategory(vc.categories)._id
            this.popup_selectedParentCategory = this.findVideoContentParentCategory(vc.categories)._id
            this.popup_selectedChildCategory = this.findVideoContentChildCategory(vc.categories)._id
            this.popup_relatedDiseases = vc.relatedDiseases
            this.popup_exerciseType = vc.exerciseType
            this.popup_isDetail = vc.isDetail

            // set popup_exercise detail set
            // TODO: vc.exerciseSet[i] 부은 후 thumbnailFileID에서 추가 property 만들어서 ._id, imageURL로 따로 풀어줘야함.
            this.popup_exerciseSet = []
            for (let i = 0; i < vc.exerciseSet.length; i++) {
                // convert milliseconds to timestamp type
                vc.exerciseSet[i].rawTimestamp = moment(vc.exerciseSet[i].timestamp).format('m:ss')
                if (i == 0) {
                    if (vc.exerciseSet[i].thumbnailFileID != undefined && vc.exerciseSet[i].thumbnailFileID != '') {
                        this.popup_exerciseDetailOne.thumbnailFileID = vc.exerciseSet[i].thumbnailFileID
                        this.popup_exerciseDetailOne.thumbnailFilePaths = vc.exerciseSet[i].thumbnailFileID.paths[0]
                        this.popup_exerciseDetailOne.rawTimestamp = vc.exerciseSet[i].rawTimestamp
                        this.popup_exerciseDetailOne.title = vc.exerciseSet[i].title
                        this.popup_exerciseDetailOne.titleEn = vc.exerciseSet[i].titleEn
                        this.popup_exerciseDetailOne.description = vc.exerciseSet[i].description
                    }
                }
                if (i == 1) {
                    if (vc.exerciseSet[i].thumbnailFileID != undefined && vc.exerciseSet[i].thumbnailFileID != '') {
                        this.popup_exerciseDetailTwo.thumbnailFileID = vc.exerciseSet[i].thumbnailFileID
                        this.popup_exerciseDetailTwo.thumbnailFilePaths = vc.exerciseSet[i].thumbnailFileID.paths[0]
                        this.popup_exerciseDetailTwo.rawTimestamp = vc.exerciseSet[i].rawTimestamp
                        this.popup_exerciseDetailTwo.title = vc.exerciseSet[i].title
                        this.popup_exerciseDetailTwo.titleEn = vc.exerciseSet[i].titleEn
                        this.popup_exerciseDetailTwo.description = vc.exerciseSet[i].description
                    }
                }
                if (i == 2) {
                    if (vc.exerciseSet[i].thumbnailFileID != undefined && vc.exerciseSet[i].thumbnailFileID != '') {
                        this.popup_exerciseDetailThree.thumbnailFileID = vc.exerciseSet[i].thumbnailFileID
                        this.popup_exerciseDetailThree.thumbnailFilePaths = vc.exerciseSet[i].thumbnailFileID.paths[0]
                        this.popup_exerciseDetailThree.rawTimestamp = vc.exerciseSet[i].rawTimestamp
                        this.popup_exerciseDetailThree.title = vc.exerciseSet[i].title
                        this.popup_exerciseDetailThree.titleEn = vc.exerciseSet[i].titleEn
                        this.popup_exerciseDetailThree.description = vc.exerciseSet[i].description
                    }
                }
                if (i == 3) {
                    if (vc.exerciseSet[i].thumbnailFileID != undefined && vc.exerciseSet[i].thumbnailFileID != '') {
                        this.popup_exerciseDetailFour.thumbnailFileID = vc.exerciseSet[i].thumbnailFileID
                        this.popup_exerciseDetailFour.thumbnailFilePaths = vc.exerciseSet[i].thumbnailFileID.paths[0]
                        this.popup_exerciseDetailFour.rawTimestamp = vc.exerciseSet[i].rawTimestamp
                        this.popup_exerciseDetailFour.title = vc.exerciseSet[i].title
                        this.popup_exerciseDetailFour.titleEn = vc.exerciseSet[i].titleEn
                        this.popup_exerciseDetailFour.description = vc.exerciseSet[i].description
                    }
                }
                if (i == 4) {
                    if (vc.exerciseSet[i].thumbnailFileID != undefined && vc.exerciseSet[i].thumbnailFileID != '') {
                        this.popup_exerciseDetailFive.thumbnailFileID = vc.exerciseSet[i].thumbnailFileID
                        this.popup_exerciseDetailFive.thumbnailFilePaths = vc.exerciseSet[i].thumbnailFileID.paths[0]
                        this.popup_exerciseDetailFive.rawTimestamp = vc.exerciseSet[i].rawTimestamp
                        this.popup_exerciseDetailFive.title = vc.exerciseSet[i].title
                        this.popup_exerciseDetailFive.titleEn = vc.exerciseSet[i].titleEn
                        this.popup_exerciseDetailFive.description = vc.exerciseSet[i].description
                    }
                }
            }
            this.popup_description = vc.description

            // set popup_material detail set
            this.popup_material = []
            for (let i = 0; i < vc.material.length; i++) {
                if (i == 0) {
                    if (vc.material[i].thumbnailFileID != undefined && vc.material[i].thumbnailFileID != '') {
                        this.popup_materialDetailOne.thumbnailFileID = vc.material[i].thumbnailFileID
                        this.popup_materialDetailOne.thumbnailFilePaths = vc.material[i].thumbnailFileID.paths[0]
                        this.popup_materialDetailOne.name = vc.material[i].name
                    }
                }
                if (i == 1) {
                    if (vc.material[i].thumbnailFileID != undefined && vc.material[i].thumbnailFileID != '') {
                        this.popup_materialDetailTwo.thumbnailFileID = vc.material[i].thumbnailFileID
                        this.popup_materialDetailTwo.thumbnailFilePaths = vc.material[i].thumbnailFileID.paths[0]
                        this.popup_materialDetailTwo.name = vc.material[i].name
                    }
                }
                if (i == 2) {
                    if (vc.material[i].thumbnailFileID != undefined && vc.material[i].thumbnailFileID != '') {
                        this.popup_materialDetailThree.thumbnailFileID = vc.material[i].thumbnailFileID
                        this.popup_materialDetailThree.thumbnailFilePaths = vc.material[i].thumbnailFileID.paths[0]
                        this.popup_materialDetailThree.name = vc.material[i].name
                    }
                }
            }
            this.popup_precaution = vc.precaution
            this.popup_searchKeyword = vc.searchKeyword

            if (vc.stimulateMusclesImageID != undefined && vc.stimulateMusclesImageID != '') {
                this.popup_stimulateMusclesImageID = vc.stimulateMusclesImageID._id
                this.popup_stimulateMusclesImagePaths = vc.stimulateMusclesImageID.paths[0]
            }

            this.popup_stimulateMuscles = vc.stimulateMuscles
            this.popup_totalVideoLength = vc.totalVideoLength

            this.popup_isShow = true
        },
        action_ShowRemoveVideoContentPopup(vc) {
            console.log(vc)
            this.removePopup_isShow = true
        },
        action_ClosePopup() {
            this.action_Init()
            this.popup_isShow = false
        },
        action_CloseRemovePopup() {
            this.removePopup_isShow = false
        },

        findVideoContentTopCategory(categories) {
            for (let i = 0; i < categories.length; i++) {
                if (categories[i].isTop == true) {
                    return categories[i]
                }
            }
        },
        findVideoContentParentCategory(categories) {
            for (let i = 0; i < categories.length; i++) {
                if (categories[i].isParent == true) {
                    return categories[i]
                }
            }
        },
        findVideoContentChildCategory(categories) {
            for (let i = 0; i < categories.length; i++) {
                if (categories[i].isTop == false && categories[i].isParent == false) {
                    return categories[i]
                }
            }
        },

        findVideoContentCategoryName(pos, categories) {
            let topName, parentName, childName
            for (let i = 0; i < categories.length; i++) {
                if (categories[i].isTop == true) {
                    topName = categories[i].categoryName
                } else if (categories[i].isParent == true) {
                    parentName = categories[i].categoryName
                } else {
                    childName = categories[i].categoryName
                }
            }
            switch (pos) {
                case 'top':
                    return topName
                case 'parent':
                    return parentName
                case 'child':
                    return childName
            }
        },

        // validate add / edit popup each ExerciseSet detail object
        validateExerciseSetDetailObject(obj) {
            if (obj.thumbnailFileID == '' || obj.thumbnailFileID == undefined) {
                return false
            }
            if (obj.rawTimestamp == '' || obj.rawTimestamp == undefined) {
                return false
            }
            if (obj.title == '' || obj.title == undefined) {
                return false
            }
            if (obj.titleEn == '' || obj.titleEn == undefined) {
                return false
            }
            if (obj.description == '' || obj.description == undefined) {
                return false
            }

            // convert "mm:ss" timestamp to miliseconds.
            let [inputMinute, inputSecond] = obj.rawTimestamp.split(':')
            let date = moment.unix(0)

            // return value by miliseconds
            let timestamp = date.minute(Number(inputMinute)).second(Number(inputSecond)).valueOf()
            obj.timestamp = timestamp

            return true
        },
        validateMaterialDetailObject(obj) {
            if (obj.thumbnailFileID == '' || obj.thumbnailFileID == undefined) {
                return false
            }
            if (obj.name == '' || obj.name == undefined) {
                return false
            }
            return true
        },
        action_SelectVideoContentCategory(event) {
            console.log(event.target.value)
            this.vcCurrentPage = 0
            this.loadPageIndex(this.vcCurrentPage)
        },
        action_SearchByKeyword(event) {
            console.log(event.target.value)
            this.vcCurrentPage = 0
            this.loadPageIndex(this.vcCurrentPage)
        },

        action_SelectPopupHLSVideo(event) {
            this.popup_selectedHLSVideoID = event.target.value
            for (let i = 0; i < this.hlsvideos.length; i++) {
                if (this.hlsvideos[i]._id == this.popup_selectedHLSVideoID) {
                    this.popup_totalVideoLength = this.hlsvideos[i].totalVideoLength
                    break
                }
            }
        },
        async action_updateHidden(videoContent) {
            let model = {}
            model._id = videoContent._id
            model.isHidden = videoContent.isHidden

            let result = await this.API.VideoContent.update(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            // update VCHs isHidden value
            let vcID = result.data.data._id.toString()
            let vchResult = await this.API.VideoContentHospital.find({ vcID: vcID })
            let vchArr = vchResult.data.data
            for (let i = 0; i < vchArr.length; i++) {
                let vchModel = {
                    vchID: vchArr[i]._id.toString(),
                    isHidden: videoContent.isHidden
                }
                await this.API.VideoContentHospital.update(vchModel)
            }
        },
        async action_SelectPopupTopVCCategory(event) {
            console.log(event.target.value)
            this.popup_selectedTopCategory = event.target.value
            let result = await this.findPopupVideoContentCategories(false, true, this.popup_selectedTopCategory)
            console.log(result)
            this.popup_parentCategory = result
            this.popup_childCategory = []
            this.popup_selectedParentCategory = null
            this.popup_selectedChildCategory = null
            this.$forceUpdate()
        },
        async action_SelectPopupParentVCCategory(event) {
            console.log(event.target.value)
            this.popup_selectedParentCategory = event.target.value
            let result = await this.findPopupVideoContentCategories(false, false, this.popup_selectedParentCategory)
            console.log(result)
            this.popup_childCategory = result
            this.popup_selectedChildCategory = null
            this.$forceUpdate()
        },

        async action_SelectPopupTopHandler(topCategory) {
            let result = await this.findPopupVideoContentCategories(false, true, topCategory)
            this.popup_parentCategory = result
            // this.popup_childCategory = []
            // this.popup_selectedParentCategory = null
            // this.popup_selectedChildCategory = null
            this.$forceUpdate()
        },

        async action_SelectPopupParentHandler(parentCategory) {
            let result = await this.findPopupVideoContentCategories(false, false, parentCategory)
            this.popup_childCategory = result
            // this.popup_selectedChildCategory = null
            this.$forceUpdate()
        },

        // empty value check
        isPopupContentEmpty() {
            if (this.popup_selectedHLSVideoID == '' || this.popup_selectedHLSVideoID == undefined) {
                this.popup_errorMessage = '홈케어 콘텐츠에 보낼 영상을 선택해주세요.'
                this.popup_isErrorMessage = true
                return
            }

            if (this.popup_thumbnailFileID == '' || this.popup_thumbnailFileID == undefined) {
                this.popup_errorMessage = '썸네일 이미지를 업로드해주세요.'
                this.popup_isErrorMessage = true
                return
            }

            if (this.popup_subtitle == '' || this.popup_subtitle == undefined) {
                this.popup_errorMessage = '서브 제목을 입력해주세요.'
                this.popup_isErrorMessage = true
                return
            }

            if (this.popup_title == '' || this.popup_title == undefined) {
                this.popup_errorMessage = '메인 제목을 입력해주세요.'
                this.popup_isErrorMessage = true
                return
            }

            if (this.popup_relatedDiseases == '' || this.popup_relatedDiseases == undefined) {
                this.popup_errorMessage = '관련질환을 입력해주세요.'
                this.popup_isErrorMessage = true
                return
            }

            if (this.popup_exerciseType == '' || this.popup_exerciseType == undefined) {
                this.popup_errorMessage = '홈케어 종류를 입력해주세요.'
                this.popup_isErrorMessage = true
                return
            }

            if (this.popup_description == '' || this.popup_description == undefined) {
                this.popup_errorMessage = '홈케어 정보를 입력해주세요.'
                this.popup_isErrorMessage = true
                return
            }

            if (this.popup_precaution == '' || this.popup_precaution == undefined) {
                this.popup_errorMessage = '주의사항을 입력해주세요.'
                this.popup_isErrorMessage = true
                return
            }

            if (this.popup_searchKeyword == '' || this.popup_searchKeyword == undefined) {
                this.popup_errorMessage = '검색키워드를 입력해주세요.'
                this.popup_isErrorMessage = true
                return
            }

            if (this.popup_stimulateMusclesImageID == '' || this.popup_stimulateMusclesImageID == undefined) {
                this.popup_errorMessage = '자극부위 이미지를 업로드해주세요.'
                this.popup_isErrorMessage = true
                return
            }

            if (this.popup_stimulateMuscles == '' || this.popup_stimulateMuscles == undefined) {
                this.popup_errorMessage = '자극부위를 입력해주세요.'
                this.popup_isErrorMessage = true
                return
            }

            // check every categories are selected
            if (this.popup_selectedTopCategory == null || this.popup_selectedParentCategory == null || this.popup_selectedChildCategory == null) {
                this.popup_isErrorMessage = true
                this.popup_errorMessage = '카테고리 대/중/소를 모두 선택하세요.'
                return
            }
        },

        async action_createVideoContent() {
            // init
            this.popup_isErrorMessage = false

            // if isErrorMessage is true, return to popup
            this.isPopupContentEmpty()
            if (this.popup_isErrorMessage == true) {
                return
            }

            // create
            let THIS = this
            let model = {
                videoFileID: this.popup_selectedHLSVideoID,
                thumbnailFileID: this.popup_thumbnailFileID,
                subtitle: this.popup_subtitle,
                title: this.popup_title,
                relatedDiseases: this.popup_relatedDiseases,
                exerciseType: this.popup_exerciseType,
                isDetail: this.popup_isDetail,
                description: this.popup_description,
                precaution: this.popup_precaution,
                searchKeyword: this.popup_searchKeyword,
                stimulateMusclesImageID: this.popup_stimulateMusclesImageID,
                stimulateMuscles: this.popup_stimulateMuscles,
                totalVideoLength: this.popup_totalVideoLength
            }

            let categories = [this.popup_selectedTopCategory, this.popup_selectedParentCategory, this.popup_selectedChildCategory]
            model.categories = categories

            // remove empty exerciseSet.detail
            let exerciseSet = [
                this.popup_exerciseDetailOne,
                this.popup_exerciseDetailTwo,
                this.popup_exerciseDetailThree,
                this.popup_exerciseDetailFour,
                this.popup_exerciseDetailFive
            ]
            this.popup_exerciseSet = [] // init
            exerciseSet.forEach(function (detail) {
                if (THIS.validateExerciseSetDetailObject(detail) == true) {
                    let pos = exerciseSet.indexOf(detail)
                    if (pos > -1) {
                        THIS.popup_exerciseSet.push(exerciseSet[pos])
                    }
                }
            })
            model.exerciseSet = this.popup_exerciseSet

            // remove empty material.detail
            let material = [this.popup_materialDetailOne, this.popup_materialDetailTwo, this.popup_materialDetailThree]
            this.popup_material = [] // init
            material.forEach(function (detail) {
                if (THIS.validateMaterialDetailObject(detail) == true) {
                    let pos = material.indexOf(detail)
                    if (pos > -1) {
                        THIS.popup_material.push(material[pos])
                    }
                }
            })
            model.material = this.popup_material

            let result = await this.API.VideoContent.create(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }

            console.log(result.data)

            let vcID = result.data.data._id.toString()

            // 대 카테고리를 컨디셔닝 카테고리로 선택 시 = 홈케어콘텐츠를 사용중인 병원에, VCH 일괄 생성
            let vccResult = await this.API.VideoContentCategory.findconditioning({ isConditioning: true })
            if (vccResult != undefined && vccResult.data.data.length > 0) {
                let conditioningCategory = vccResult.data.data[0]
                if (this.popup_selectedTopCategory == conditioningCategory._id) {
                    //홈케어 콘텐츠 사용중인 병원에 대해 일괄 생성
                    let hResult = await this.API.Hospital.getHospitals({ usingVideoContent: true })
                    let hospitals = hResult.data.data
                    for (let i = 0; i < hospitals.length; i++) {
                        await this.API.VideoContentHospital.create({ hospitalID: hospitals[i]._id, vcID: vcID })
                    }
                } else {
                    // 선택한 카테고리들을 사용하고 있는 병원들에 대해서, VCH 일괄 생성.
                    let serviceSetting = await this.API.ServiceSetting.getServiceSettings({ topCategory: this.popup_selectedTopCategory })
                    let serviceArr = serviceSetting.data.data
                    for (let i = 0; i < serviceArr.length; i++) {
                        await this.API.VideoContentHospital.create({ hospitalID: serviceArr[i].hospitalID, vcID: vcID })
                    }
                }
            }

            this.initData()
            this.action_ClosePopup()
        },

        async action_updateVideoContent() {
            // init
            this.popup_isErrorMessage = false

            // if isErrorMessage is true, return to popup
            this.isPopupContentEmpty()
            if (this.popup_isErrorMessage == true) {
                return
            }

            let THIS = this
            let model = {
                _id: this.selectedVideoContent._id,
                videoFileID: this.popup_selectedHLSVideoID,
                thumbnailFileID: this.popup_thumbnailFileID,
                subtitle: this.popup_subtitle,
                title: this.popup_title,
                relatedDiseases: this.popup_relatedDiseases,
                exerciseType: this.popup_exerciseType,
                isDetail: this.popup_isDetail,
                description: this.popup_description,
                precaution: this.popup_precaution,
                searchKeyword: this.popup_searchKeyword,
                stimulateMusclesImageID: this.popup_stimulateMusclesImageID,
                stimulateMuscles: this.popup_stimulateMuscles,
                totalVideoLength: this.popup_totalVideoLength
            }

            let categories = [this.popup_selectedTopCategory, this.popup_selectedParentCategory, this.popup_selectedChildCategory]
            model.categories = categories

            // remove empty exerciseSet.detail
            let exerciseSet = [
                this.popup_exerciseDetailOne,
                this.popup_exerciseDetailTwo,
                this.popup_exerciseDetailThree,
                this.popup_exerciseDetailFour,
                this.popup_exerciseDetailFive
            ]
            this.popup_exerciseSet = [] // init
            exerciseSet.forEach(function (detail) {
                if (THIS.validateExerciseSetDetailObject(detail) == true) {
                    let pos = exerciseSet.indexOf(detail)
                    if (pos > -1) {
                        THIS.popup_exerciseSet.push(exerciseSet[pos])
                    }
                }
            })
            model.exerciseSet = this.popup_exerciseSet

            // remove empty material.detail
            let material = [this.popup_materialDetailOne, this.popup_materialDetailTwo, this.popup_materialDetailThree]
            this.popup_material = [] // init
            material.forEach(function (detail) {
                if (THIS.validateMaterialDetailObject(detail) == true) {
                    let pos = material.indexOf(detail)
                    if (pos > -1) {
                        THIS.popup_material.push(material[pos])
                    }
                }
            })
            model.material = this.popup_material

            let result = await this.API.VideoContent.update(model)
            if (result.data.code != 0) {
                console.log(result)
                //
                return
            }
            console.log(result.data)
            // update vchs has vcID (result.data.data._id)
            let vcID = result.data.data._id.toString()
            console.log(vcID)
            // TODO : update vch that vcid exists

            this.initData()
            this.action_ClosePopup()
        },

        popupThumbnailFileHover(status) {
            switch (status) {
                case 'on':
                    this.popup_thumbnailFileHoverStatus = true
                    break
                case 'off':
                    this.popup_thumbnailFileHoverStatus = false
            }
        },
        popupExerciseDetailOneHover(status) {
            switch (status) {
                case 'on':
                    this.popup_exerciseDetailOne.thumbnailFileHoverStatus = true
                    console.log('ONE on')
                    break
                case 'off':
                    this.popup_exerciseDetailOne.thumbnailFileHoverStatus = false
                    console.log('ONE off')
            }
        },
        popupExerciseDetailTwoHover(status) {
            switch (status) {
                case 'on':
                    this.popup_exerciseDetailTwo.thumbnailFileHoverStatus = true
                    break
                case 'off':
                    this.popup_exerciseDetailTwo.thumbnailFileHoverStatus = false
            }
        },
        popupExerciseDetailThreeHover(status) {
            switch (status) {
                case 'on':
                    this.popup_exerciseDetailThree.thumbnailFileHoverStatus = true
                    break
                case 'off':
                    this.popup_exerciseDetailThree.thumbnailFileHoverStatus = false
            }
        },
        popupExerciseDetailFourHover(status) {
            switch (status) {
                case 'on':
                    this.popup_exerciseDetailFour.thumbnailFileHoverStatus = true
                    break
                case 'off':
                    this.popup_exerciseDetailFour.thumbnailFileHoverStatus = false
            }
        },
        popupExerciseDetailFiveHover(status) {
            switch (status) {
                case 'on':
                    this.popup_exerciseDetailFive.thumbnailFileHoverStatus = true
                    break
                case 'off':
                    this.popup_exerciseDetailFive.thumbnailFileHoverStatus = false
            }
        },
        popupMaterialDetailOneHover(status) {
            switch (status) {
                case 'on':
                    this.popup_materialDetailOne.thumbnailFileHoverStatus = true
                    break
                case 'off':
                    this.popup_materialDetailOne.thumbnailFileHoverStatus = false
            }
        },
        popupMaterialDetailTwoHover(status) {
            switch (status) {
                case 'on':
                    this.popup_materialDetailTwo.thumbnailFileHoverStatus = true
                    break
                case 'off':
                    this.popup_materialDetailTwo.thumbnailFileHoverStatus = false
            }
        },
        popupMaterialDetailThreeHover(status) {
            switch (status) {
                case 'on':
                    this.popup_materialDetailThree.thumbnailFileHoverStatus = true
                    break
                case 'off':
                    this.popup_materialDetailThree.thumbnailFileHoverStatus = false
            }
        },
        popupStimultateMusclesImageHover(status) {
            switch (status) {
                case 'on':
                    this.popup_stimulateMusclesImageHoverStatus = true
                    break
                case 'off':
                    this.popup_stimulateMusclesImageHoverStatus = false
            }
        },

        // general function below
        ISODatetoDate(date) {
            let modifiedDate = moment(date).format('YYYY-MM-DD HH:mm')
            return modifiedDate
        },

        isEmptyObject(obj) {
            // check object.keys if object is iterable or not
            for (var i in obj) return false
            return true
        }
    }
}
</script>
