<template>
    <div class="custom_select">
            <div class="input_xs w_110" @click="action_ShowList" v-click-outside="action_HideList">
            <span class="color_circle" :style="selectedItemColorString"></span>
            <span class="txt">{{ selectedItemTitle }}</span>
            <span class="chevron"><v-icon>mdi-chevron-down</v-icon></span>
        </div>
        <div class="list" v-show="isShowList">
            <div class="item" v-for="(item, index) in list" :key="index" @click="action_Select(item)" :class="{ on: selectedItem == item }">
                <span class="color_circle" :style="getColorStyleString(item)"></span>
                <span class="txt">{{ getItemTitle(item) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: undefined,
        list: Array,
        keyTitle: String
    },
    data() {
        return {
            data: undefined,
            isShowList: false,
        }
    },
    watch: {
        value: {
            immediate: true,
            handler: function(newValue) {
                this.data = newValue
            }
        },
    },
    computed: {
        selectedItem: function() {
            let item = undefined
            for (let i=0 ; i<this.list.length ; i++) {
                if (this.value == this.list[i]) {
                    item = this.list[i]
                    break
                }
            }
            if (item == undefined) { 
                item = this.list[0]
                this.$emit('update', item.id)
            }
            console.log('item:', item)
            return item
        },
        selectedItemTitle: function() {
            if (this.selectedItem == undefined) { return }
            return this.selectedItem.name
        },
        selectedItemColorString: function() {
            return 'background-color: ' + this.selectedItem.color + ';'
        }
    },
    methods: {
        getColorStyleString(item) {
            let string = "background-color: " + item.color + ';'
            return string
        },
        action_ShowList() {
            this.isShowList = true
        },
        action_HideList() {
            this.isShowList = false
        },
        action_Select(item) {
            this.$emit('update', item)
        },
        getItemTitle(item) { return item[this.keyTitle] }
    }
}
</script>


<style scoped>

</style>