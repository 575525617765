<template>
    <div class="snb">
        <div class="snb_title">이벤트 관리</div>
        <a @click="action_PushRouter('EventState')" class="snb_menu" :class="{ on: $router.currentRoute.name == 'EventState' }">이벤트 현황</a>
        <a @click="action_PushRouter('EventPushAlarmState')" class="snb_menu" :class="{ on: $router.currentRoute.name == 'EventPushAlarmState' }">이벤트 푸시 알림 현황</a>
    </div>
</template>

<script>
export default {
    methods: {
        action_PushRouter(r) {
            this.$router.push({name: `${r}`}).catch(() => {})
        },
    }
}
</script>
