import axios from 'axios'
import URL from './URL'
import Util from './Util'

const find = async function (m) {
    return axios({
        method: 'GET',
        url: URL.APPVERSION_FIND,
        params: m,
        headers: Util.getHeaders()
    })
}

const update = async function (m) {
    return axios({
        method: 'POST',
        url: URL.APPVERSION_UPDATE,
        data: m,
        headers: Util.getHeaders()
    })
}

export default {
    find: find,
    update: update
}
