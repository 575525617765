<template>
    <div class="content_wrap">
        <div class="page_title">
            <span>건강관리뉴스 설정</span>
        </div>
        <div class="h_scroll pd_15">
            <div class="table_title">
                <div class="btn_switch_wrap btn_switch_news">
                    <a class="switch on">전체(94)</a>
                    <a class="switch">건강/질병(20)</a>
                    <a class="switch">출산/육아(5)</a>
                    <a class="switch">다이어트/헬스(20)</a>
                    <a class="switch">뷰티/푸드(15)</a>
                    <a class="switch">시니어(15)</a>
                </div>
                <div class="table_title_btn">
                    <input type="text" class="input_s" placeholder="제목 검색" />
                    <button class="btn_s btn_blue_line mgl_5">검색</button>
                    <button class="btn btn_s btn_blue">뉴스 추가</button>
                </div>
            </div>
            <div class="cc">
                <div class="news_set_wrap">
                    <div class="news_set">
                        <div class="h_scroll_fix">
                            <table class="table_default fixed">
                                <thead>
                                    <tr>
                                        <th width="50px">No.</th>
                                        <th width="110px">카테고리</th>
                                        <th width="250px">제목</th>
                                        <th width="250px">내용(미리보기)</th>
                                        <th>수정</th>
                                        <th>삭제</th>
                                        <th>푸시알림</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <td>25</td>
                                    <td>다이어트/헬스</td>
                                    <td class="txt_el">문 손잡이 못만지는 나... 혹시 '이 병' 어쩌고</td>
                                    <td class="txt_el">운동을 열심히 하면 코로나에 걸릴 가능성도 낮아질까? ㅇㄹㅇㄹ</td>
                                    <td><button class="btn_s btn_blue_line btn">수정</button></td>
                                    <td><button class="btn_s btn_gray btn">삭제</button></td>
                                    <td><button class="btn_s btn_blue btn">전송</button></td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>다이어트/헬스</td>
                                    <td class="txt_el">문 손잡이 못만지는 나... 혹시 '이 병' 어쩌고</td>
                                    <td class="txt_el">운동을 열심히 하면 코로나에 걸릴 가능성도 낮아질까? ㅇㄹㅇㄹ</td>
                                    <td><button class="btn_s btn_blue_line btn">수정</button></td>
                                    <td><button class="btn_s btn_gray btn">삭제</button></td>
                                    <td><button class="btn_s btn_blue btn">전송</button></td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>다이어트/헬스</td>
                                    <td class="txt_el">문 손잡이 못만지는 나... 혹시 '이 병' 어쩌고</td>
                                    <td class="txt_el">운동을 열심히 하면 코로나에 걸릴 가능성도 낮아질까? ㅇㄹㅇㄹ</td>
                                    <td><button class="btn_s btn_blue_line btn">수정</button></td>
                                    <td><button class="btn_s btn_gray btn">삭제</button></td>
                                    <td><button class="btn_s btn_blue btn">전송</button></td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>다이어트/헬스</td>
                                    <td class="txt_el">문 손잡이 못만지는 나... 혹시 '이 병' 어쩌고</td>
                                    <td class="txt_el">운동을 열심히 하면 코로나에 걸릴 가능성도 낮아질까? ㅇㄹㅇㄹ</td>
                                    <td><button class="btn_s btn_blue_line btn">수정</button></td>
                                    <td><button class="btn_s btn_gray btn">삭제</button></td>
                                    <td><button class="btn_s btn_blue btn">전송</button></td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>다이어트/헬스</td>
                                    <td class="txt_el">문 손잡이 못만지는 나... 혹시 '이 병' 어쩌고</td>
                                    <td class="txt_el">운동을 열심히 하면 코로나에 걸릴 가능성도 낮아질까? ㅇㄹㅇㄹ</td>
                                    <td><button class="btn_s btn_blue_line btn">수정</button></td>
                                    <td><button class="btn_s btn_gray btn">삭제</button></td>
                                    <td><button class="btn_s btn_blue btn">전송</button></td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>다이어트/헬스</td>
                                    <td class="txt_el">문 손잡이 못만지는 나... 혹시 '이 병' 어쩌고</td>
                                    <td class="txt_el">운동을 열심히 하면 코로나에 걸릴 가능성도 낮아질까? ㅇㄹㅇㄹ</td>
                                    <td><button class="btn_s btn_blue_line btn">수정</button></td>
                                    <td><button class="btn_s btn_gray btn">삭제</button></td>
                                    <td><button class="btn_s btn_blue btn">전송</button></td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>다이어트/헬스</td>
                                    <td class="txt_el">문 손잡이 못만지는 나... 혹시 '이 병' 어쩌고</td>
                                    <td class="txt_el">운동을 열심히 하면 코로나에 걸릴 가능성도 낮아질까? ㅇㄹㅇㄹ</td>
                                    <td><button class="btn_s btn_blue_line btn">수정</button></td>
                                    <td><button class="btn_s btn_gray btn">삭제</button></td>
                                    <td><button class="btn_s btn_blue btn">전송</button></td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>다이어트/헬스</td>
                                    <td class="txt_el">문 손잡이 못만지는 나... 혹시 '이 병' 어쩌고</td>
                                    <td class="txt_el">운동을 열심히 하면 코로나에 걸릴 가능성도 낮아질까? ㅇㄹㅇㄹ</td>
                                    <td><button class="btn_s btn_blue_line btn">수정</button></td>
                                    <td><button class="btn_s btn_gray btn">삭제</button></td>
                                    <td><button class="btn_s btn_blue btn">전송</button></td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>다이어트/헬스</td>
                                    <td class="txt_el">문 손잡이 못만지는 나... 혹시 '이 병' 어쩌고</td>
                                    <td class="txt_el">운동을 열심히 하면 코로나에 걸릴 가능성도 낮아질까? ㅇㄹㅇㄹ</td>
                                    <td><button class="btn_s btn_blue_line btn">수정</button></td>
                                    <td><button class="btn_s btn_gray btn">삭제</button></td>
                                    <td><button class="btn_s btn_blue btn">전송</button></td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>다이어트/헬스</td>
                                    <td class="txt_el">문 손잡이 못만지는 나... 혹시 '이 병' 어쩌고</td>
                                    <td class="txt_el">운동을 열심히 하면 코로나에 걸릴 가능성도 낮아질까? ㅇㄹㅇㄹ</td>
                                    <td><button class="btn_s btn_blue_line btn">수정</button></td>
                                    <td><button class="btn_s btn_gray btn">삭제</button></td>
                                    <td><button class="btn_s btn_blue btn">전송</button></td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>다이어트/헬스</td>
                                    <td class="txt_el">문 손잡이 못만지는 나... 혹시 '이 병' 어쩌고</td>
                                    <td class="txt_el">운동을 열심히 하면 코로나에 걸릴 가능성도 낮아질까? ㅇㄹㅇㄹ</td>
                                    <td><button class="btn_s btn_blue_line btn">수정</button></td>
                                    <td><button class="btn_s btn_gray btn">삭제</button></td>
                                    <td><button class="btn_s btn_blue btn">전송</button></td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>다이어트/헬스</td>
                                    <td class="txt_el">문 손잡이 못만지는 나... 혹시 '이 병' 어쩌고</td>
                                    <td class="txt_el">운동을 열심히 하면 코로나에 걸릴 가능성도 낮아질까? ㅇㄹㅇㄹ</td>
                                    <td><button class="btn_s btn_blue_line btn">수정</button></td>
                                    <td><button class="btn_s btn_gray btn">삭제</button></td>
                                    <td><button class="btn_s btn_blue btn">전송</button></td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>다이어트/헬스</td>
                                    <td class="txt_el">문 손잡이 못만지는 나... 혹시 '이 병' 어쩌고</td>
                                    <td class="txt_el">운동을 열심히 하면 코로나에 걸릴 가능성도 낮아질까? ㅇㄹㅇㄹ</td>
                                    <td><button class="btn_s btn_blue_line btn">수정</button></td>
                                    <td><button class="btn_s btn_gray btn">삭제</button></td>
                                    <td><button class="btn_s btn_blue btn">전송</button></td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>다이어트/헬스</td>
                                    <td class="txt_el">문 손잡이 못만지는 나... 혹시 '이 병' 어쩌고</td>
                                    <td class="txt_el">운동을 열심히 하면 코로나에 걸릴 가능성도 낮아질까? ㅇㄹㅇㄹ</td>
                                    <td><button class="btn_s btn_blue_line btn">수정</button></td>
                                    <td><button class="btn_s btn_gray btn">삭제</button></td>
                                    <td><button class="btn_s btn_blue btn">전송</button></td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>다이어트/헬스</td>
                                    <td class="txt_el">문 손잡이 못만지는 나... 혹시 '이 병' 어쩌고</td>
                                    <td class="txt_el">운동을 열심히 하면 코로나에 걸릴 가능성도 낮아질까? ㅇㄹㅇㄹ</td>
                                    <td><button class="btn_s btn_blue_line btn">수정</button></td>
                                    <td><button class="btn_s btn_gray btn">삭제</button></td>
                                    <td><button class="btn_s btn_blue btn">전송</button></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    여기 페이지네이션
                </div>
                <div class="news_set_wrap">
                    <div class="form_flex_wrap">
                        <div class="form_flex_title">
                            <span class="title">푸시 전송 내용</span>
                        </div>
                        <div class="form_flex_cont">
                            <div class="form_list">
                                <div class="title">연결 페이지</div>
                                <div class="cont">
                                    <select class="input_s w_full">
                                        <option>페이지 선택</option>
                                        <option>페이지 인덱스, 페이지 제목 표시</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form_list">
                                <div class="title">푸시 타이틀</div>
                                <div class="cont"><input type="text" class="input_s w_full" placeholder="타이틀 입력" /></div>
                            </div>
                            <div class="form_list">
                                <div class="title">푸시 내용</div>
                                <div class="cont">
                                    <textarea rows="5" class="input_s w_full" placeholder="내용 입력"></textarea>
                                </div>
                            </div>
                            <div class="form_list">
                                <div class="title">전송 대상</div>
                                <div class="cont">
                                    <select class="input_s w_full" disabled>
                                        <option>전체</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form_list">
                                <div class="title">전송일시</div>
                                <div class="cont">
                                    <select class="input_s">
                                        <option>2022년</option>
                                    </select>
                                    <select class="input_s">
                                        <option>11월</option>
                                    </select>
                                    <select class="input_s">
                                        <option>28일</option>
                                    </select>
                                    <select class="input_s">
                                        <option>09시</option>
                                    </select>
                                    <select class="input_s">
                                        <option>30분</option>
                                    </select>
                                </div>
                            </div>
                            <div class="btn_wrap right pd_0 mgt_20">
                                <button class="btn btn_blue btn_l">전송</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 뉴스 추가 팝업 -->
        <!-- <div class="pop_overlay_bg">
            <div class="pop_wrap" style="width: 550px">
                <div class="pop_title">
                    <span class="title">건강관리뉴스 추가</span>
                    <a class="mgl_auto"><v-icon large>mdi-close</v-icon></a>
                </div>
                <div class="pop_cont">
                    <div class="form_list">
                        <div class="title">카테고리</div>
                        <div class="cont">
                            <select class="input_s w_full">
                                <option>건강/질병</option>
                                <option>출산/육아</option>
                                <option>다이어트/헬스</option>
                                <option>뷰티/푸드</option>
                                <option>시니어</option>
                            </select>
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">제목</div>
                        <div class="cont">
                            <textarea rows="2" class="input_s w_full" placeholder="제목 입력"></textarea>
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">내용</div>
                        <div class="cont">
                            <textarea rows="4" class="input_s w_full" placeholder="내용(미리보기)"></textarea>
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">썸네일 이미지 링크</div>
                        <div class="cont">
                            <textarea rows="4" class="input_s w_full" placeholder="이미지 url"></textarea>
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">페이지 이동 링크</div>
                        <div class="cont">
                            <textarea rows="4" class="input_s w_full" placeholder="페이지 이동할 url"></textarea>
                        </div>
                    </div>
                    <div class="btn_wrap">
                        <button class="btn btn_l btn_blue">저장</button>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {}
</script>
