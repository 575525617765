<template>
    <tr>
		<td data-row-idx="1" data-cell-idx="0" class="sot-left" style="left: 0px;">{{ getCreatedAt }}</td>
		<td data-row-idx="1" data-cell-idx="1" class="sot-left" style="left: 100px;">{{ getHospitalName }}</td>
		<td data-row-idx="1" data-cell-idx="2" class="sot-left" style="left: 250px;">{{ getTitle }}</td>
		<td data-row-idx="1" data-cell-idx="3" class="sot-left" @click="action_ShowPopup" style="cursor: pointer; left: 700px;">
			<button class="btn_s btn_blue_line">미리보기</button>
		</td>
		<td data-row-idx="1" data-cell-idx="4">{{ getViewCount }}</td>
		<td data-row-idx="1" data-cell-idx="5">{{ getViewUserCount }}</td>
		<td data-row-idx="1" data-cell-idx="6">{{ getChatCount }}</td>
		<td data-row-idx="1" data-cell-idx="7">{{ getChatUserCount }}</td>
		<td data-row-idx="1" data-cell-idx="8">{{ getChatCountPerViewCount }}</td>
		<td data-row-idx="1" data-cell-idx="9">{{ getChatUserCountPerViewUserCount }}</td>
		<td data-row-idx="1" data-cell-idx="10">{{ getPurchaseCount }}</td>
		<td data-row-idx="1" data-cell-idx="11">{{ getPurchaseUserCount }}</td>
		<td data-row-idx="1" data-cell-idx="12">{{ getPurchaseCountPerChatCount }}</td>
		<td data-row-idx="1" data-cell-idx="13">{{ getPurchaseUserCountPerChatUserCount }}</td>
		<td data-row-idx="1" data-cell-idx="14">{{ getPurchaseCountPerViewCount }}</td>
		<td data-row-idx="1" data-cell-idx="15">{{ getPurchaseUserCountPerViewUserCount }}</td>
		<td data-row-idx="1" data-cell-idx="16">{{ getTotalPrice }}</td>
		<td data-row-idx="1" data-cell-idx="17">{{ getTotalPricePerViewUserCount }}</td>
		<td data-row-idx="1" data-cell-idx="18">{{ getTotalPricePerClickUserCount }}</td>
		<td data-row-idx="1" data-cell-idx="19">{{ getTotalPricePerPurchaseUserCount }}</td>
		<td data-row-idx="1" data-cell-idx="20">{{ getTotalPricePerPurchaseCount }}</td>
	</tr>
</template>

<script>
const moment = require('moment')

export default {
	props: {
		data: Object
	},
    data() {
        return {
            isShow: false,
			isLoading: false,
        }
    },
	computed: {
		getHospitalName: function() {
			if(this.data == undefined) { return }
			if(this.data.hospitalName == undefined) { return '-' }
			return this.data.hospitalName
		},
		getTitle: function() {
			if(this.data == undefined) { return }
			if(this.data.title == undefined) { return '-' }
			return this.data.title
		},
		getEventID: function() {
			if(this.data == undefined) { return }
			if(this.data._id == undefined || this.data._id == null) {return }
			return this.data.hospitalEventID
		},
		getCreatedAt: function() {
			if(this.data == undefined) { return }
			if(this.data.createdAt == undefined) { return '-' }
			return moment(this.data.createdAt).format('YYYY-MM-DD')
		},
		getViewCount: function() {
			if(this.data == undefined) { return }
			if(this.data.viewCount == undefined || this.data.viewCount == null ) { return '-' }
			return this.data.viewCount.toLocaleString()
		},
		getViewUserCount: function() {
			if(this.data == undefined) { return }
			if(this.data.viewUserCount == undefined || this.data.viewUserCount == null ) { return '-' }
			return this.data.viewUserCount.toLocaleString()
		},
		getChatCount: function() {
			if(this.data == undefined) { return }
			if(this.data.chatCount == undefined || this.data.chatCount == null) { return '-' }
			return this.data.chatCount.toLocaleString()
		},
		getChatUserCount: function() {
			if(this.data == undefined) { return }
			if(this.data.chatUserCount == undefined || this.data.chatUserCount == null) { return '-'}
			return this.data.chatUserCount.toLocaleString()
		},
		getChatCountPerViewCount: function() {
			if(this.data == undefined) { return }
			if(this.data.chatCountPerViewCount == undefined || this.data.chatCountPerViewCount == null) { return '-' }
			return `${this.formatNumber(this.data.chatCountPerViewCount * 100)}%`
		},
		getChatUserCountPerViewUserCount: function() {
			if(this.data == undefined) { return }
			if(this.data.chatUserCountPerViewUserCount == undefined || this.data.chatUserCountPerViewUserCount == null) { return '-' }
			return `${this.formatNumber(this.data.chatUserCountPerViewUserCount * 100)}%`
		},
		getPurchaseCount: function() {
			if(this.data == undefined) { return }
			if(this.data.purchaseCount == undefined || this.data.purchaseCount == null ) { return '-'}
			return this.data.purchaseCount.toLocaleString()
		},
		getPurchaseUserCount: function() {
			if(this.data == undefined) { return }
			if(this.data.purchaseUserCount == undefined || this.data.purchaseUserCount == null) { return '-' }
			return this.data.purchaseUserCount.toLocaleString()
		},
		getPurchaseCountPerChatCount: function() {
			if(this.data == undefined) { return }
			if(this.data.purchaseCountPerChatCount == undefined || this.data.purchaseCountPerChatCount == null) { return '-' }
			return `${this.formatNumber(this.data.purchaseCountPerChatCount * 100)}%`
		},
		getPurchaseUserCountPerChatUserCount: function() {
			if(this.data == undefined) { return }
			if(this.data.purchaseUserCountPerChatUserCount == undefined || this.data.purchaseUserCountPerChatUserCount == null) { return '-' }
			return `${this.formatNumber(this.data.purchaseUserCountPerChatUserCount * 100)}%`
		},
		getPurchaseCountPerViewCount: function() {
			if(this.data == undefined) { return }
			if(this.data.purchaseCountPerViewCount == undefined || this.data.purchaseCountPerViewCount == null) { return '-' }
			return `${this.formatNumber(this.data.purchaseCountPerViewCount * 100)}%`
		},
		getPurchaseUserCountPerViewUserCount: function() {
			if(this.data == undefined) { return }
			if(this.data.purchaseUserCountPerViewUserCount == undefined || this.data.purchaseUserCountPerViewUserCount == null) { return '-' }
			return `${this.formatNumber(this.data.purchaseUserCountPerViewUserCount * 100)}%`
		},
		getTotalPrice: function() {
			if(this.data == undefined) { return }
			if(this.data.totalPrice == undefined || this.data.totalPrice == null) { return '-' }
			return Number(this.formatNumber(this.data.totalPrice)).toLocaleString()
		},
		getTotalPricePerViewUserCount: function() {
			if(this.data == undefined) { return }
			if(this.data.totalPricePerViewUserCount == undefined || this.data.totalPricePerViewUserCount == null ) { return '-' }
			return Number(this.formatNumber(this.data.totalPricePerViewUserCount)).toLocaleString()
		},
		getTotalPricePerClickUserCount: function() {
			if(this.data == undefined) { return }
			if(this.data.totalPricePerClickUserCount == undefined || this.data.totalPricePerClickUserCount == null ) { return '-' }
			return Number(this.formatNumber(this.data.totalPricePerClickUserCount)).toLocaleString()
		},
		getTotalPricePerPurchaseUserCount: function() {
			if(this.data == undefined) { return }
			if(this.data.totalPricePerPurchaseUserCount == undefined || this.data.totalPricePerPurchaseUserCount == null ) { return '-' }
			return Number(this.formatNumber(this.data.totalPricePerPurchaseUserCount)).toLocaleString()
		},
		getTotalPricePerPurchaseCount: function() {
			if(this.data == undefined) { return }
			if(this.data.totalPricePerPurchaseCount == undefined || this.data.totalPricePerPurchaseCount == null ) { return '-' }
			return Number(this.formatNumber(this.data.totalPricePerPurchaseCount)).toLocaleString()
		},
	},
    methods: {
		formatNumber(num) {
            if (Number.isInteger(num)) {
                return num.toString()
            } else {
                return num.toFixed(1)
            }
        },
        action_ShowPopup() {
            this.isShow = true
			this.$emit('action_ShowPopup', this.getEventID)
        },
        action_ClosePopup() {
            this.isShow = false
			this.$emit('action_ClosePopup', this.getEventID)
        }
    }
}
</script>