<template>
    <div class="snb">
        <div class="snb_title">병원 관리</div>
        <a @click="action_HospitalSetting" class="snb_menu" :class="{ on: $router.currentRoute.name == 'HospitalSetting' }">병원 설정</a>
        <a @click="action_AccountSetting" class="snb_menu" :class="{ on: $router.currentRoute.name == 'AccountSetting' }">계정 설정</a>
    </div>
</template>

<script>
export default {
    methods: {
        action_HospitalSetting() {
            this.$router.push({ name: 'HospitalSetting' }).catch(() => {})
        },
        action_AccountSetting() {
            this.$router.push({ name: 'AccountSetting' }).catch(() => {})
        }
    }
}
</script>

<style scoped></style>
