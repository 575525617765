<template>
    <div class="pop_overlay_bg" v-if="answeringchatpopup.isShow">
        <ToastComponent :isShow="isShowErrorMessage" :noticeMessage="`저장되었습니다.`" />
        <div class="answeringChat_pop_wrap">
            <PopupTitleSection title="자동 응대 메세지 설정" @action_Close="action_Close"/>
            <div class="inner_wrap">
                <div class="content_wrap">
                <div class="title">병원명</div>
                <div class="hospital_name">{{hospitalName}}</div>
                </div>
                <div class="content_wrap">
                    <div>
                        <div class="title">채팅 예약 상담 시 자동 응대</div>
                        <div class="description">자동 응대 채팅 기능 및 [앱 - 채팅 예약]을 클릭했을 때, 자동 응대 채팅 메시지를 발송합니다.</div>
                    </div>
                    <div>
                        <input type="radio" v-model="isUsed" value="false" />
                        <span class="checkmark"></span> 미사용 &nbsp;
                        <input type="radio" v-model="isUsed" value="true" />
                        <span class="checkmark"></span> 사용 
                    </div>
                </div>
                <div class="content_wrap">
                    <div>
                        <div class="title">일반 텍스트 상담 시 자동 응대</div>
                        <div class="description">고객이 앱을 통해 일반 채팅 또는 사진을 보냈을 때, 자동 응대 채팅 메시지를 발송합니다.</div>
                    </div>
                    <div>
                        <input type="radio" v-model="usingGeneralChat" value="false" />
                        <span class="checkmark"></span> 미사용 &nbsp;
                        <input type="radio" v-model="usingGeneralChat" value="true" />
                        <span class="checkmark"></span> 사용 
                    </div>
                </div>
                <hr/>
                <div class="content_wrap">
                    <div class="title">병원 운영시간 내 메시지</div>
                    <div class="cont">
                        <input type="radio" v-model="usingBusinessHourMessage" value="false" />
                        <span class="checkmark"></span> 미사용 &nbsp;
                        <input type="radio" v-model="usingBusinessHourMessage" value="true" />
                        <span class="checkmark"></span> 사용 
                        <textarea v-model="businessHourMessage" @focus="focus_Input"/>
                    </div>
                </div>
                <!-- <div class="content_wrap">
                    <div class="title">병원 운영시간 내 메시지 내용</div>
                        <textarea v-model="businessHourMessage" />
                </div> -->
                <hr/> 
                <div class="content_wrap">
                    <div class="title">병원 운영시간 외 메시지</div>
                    <div class="cont">
                        <input type="radio" v-model="usingNonBusinessHourMessage" value="false" />
                        <span class="checkmark"></span> 미사용 &nbsp;
                        <input type="radio" v-model="usingNonBusinessHourMessage" value="true" />
                        <span class="checkmark"></span> 사용 
                        <textarea v-model="nonBusinessHourMessage" @focus="focus_Input"/>
                    </div>
                </div>
                <!-- <div class="content_wrap">
                    <div class="title">병원 운영시간 외 메시지 내용</div>
                </div> -->
                <hr/>
                <div class="content_wrap">
                    <div>
                        <div class="title">자동 응대 대기시간</div>
                        <div class="description">메시지 발송까지의 분 단위 대기 시간입니다.</div>
                    </div>
                    <div class="cont">
                        <select class="input_s" v-model="interval">
                            <option value="1">1</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                            <option value="30">30</option> 
                            <option value="35">35</option>
                            <option value="40">40</option>
                            <option value="45">45</option>
                            <option value="50">50</option>
                            <option value="55">55</option>
                            <option value="60">60</option>
                        </select>
                        <!-- <input class="interval_input" type="number" placeholder="10" min="0" v-model="nonBusinessHourInterval"/> -->
                    </div>
                </div>
                <hr/>
                        <div class="form_list">
                            <div class="title" style="color: black">병원 운영시간</div>
                            <div class="cont">
                                <div class="mgt_15" v-if="careSchedule.length == 0">
                                <button class="btn btn_s btn_blue" @click="action_AddTime()">+ 추가</button>
                                </div>
                                <div class="mgt_15" v-for="(t, index) in careSchedule" :key="index">
                                <select class="input_s" v-model="t.dayIndex">
                                    <option value="1">월</option>
                                    <option value="2">화</option>
                                    <option value="3">수</option>
                                    <option value="4">목</option>
                                    <option value="5">금</option>
                                    <option value="6">토</option>
                                    <option value="0">일</option> 
                                </select>
                                <select class="input_s" v-model="t.s_Hour">
                                    <option :value="index" v-for="(i, index) in 24" :key="i">{{ ('0' + index).slice(-2) }}시</option>
                                </select>
                                <select class="input_s" v-model="t.s_Minute">
                                    <option :value="index" v-for="(i, index) in 60" :key="i">{{ ('0' + index).slice(-2) }}분</option>
                                </select>
                                ~
                                <select class="input_s" v-model="t.e_Hour">
                                    <option :value="index" v-for="(i, index) in 24" :key="i">{{ ('0' + index).slice(-2) }}시</option>
                                </select>
                                <select class="input_s" v-model="t.e_Minute">
                                    <option :value="index" v-for="(i, index) in 60" :key="i">{{ ('0' + index).slice(-2) }}분</option>
                                </select>
                                <button class="btn btn_s btn_blue" @click="action_AddTime(index + 1)">+ 추가</button>
                                <button class="btn btn_s btn_gray" @click="action_RemoveTime(index)">- 삭제</button>
                        </div>                                
                    </div>
                </div>
            </div>
            <small class="red_txt mgt_10 txt_center" v-show="isError == true">
                {{ errorMessage }}
            </small>
            <div class="btn_wrap">
                <button class="btn btn_l btn_gray" @click="action_Close">닫기</button>
                <button class="btn btn_l btn_blue" @click="action_Save">저장</button>
            </div>
        </div>    
    </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
import PopupTitleSection from '@/views/main/ShareComponents/PopupTitleSection.vue'
import ToastComponent from '../../ShareComponents/ToastComponent.vue'

const moment = require('@/util/MPMoment')

export default {
    components: { PopupTitleSection, ToastComponent },
    data() {
        return {
            hospitalID: undefined,
            hospitalName: undefined,
            isShowErrorMessage: false,
            
            // 설정 변수 
            interval: 0,
            isUsed: false,
            usingGeneralChat: false,
            answeringID: null,
            careSchedule: [],
            businessHourMessage: '',
            nonBusinessHourMessage: '',
            // businessHourInterval: 0,
            // nonBusinessHourInterval: 0,
            usingBusinessHourMessage: false,
            usingNonBusinessHourMessage: false,

            // errors
            errorMessage: '',
            isError: false
        }
    },
    watch: {
        'answeringchatpopup.isShow': {
            immediate: true,
            handler: function(newValue) {
                if(newValue == true) {
                    this.addWindowEvent()
                }
            }
        },
        'answeringchatpopup.selectedHospital': {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                if(newValue == undefined) {return}
                this.hospitalID = newValue._id
                this.hospitalName = newValue.shortName
                this.loadAnsweringChatSetting()
            }
        },
    },
    computed: {
        ...mapState(['answeringchatpopup']),
    },
    methods: {
        ...mapActions(['setAnsweringChatPopupState']),

        // MARK: - network
        async loadAnsweringChatSetting() {
            try {
                if(this.hospitalID == undefined) { return }
                let model = { hospitalID: this.hospitalID}
                let result = await this.API.AnsweringChat.find(model)

                if(result.data.code == 0 ) {
                    result = result.data.data[0]
                    if(result == undefined) {
                        this.isUsed = false
                        this.careSchedule = []
                        this.interval = 30
                        return
                    }
                    this.answeringID                 = result._id
                    this.isUsed                      = result.usingAnsweringChat
                    this.usingGeneralChat            = result.usingGeneralChat
                    // this.businessHourInterval        = result.businessHourInterval
                    // this.nonBusinessHourInterval     = result.nonBusinessHourInterval
                    this.usingBusinessHourMessage    = result.usingBusinessHourMessage
                    this.usingNonBusinessHourMessage = result.usingNonBusinessHourMessage
                    this.businessHourMessage         = result.businessHourMessage
                    this.nonBusinessHourMessage      = result.nonBusinessHourMessage
                    this.interval                    = result.interval  
                    this.isError = false
                    this.errorMessage = ''                 

                    for(let i = 0; i < result.careSchedule.length; i++) {
                        let s = moment(result.careSchedule[i].startedAt)
                        let e = moment(result.careSchedule[i].endedAt)
                        result.careSchedule[i].s_Hour     = s.hours()
                        result.careSchedule[i].s_Minute   = s.minutes()
                        result.careSchedule[i].e_Hour     = e.hours()
                        result.careSchedule[i].e_Minute   = e.minutes()
                    }   this.careSchedule = result.careSchedule
                }
            }
            catch(err) {console.log(err)}
        },
        async API_AnsweringChatConfig_create() {
            this.isError = false
            this.errorMessage = ''
            if (
                (
                    (this.businessHourMessage == '' || this.businessHourMessage == undefined) &&
                    (this.usingBusinessHourMessage == true || this.usingBusinessHourMessage == "true")
                ) ||
                (
                    (this.nonBusinessHourMessage == '' || this.nonBusinessHourMessage == undefined) &&
                    (this.usingNonBusinessHourMessage == true || this.usingNonBusinessHourMessage == "true")
                )
            ) {
                this.isError = true
                this.errorMessage = '발송될 자동 응대 메시지를 입력해주세요.'
                console.log("리턴")
                return
            }

            console.log("여기 왜들어옴")
            // careSchedule convert
            for (let i = 0; i < this.careSchedule.length; i++) {
                let s = moment(0).startOf('days')
                s.add(this.careSchedule[i].s_Hour, 'hours')
                s.add(this.careSchedule[i].s_Minute, 'minutes')
                let e = moment(0).startOf('days')
                e.add(this.careSchedule[i].e_Hour, 'hours')
                e.add(this.careSchedule[i].e_Minute, 'minutes')
                this.careSchedule[i].startedAt = s.format()
                this.careSchedule[i].endedAt = e.format()
            }
            let model = {
                hospitalID: this.hospitalID,
                usingAnsweringChat: this.isUsed,
                usingGeneralChat: this.usingGeneralChat,
                careSchedule: this.careSchedule,
                // businessHourInterval: this.businessHourInterval,
                // nonBusinessHourInterval: this.nonBusinessHourInterval,
                usingBusinessHourMessage: this.usingBusinessHourMessage,
                usingNonBusinessHourMessage: this.usingNonBusinessHourMessage,
                businessHourMessage: this.businessHourMessage,
                nonBusinessHourMessage: this.nonBusinessHourMessage,
                interval: this.interval
            }
            let result = await this.API.AnsweringChat.create(model)
            this.show_ToastMessage()
            setTimeout(() => {
                this.action_Close()
                this.$emit('action_Close')
            }, 500)
            if(result.data.code != 0) {throw result}
        },
        async API_AnsweringChatConfig_update() {
            this.isError = false
            this.errorMessage = ''
            if (
                (
                    (this.businessHourMessage == '' || this.businessHourMessage == undefined) &&
                    (this.usingBusinessHourMessage == true || this.usingBusinessHourMessage == "true")
                ) ||
                (
                    (this.nonBusinessHourMessage == '' || this.nonBusinessHourMessage == undefined) &&
                    (this.usingNonBusinessHourMessage == true || this.usingNonBusinessHourMessage == "true")
                )
            ) {
                this.isError = true
                this.errorMessage = '발송될 자동 응대 메시지를 입력해주세요.'
                return
            }
            // careSchedule convert
            for (let i = 0; i < this.careSchedule.length; i++) {
                let s = moment(0).startOf('days')
                s.add(this.careSchedule[i].s_Hour, 'hours')
                s.add(this.careSchedule[i].s_Minute, 'minutes')
                let e = moment(0).startOf('days')
                e.add(this.careSchedule[i].e_Hour, 'hours')
                e.add(this.careSchedule[i].e_Minute, 'minutes')
                this.careSchedule[i].startedAt = s.format()
                this.careSchedule[i].endedAt = e.format()
            }
            let model = {
                _id: this.answeringID,
                usingAnsweringChat: this.isUsed,
                usingGeneralChat: this.usingGeneralChat,
                careSchedule: this.careSchedule,
                // businessHourInterval: this.businessHourInterval,
                // nonBusinessHourInterval: this.nonBusinessHourInterval,
                usingBusinessHourMessage: this.usingBusinessHourMessage,
                usingNonBusinessHourMessage: this.usingNonBusinessHourMessage,
                businessHourMessage: this.businessHourMessage,
                nonBusinessHourMessage: this.nonBusinessHourMessage,
                interval: this.interval
            }
            let result = await this.API.AnsweringChat.update(model)
            this.show_ToastMessage()
            if(result.data.code != 0) {throw result}
            setTimeout(() => {
                this.action_Close()
                this.$emit('action_Close')
            }, 500)
        },
        show_ToastMessage() {
            console.log("왜들어옴")
            this.isShowErrorMessage = true

            setTimeout(() => {
                this.isShowErrorMessage = false
            }, 1000)
        },
        // MARK: - actions
        async action_Save() {
            if(this.answeringID == null) {
                this.API_AnsweringChatConfig_create() // create
            } 
            if(this.answeringID != null) {
                this.API_AnsweringChatConfig_update() // update
            }
        },
        async action_Close() {
            this.answeringID                 = null
            this.isUsed                      = undefined
            this.usingGeneralChat            = undefined
            this.hospitalID                  = undefined
            // this.businessHourInterval        = undefined
            // this.nonBusinessHourInterval     = undefined
            this.usingBusinessHourMessage    = undefined
            this.usingNonBusinessHourMessage = undefined
            this.businessHourMessage         = undefined
            this.nonBusinessHourMessage      = undefined
            this.careSchedule                = []
            this.interval                    = 0
            this.isError = false
            this.errorMessage = ''
        await this.setAnsweringChatPopupState({
                isShow: false,
                selectedHospital: undefined,
            })
        },
        action_AddTime(index) {
            if (this.careSchedule == undefined) { this.careSchedule = [] }
            let newModel = {
                dayIndex: 1,
                startedAt: moment(0).startOf('days').format(),
                endedAt: moment(0).startOf('days').format(),
                s_Hour: 0,
                s_Minute: 0,
                e_Hour: 0,
                e_Minute: 0
            }
            if (index != undefined) { this.careSchedule.splice(index, 0, newModel) } 
            else { this.careSchedule.push(newModel) }
        },
        action_RemoveTime(index) {
            this.careSchedule.splice(index, 1)
        },
        keyDownHandler(event) { if (event.keyCode == 27) { this.action_Close() } },
        addWindowEvent() { window.addEventListener('keyup', this.keyDownHandler) },
        focus_Input() {
            this.isError = false
        }
    }
}

</script>