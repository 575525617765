<template>
    <div class="cc">
        <ReservedChatNavigation />
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script>
import ReservedChatNavigation from './Navigation/ReservedChatNavigation.vue'
export default {
    components: { ReservedChatNavigation }
}
</script>

<style scoped></style>
