<template>
    <div class="layout_wrap">
        <Navigation />
        <div class="cont_fix">
            <router-view :key="$route.fullPath"></router-view>
        </div>
    </div>
</template>

<script>
import Navigation from './SubViews/NavigationPage'
export default {
    components: { Navigation }
}
</script>

<style scoped></style>
