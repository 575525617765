<template>
    <div class="content_wrap">
        <Loading :isLoading="isLoading" />
        <div class="page_title"><span>이벤트 현황</span></div>
        <div class="h_scroll" style="justify-content: space-between">
            <div>
                <div class="table_title">
                    <div class="cont">
                        <p class="dgray_txt">
                            총 <span class="black_txt">{{ hTotalCount }}</span
                            >개
                        </p>
                    </div>
                    <div class="table_title_btn">
                        <input type="text" class="input_s" placeholder="병원명" v-model="keyword.hospital" @keyup.enter="loadData(0)" />
                        <input type="text" class="input_s" placeholder="이벤트 제목" v-model="keyword.event" @keyup.enter="loadData(0)" />
                        <button class="btn_s btn_blue_line mgl_5" @click="loadData(0)">검색</button>
                        <select class="input_s mgl_5" v-model="viewType">
                            <option value="100개씩 보기">100개씩 보기</option>
                            <option value="150개씩 보기">150개씩 보기</option>
                            <option value="200개씩 보기">200개씩 보기</option>
                        </select>
                    </div>
                </div>
                <div id="sot7" class="sot" data-sot-top="1" data-sot-left="4" data-sot-bottom="0" data-sot-right="0">
                    <table class="table_default">
                        <colgroup>
                            <col width="100px" />
                            <col width="150px" />
                            <col width="450px" />
                            <col width="110px" />
                            <col width="100px" />
                            <col width="120px" />
                            <col width="150px" />
                            <col width="110px" />
                            <col width="120px" />
                            <col width="110px" />
                            <col width="100px" />
                            <col width="120px" />
                            <col width="120px" />
                            <col width="120px" />
                            <col width="90px" />
                            <col width="120px" />
                            <col width="120px" />
                            <col width="120px" />
                            <col width="120px" />
                            <col width="120px" />
                            <col width="120px" />
                        </colgroup>
                        <tbody>
                            <tr class="no_hover">
                                <th
                                    data-row-idx="0"
                                    data-cell-idx="0"
                                    class="sot-top sot-left"
                                    @click="action_TableHeader('updatedAt')"
                                    style="top: 0px; left: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >등록일 <v-icon class="sort_icon ascending_order">{{ changeSortIcon('updatedAt') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    data-row-idx="0"
                                    data-cell-idx="1"
                                    class="sot-top sot-left"
                                    @click="action_TableHeader('hospitalName')"
                                    style="top: 0px; left: 100px"
                                >
                                    <span style="vertical-align: middle"
                                        >병원명 <v-icon class="sort_icon">{{ changeSortIcon('hospitalName') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    data-row-idx="0"
                                    data-cell-idx="2"
                                    class="sot-top sot-left"
                                    @click="action_TableHeader('title')"
                                    style="top: 0px; left: 250px"
                                >
                                    <span style="vertical-align: middle"
                                        >이벤트 제목 <v-icon class="sort_icon">{{ changeSortIcon('title') }}</v-icon></span
                                    >
                                </th>
                                <th data-row-idx="0" data-cell-idx="3" class="sot-top sot-left" style="top: 0px; left: 700px">
                                    <span style="vertical-align: middle">미리보기</span>
                                </th>
                                <th data-row-idx="0" data-cell-idx="4" class="sot-top" @click="action_TableHeader('viewCount')" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >조회수 <v-icon class="sort_icon">{{ changeSortIcon('viewCount') }}</v-icon></span
                                    >
                                </th>
                                <th data-row-idx="0" data-cell-idx="5" class="sot-top" @click="action_TableHeader('viewUserCount')" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >조회 유저 수 <v-icon class="sort_icon">{{ changeSortIcon('viewUserCount') }}</v-icon></span
                                    >
                                </th>
                                <th data-row-idx="0" data-cell-idx="6" class="sot-top" @click="action_TableHeader('chatCount')" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >상담버튼 클릭 수 <v-icon class="sort_icon">{{ changeSortIcon('chatCount') }}</v-icon></span
                                    >
                                </th>
                                <th data-row-idx="0" data-cell-idx="7" class="sot-top" @click="action_TableHeader('chatUserCount')" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >상담버튼 클릭 유저 수 <v-icon class="sort_icon">{{ changeSortIcon('chatUserCount') }}</v-icon></span
                                    >
                                </th>
                                <th data-row-idx="0" data-cell-idx="8" class="sot-top" @click="action_TableHeader('chatCountPerViewCount')" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >상담버튼 클릭 수 / 조회수 <v-icon class="sort_icon">{{ changeSortIcon('chatCountPerViewCount') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    data-row-idx="0"
                                    data-cell-idx="9"
                                    class="sot-top"
                                    @click="action_TableHeader('chatUserCountPerViewUserCount')"
                                    style="top: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >상담버튼 클릭 유저 수 / 조회 유저 수
                                        <v-icon class="sort_icon">{{ changeSortIcon('chatUserCountPerViewUserCount') }}</v-icon></span
                                    >
                                </th>
                                <th data-row-idx="0" data-cell-idx="10" class="sot-top" @click="action_TableHeader('purchaseCount')" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >구매 횟수 <v-icon class="sort_icon">{{ changeSortIcon('purchaseCount') }}</v-icon></span
                                    >
                                </th>
                                <th data-row-idx="0" data-cell-idx="11" class="sot-top" @click="action_TableHeader('purchaseUserCount')" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >구매 유저 수 <v-icon class="sort_icon">{{ changeSortIcon('purchaseUserCount') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    data-row-idx="0"
                                    data-cell-idx="12"
                                    class="sot-top"
                                    @click="action_TableHeader('purchaseCountPerChatCount')"
                                    style="top: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >구매 횟수 / 상담버튼 클릭 수 <v-icon class="sort_icon">{{ changeSortIcon('purchaseCountPerChatCount') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    data-row-idx="0"
                                    data-cell-idx="13"
                                    class="sot-top"
                                    @click="action_TableHeader('purchaseUserCountPerChatUserCount')"
                                    style="top: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >구매 유저 수 / 상담 버튼 클릭 유저 수
                                        <v-icon class="sort_icon">{{ changeSortIcon('purchaseUserCountPerChatUserCount') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    data-row-idx="0"
                                    data-cell-idx="14"
                                    class="sot-top"
                                    @click="action_TableHeader('purchaseCountPerViewCount')"
                                    style="top: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >구매횟수 / 조회수 <v-icon class="sort_icon">{{ changeSortIcon('purchaseCountPerViewCount') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    data-row-idx="0"
                                    data-cell-idx="15"
                                    class="sot-top"
                                    @click="action_TableHeader('purchaseUserCountPerViewUserCount')"
                                    style="top: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >구매 유저 수 / 조회 유저 수
                                        <v-icon class="sort_icon">{{ changeSortIcon('purchaseUserCountPerViewUserCount') }}</v-icon></span
                                    >
                                </th>
                                <th data-row-idx="0" data-cell-idx="16" class="sot-top" @click="action_TableHeader('totalPrice')" style="top: 0px">
                                    <span style="vertical-align: middle"
                                        >총 금액 <v-icon class="sort_icon">{{ changeSortIcon('totalPrice') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    data-row-idx="0"
                                    data-cell-idx="17"
                                    class="sot-top"
                                    @click="action_TableHeader('totalPricePerViewUserCount')"
                                    style="top: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >총 금액 / 조회 유저 수 <v-icon class="sort_icon">{{ changeSortIcon('totalPricePerViewUserCount') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    data-row-idx="0"
                                    data-cell-idx="18"
                                    class="sot-top"
                                    @click="action_TableHeader('totalPricePerClickUserCount')"
                                    style="top: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >총 금액 / 상담 버튼 클릭 유저 수 <v-icon class="sort_icon">{{ changeSortIcon('totalPricePerClickUserCount') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    data-row-idx="0"
                                    data-cell-idx="19"
                                    class="sot-top"
                                    @click="action_TableHeader('totalPricePerPurchaseUserCount')"
                                    style="top: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >총 금액 / 구매 유저 수 <v-icon class="sort_icon">{{ changeSortIcon('totalPricePerPurchaseUserCount') }}</v-icon></span
                                    >
                                </th>
                                <th
                                    data-row-idx="0"
                                    data-cell-idx="20"
                                    class="sot-top"
                                    @click="action_TableHeader('totalPricePerPurchaseCount')"
                                    style="top: 0px"
                                >
                                    <span style="vertical-align: middle"
                                        >총 금액 / 구매 횟수 <v-icon class="sort_icon">{{ changeSortIcon('totalPricePerPurchaseCount') }}</v-icon></span
                                    >
                                </th>
                            </tr>
                            <Item v-for="(data, idx) in hData" :key="idx" :data="data" 
                                @action_ShowPopup="action_ShowPopup"
                                @action_ClosePopup="action_ClosePopup" 
                            />
                            <tr v-if="hData.length == 0">
                                <td data-row-idx="1" data-cell-idx="0" class="sot-left txt_center" colspan="12">조회된 값이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Pagenation
                    v-bind:totalCount="hTotalCount"
                    v-bind:currentPage="hCurrentPage"
                    v-bind:countPerPage="hCountPerPage"
                    v-bind:countPerRow="5"
                    v-on:changePage="hChangePage"
                />
                <Popup 
                    :isShow="isShow"
                    :item="popupEventID"
                    @action_Close="action_ClosePopup"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Item from './SubComponent/EventStatusListItem.vue'
import PageController from '@/controller/PageController.js'
import Loading from '@/views/main/ShareComponents/LoadingIndicate.vue'
import Pagenation from '@/views/main/ShareComponents/PagenationComponent.vue'
import Popup from './SubComponent/EventStatusListItemPopup.vue'

export default {
    components: { Pagenation, Loading, Item, Popup },
    data() {
        return {
            isLoading: false,
            isShow: false,
            popupEventID: undefined,
            keyword: {
                hospital: '',
                event: ''
            },
            viewType: '100개씩 보기',
            sortKey: undefined,
            sortWay: undefined,

            // pagenation
            pageController: undefined,
            hData: [],
            hTotalCount: 0,
            hCurrentPage: 0,
            hCountPerPage: 100
        }
    },
    watch: {
        viewType: {
            immediate: true,
            handler: function (newValue, oldValue) {
                if (oldValue == undefined || newValue == oldValue) {
                    return
                }
                if (newValue == '100개씩 보기') {
                    this.hCountPerPage = 100
                }
                if (newValue == '150개씩 보기') {
                    this.hCountPerPage = 150
                }
                if (newValue == '200개씩 보기') {
                    this.hCountPerPage = 200
                }
                this.hCurrentPage = 0
                this.pageController.countPerPage = this.hCountPerPage
                this.loadData(this.hCurrentPage)
            }
        }
    },
    mounted() {
        this.pageController = new PageController(this.API.HospitalEvent.findStats)
        this.pageController.countPerPage = this.hCountPerPage
        this.loadData(this.hCurrentPage)
    },
    methods: {
        // load
        async loadData(page) {
            this.isLoading = true
            
            this.pageController.filter = {}
            this.pageController.filter.hospitalName = this.keyword.hospital
            this.pageController.filter.title = this.keyword.event
            this.pageController.filter.key = this.sortKey 
            this.pageController.filter.way = this.sortWay

            await this.pageController.loadPageIndex(page)
            await this.loadDataCount()

            this.hData = this.pageController.data
            this.isLoading = false
        },
        async loadDataCount() {
            this.isLoading = true
            let result = await this.API.HospitalEvent.count(this.pageController.filter)
            if (result.data.code != 0) {
                console.log(result)
            }
            this.hTotalCount = result.data.data
            this.isLoading = false
        },

        // action
        action_TableHeader(key) {
            if (this.sortKey == key) {
                if (this.sortWay == 1) {
                    this.sortWay = -1
                } else if (this.sortWay == -1) {
                    this.sortKey = undefined
                    this.sortWay = undefined
                } else {
                    this.sortWay = 1
                }
            } else {
                this.sortKey = key
                this.sortWay = 1
            }
            this.loadData(this.hCurrentPage)
        },

        // handler
        hChangePage(page) {
            this.hCurrentPage = page
            this.loadData(this.hCurrentPage)
        },
        changeSortIcon(sort) {
            if (this.sortKey != sort) {
                return ''
            }
            if (this.sortWay == 1) {
                return 'mdi-menu-up'
            }
            return 'mdi-menu-down'
        },

        action_ShowPopup(id) {
            this.isShow = true
            this.popupEventID = id
        },
        action_ClosePopup() {
            this.isShow = false
        }
    }
}
</script>
