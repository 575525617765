<template>
    <div v-if="isShow == true">
        <div class="toast_error" v-if="noticeMessage == errorMessage">
            {{ noticeMessage }}
        </div>
        <div class="toast_error" v-if="noticeMessage == duplicateMessage">
            {{ noticeMessage }}
        </div>
        <div class="toast_success" v-if="noticeMessage == successMessage">
            {{ noticeMessage }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errorMessage: '이름이 입력되지 않았습니다.',
            duplicateMessage: '이미 등록된 치료 유형입니다.',
            successMessage: '저장되었습니다.'
        }
    },

    props: {
        isShow: Boolean,
        noticeMessage: String
    }
}
</script>
