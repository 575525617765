<template>
    <div class="pop_overlay_bg" v-if="isShow">
        <LoadingIndicate :isLoading="isLoading" />
        <div class="chatResponse_pop_wrap">
            <PopupTitleSection title="채팅 응대" @action_Close="action_Close" />
            <div class="pop_cont pop_scroll_wrap">
                <div class="pop_scroll">
                    <!-- 병원 응대 사용 -->
                    <div class="form_list">
                        <div class="title">병원 응대 사용</div>
                        <div class="cont">
                            <label class="radio_wrap"
                                ><input type="radio" name="is" v-model="usingChatResponseRequired" :value="false" />
                                <span class="checkmark"></span>
                                미사용
                            </label>
                            <label class="radio_wrap"
                                ><input type="radio" name="is" v-model="usingChatResponseRequired" :value="true" />
                                <span class="checkmark"></span>
                                사용
                            </label>
                        </div>
                    </div>
                    <!-- 담당자 설정 -->
                    <div class="form_list">
                        <div class="title">담당자 설정</div>
                        <div class="cont">
                            <div class="select_item_wrap">
                                <table class="table_default">
                                    <thead>
                                        <tr>
                                            <th>이름</th>
                                            <th>고객이보는이름</th>
                                            <th>직책</th>
                                            <th>ID</th>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr v-if="accountList.length == 0">
                                            <td>비어있습니다.</td>
                                        </tr>
                                    </thead>
                                    <thead v-if="accountList.length > 0">
                                        <tr v-for="(a, index) in accountList" :key="index">
                                            <td>
                                                <label class="check_wrap"
                                                    ><input
                                                        type="checkbox"
                                                        name="chatResponseUser"
                                                        v-model="a.isSelected"
                                                        :disabled="usingChatResponseRequired == false"
                                                    /><span class="checkmark"></span>{{ a.realName }}</label
                                                >
                                            </td>
                                            <td>{{ a.name }}</td>
                                            <td>{{ a.jobType }}</td>
                                            <td>{{ a.id }}</td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- 병원 응대 시간 -->
                    <div class="form_list">
                        <div class="title">병원 응대 시간</div>
                        <div class="cont">
                            <div class="mgt_15">
                                <button class="btn btn_s btn_blue" @click="action_AddTime()">+ 추가</button>
                            </div>
                            <div class="mgt_15" v-for="(t, index) in chatResponseRequiredTime" :key="index">
                                <select class="input_s" v-model="t.dayIndex">
                                    <option value="1">월</option>
                                    <option value="2">화</option>
                                    <option value="3">수</option>
                                    <option value="4">목</option>
                                    <option value="5">금</option>
                                    <option value="6">토</option>
                                    <option value="0">일</option>
                                </select>
                                <select class="input_s" v-model="t.s_Hour">
                                    <option :value="index" v-for="(i, index) in 24" :key="i">{{ ('0' + index).slice(-2) }}시</option>
                                </select>
                                <select class="input_s" v-model="t.s_Minute">
                                    <option :value="index" v-for="(i, index) in 60" :key="i">{{ ('0' + index).slice(-2) }}분</option>
                                </select>
                                ~
                                <select class="input_s" v-model="t.e_Hour">
                                    <option :value="index" v-for="(i, index) in 24" :key="i">{{ ('0' + index).slice(-2) }}시</option>
                                </select>
                                <select class="input_s" v-model="t.e_Minute">
                                    <option :value="index" v-for="(i, index) in 60" :key="i">{{ ('0' + index).slice(-2) }}분</option>
                                </select>
                                <button class="btn btn_s btn_blue" @click="action_AddTime(index + 1)">+ 추가</button>
                                <button class="btn btn_s btn_gray" @click="action_RemoveTime(index)">- 삭제</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn_wrap">
                <button class="btn btn_l btn_blue" @click="action_Save">저장</button>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'
import PopupTitleSection from '@/views/main/ShareComponents/PopupTitleSection.vue'

const moment = require('@/util/MPMoment')

export default {
    components: {
        LoadingIndicate,
        PopupTitleSection
    },
    props: {
        isShow: Boolean,
        hospitalID: String
    },
    data() {
        return {
            accountList: [],
            chatResponseRequiredTime: [],
            isLoading: false,
            usingChatResponseRequired: false,
            serviceSetting: undefined
        }
    },
    watch: {
        hospitalID: {
            immediate: true,
            handler: function () {
                this.loadDatas()
            }
        },
        serviceSetting: {
            immediate: true,
            handler: function (newValue) {
                if (newValue == undefined) {
                    this.usingChatResponseRequired = false
                } else if (newValue.usingChatResponseRequired == undefined || newValue.usingChatResponseRequired == '') {
                    this.usingChatResponseRequired = false
                } else {
                    this.usingChatResponseRequired = newValue.usingChatResponseRequired
                }
            }
        }
    },
    computed: {},
    methods: {
        // MARK: - Network
        async loadDatas() {
            this.isLoading = true
            await this.loadHospitalSetting()
            await this.loadAccountList()
            this.parseSelectedAccount()
            this.isLoading = false
        },
        async loadHospitalSetting() {
            if (this.hospitalID == undefined || this.hospitalID == '') {
                return
            }
            let result = await this.API.ServiceSetting.getServiceSettingDetail({
                hospitalID: this.hospitalID
            })
            if (result.data.code != 0) {
                console.log(result)
                return
            }

            this.serviceSetting = result.data.data
            for (let i = 0; i < this.serviceSetting.chatResponseRequiredTime.length; i++) {
                let s = moment(this.serviceSetting.chatResponseRequiredTime[i].startedAt)
                let e = moment(this.serviceSetting.chatResponseRequiredTime[i].endedAt)
                this.serviceSetting.chatResponseRequiredTime[i].s_Hour = s.hours()
                this.serviceSetting.chatResponseRequiredTime[i].s_Minute = s.minutes()
                this.serviceSetting.chatResponseRequiredTime[i].e_Hour = e.hours()
                this.serviceSetting.chatResponseRequiredTime[i].e_Minute = e.minutes()
            }
            this.chatResponseRequiredTime = this.serviceSetting.chatResponseRequiredTime
        },
        async loadAccountList() {
            if (this.hospitalID == undefined || this.hospitalID == '') {
                return
            }
            let result = await this.API.Hospital.getDoctors({
                hospitalID: this.hospitalID
            })
            if (result.data.code != 0 || result.data.data == undefined) {
                console.log(result)
                return
            }

            for (let i = 0; i < result.data.data.length; i++) {
                result.data.data[i].isSelected = false
            }

            this.accountList = result.data.data
        },
        async updateServiceSetting() {
            if (this.serviceSetting == undefined || this.serviceSetting._id == undefined) {
                return
            }

            this.isLoading = true

            let model = {
                _id: this.serviceSetting._id,
                hospitalID: this.serviceSetting.hospitalID,
                usingChatResponseRequired: this.usingChatResponseRequired
            }

            // chat response required users parse
            let list = []
            for (let i = 0; i < this.accountList.length; i++) {
                if (this.accountList[i].isSelected == true) {
                    list.push(this.accountList[i]._id)
                    continue
                }
            }
            model.chatResponseRequiredUsers = list

            // chat response required time parse
            for (let i = 0; i < this.chatResponseRequiredTime.length; i++) {
                let s = moment(0).startOf('days')
                s.add(this.chatResponseRequiredTime[i].s_Hour, 'hours')
                s.add(this.chatResponseRequiredTime[i].s_Minute, 'minutes')
                let e = moment(0).startOf('days')
                e.add(this.chatResponseRequiredTime[i].e_Hour, 'hours')
                e.add(this.chatResponseRequiredTime[i].e_Minute, 'minutes')
                this.chatResponseRequiredTime[i].startedAt = s.format()
                this.chatResponseRequiredTime[i].endedAt = e.format()
            }
            model.chatResponseRequiredTime = this.chatResponseRequiredTime
            console.log(model)

            let result = await this.API.ServiceSetting.updateServiceSetting(model)
            if (result.data.code != 0) {
                console.log(result)
                this.isLoading = false
                return
            }

            // TODO: show Toast

            this.isLoading = false
            this.action_Close()
        },

        // MARK: - Data parse
        parseSelectedAccount() {
            if (this.serviceSetting == undefined || this.serviceSetting.chatResponseRequiredUsers == undefined) {
                return
            }
            if (this.accountList == undefined) {
                return
            }
            let idList = this.serviceSetting.chatResponseRequiredUsers
            for (let i = 0; i < idList.length; i++) {
                for (let j = 0; j < this.accountList.length; j++) {
                    if (this.accountList[j]._id == idList[i]) {
                        this.accountList[j].isSelected = true
                        continue
                    }
                }
            }
        },

        // MARK: - Actions
        action_Close() {
            this.$emit('action_Close')
        },
        action_Save() {
            this.updateServiceSetting()
        },
        action_AddTime(index) {
            if (this.chatResponseRequiredTime == undefined) {
                this.chatResponseRequiredTime = []
            }
            let newModel = {
                dayIndex: 1,
                startedAt: moment(0).startOf('days').format(),
                endedAt: moment(0).startOf('days').format(),
                s_Hour: 0,
                s_Minute: 0,
                e_Hour: 0,
                e_Minute: 0
            }
            if (index != undefined) {
                this.chatResponseRequiredTime.splice(index, 0, newModel)
            } else {
                this.chatResponseRequiredTime.push(newModel)
            }
        },
        action_RemoveTime(index) {
            this.chatResponseRequiredTime.splice(index, 1)
        }
    }
}
</script>
