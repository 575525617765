<template>
    <div class="pop_overlay_bg" v-if="alhospitalinfopopup.isShow">
        <ToastComponent :isShow="showErrorMessage" :noticeMessage="`저장되었습니다.`" />
        <div class="hospitalInfo_pop_wrap">
            <PopupTitleSection title="병원 정보 확인" @action_Close="action_Close" />
            <div class="inner_wrap">
                <div class="content_wrap">
                    <div class="title">병원명</div>
                    <div class="content">{{ hospitalName }}</div>
                </div>
                <div class="content_wrap">
                    <div class="title">애프터닥 병원 ID</div>
                    <div class="content">{{ hospitalID }}</div>
                </div>
                <div class="content_wrap">
                    <div class="title">전능 연동</div>
                    <div class="content">
                        <div class="input_wrap">
                            <input type="radio" v-model="isAlConnect" :value="false" />
                            <span class="checkmark"></span>연동
                        </div>
                        <div class="input_wrap">
                            <input type="radio" v-model="isAlConnect" :value="true" />
                            <span class="checkmark"></span>해제
                        </div>
                    </div>
                </div>
                <div class="content_wrap">
                    <div class="title">고객 번호</div>
                    <input :class="inputStyle" v-model="alhospital.id" placeholder="고객 번호" :readonly="!isModifyState" />
                </div>
                <div class="content_wrap">
                    <div class="title">연동 접근 토큰값</div>
                    <input :class="inputStyle" v-model="alhospital.token" placeholder="토큰 값" :readonly="!isModifyState" />
                </div>
            </div>
            <div class="btn_wrap">
                <button class="btn btn_l btn_gray" @click="action_Close">닫기</button>
                <button class="btn btn_l btn_blue" @click="action_Save">저장</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ToastComponent from '@/views/main/ShareComponents/ToastComponent.vue'
import PopupTitleSection from '@/views/main/ShareComponents/PopupTitleSection.vue'

export default {
    components: { PopupTitleSection, ToastComponent },
    data() {
        return {
            inputStyle: '',
            isAlConnect: false, // 전능 연동 해제 여부 isRemoved
            isModifyState: false,
            hospitalObj: undefined,
            alhospital: {
                id: '',
                token: ''
            },
            showErrorMessage: false
        }
    },

    watch: {
        'alhospitalinfopopup.isShow': {
            immediate: true,
            handler: function (newValue) {
                if (newValue == true) {
                    this.loadIntegration()
                    this.API_ALHospitalSetting()
                    this.addWindowEvent()
                }
            }
        },
        isAlConnect: {
            immediate: true,
            deep: true,
            handler: function (newValue) {
                if (newValue == false) { this.isModifyState = true }
                if (newValue == true) { this.isModifyState = false }
            }
        },
        isModifyState: {
            immediate: true,
            deep: true,
            handler: function (newValue) {
                newValue ? (this.inputStyle = 'content_input_active') : (this.inputStyle = 'content_input')
            }
        }
    },
    computed: {
        ...mapState(['alhospitalinfopopup']),

        hospital: function () {
            let alhospital = this.alhospitalinfopopup
            if (alhospital == undefined) { return }
            if (alhospital.selectedHospital == undefined) { return }
            return alhospital.selectedHospital
        },
        hospitalID: function () {
            let alhospital = this.alhospitalinfopopup.selectedHospital
            if (alhospital == undefined) { return }
            if (alhospital._id == undefined) { return }
            return alhospital._id
        },
        hospitalName: function () {
            let alhospital = this.alhospitalinfopopup.selectedHospital
            if (alhospital == undefined) { return }
            if (alhospital.shortName == undefined) { return }
            return alhospital.shortName
        },
        hospitalintegrationSetting: function () {
            if (this.hospital == undefined) { return }
            if (this.hospital.integrationSetting == undefined) { return }
            return this.hospital.integrationSetting
        }
    },
    methods: {
        ...mapActions(['setAlhospitalInfoPopupState']),

        // MARK: - Network
        async loadIntegration() {
            this.isLoading = true

            let model = {
                hospitalID: this.hospitalID
            }
            let result = await this.API.AlService.getIntegrationSetting(model)
            if (result.data.data == null) { this.isAlConnect = true } 
            else {
                result = result.data.data
                this.isAlConnect = result.isRemoved
                if (!result.isRemoved) { this.isModifyState = true }
            }
            this.isLoading = false
        },

        async API_ALHospitalSetting() {
            if(this.hospitalID == undefined) {return}
            let model = {
                hospitalID: this.hospitalID
            }
            try {
                let result = await this.API.AlService.getHospital(model)
                this.hospitalObj = result.data
                this.alhospital.id = result.data.sdHospitalID

                if (this.hospitalintegrationSetting.clientToken) {
                    this.alhospital.token = this.hospitalintegrationSetting.clientToken
                }
            } catch (error) {
                console.log('alhospitalsetting', error)
            }
        },

        // 병원 ID 수정
        async API_ALHostpitalIdUpdate() {
            if (this.hospital == undefined) {
                return
            }

            try {
                if (this.hospital.integrationSetting == undefined) {
                    let model = {
                        hospitalID: this.hospitalID,
                        sdHospitalID: this.alhospital.id
                    }
                    let result = await this.API.AlService.createHospitalID(model)
                    console.log(result)
                } else {
                    let model = {
                        _id: this.hospitalObj._id,
                        hospitalID: this.hospitalID,
                        sdHospitalID: this.alhospital.id,
                        isRemoved: this.isAlConnect
                    }
                    let result = await this.API.AlService.updateHospitalID(model)
                    console.log(result)
                }
            } catch (error) {
                console.error(error)
            }
        },

        // 병원 연동, 토큰 수정
        async API_ALHospitalFeatureConfig() {
            if (this.hospital == undefined) {
                return
            }

            try {
                if (this.hospital.integrationSetting == undefined) {
                    let model = {
                        hospitalID: this.hospitalID,
                        clientToken: this.alhospital.token,
                        isRemoved: this.isAlConnect,
                        usingRegist: false,
                        usingDashboard: true,
                        usingReservation: true,
                        customObject: {
                            "defaultLabelID": [],
                            "defaultCancelID": []
                        },                    
                    }
                    let result = await this.API.AlService.createIntegrationSetting(model)
                    console.log(result)
                } else {
                    let model = {
                        hospitalID: this.hospitalID,
                        _id: this.hospitalintegrationSetting._id,
                        clientToken: this.alhospital.token,
                        isRemoved: this.isAlConnect
                    }
                    let result = await this.API.AlService.updateIntegrationSetting(model)
                    console.log(result)
                }
            } catch (error) {
                console.error(error)
            }
        },

        // MARK: - Action
        async action_Save() {
            try {
                this.API_ALHospitalFeatureConfig()
                this.API_ALHostpitalIdUpdate()
                this.show_ToastMessage()
            }
            catch (err) { console.log(err) }
            this.action_Close() 

            setTimeout(() => { 
                this.$emit('action_Close') 
            }, 500)
        },

        async action_Close() {
            this.isModifyState = false
            this.hospitalObj = undefined
            this.alhospital.id = ''
            this.alhospital.token = ''

            await this.setAlhospitalInfoPopupState({
                isShow: false,
                selectedHospital: undefined
            })
        },
        show_ToastMessage() {
            this.showErrorMessage = true

            setTimeout(() => {
                this.showErrorMessage = false
            }, 1000)
        },

        action_Modify() {
            this.isModifyState = true
        },

        keyDownHandler(event) {
            if (event.keyCode == 27) {
                this.action_Close()
            }
        },

        addWindowEvent() {
            window.addEventListener('keyup', this.keyDownHandler)
        }
    }
}
</script>