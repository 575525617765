<template>
    <div class="pagination_wrap">
        <div class="pagination">
            <div>
                <a :class="{ disabled: currentPage == 0 }" v-on:click="moveFirst"><v-icon>mdi-chevron-double-left</v-icon></a>
            </div>
            <div>
                <a :class="{ disabled: currentPage == 0 }" v-on:click="movePre"><v-icon>mdi-chevron-left</v-icon></a>
            </div>
            <div v-if="indexes.length > 0">
                <div v-for="(number, index) in indexes" :key="index">
                    <a :class="{ on: currentPage + 1 == number }" v-if="number >= rowFirstIndex" v-on:click="move(number)">{{ number }}</a>
                </div>
            </div>
            <div v-if="indexes.length == 0">
                <a :class="{ on: currentPage + 1 == 1 }" v-on:click="move(1)">{{ 1 }}</a>
            </div>
            <div>
                <a :class="{ disabled: currentPage >= lastIndex }" v-on:click="moveNext"><v-icon>mdi-chevron-right</v-icon></a>
            </div>
            <div>
                <a :class="{ disabled: currentPage >= lastIndex }" v-on:click="moveLast"><v-icon>mdi-chevron-double-right</v-icon></a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'paginationComponent',
    props: {
        // 전체 row 개수
        totalCount: Number,

        // 현재 페이지
        currentPage: Number,

        // 한 페이지당 표기할 row 개수
        countPerPage: Number,

        // 한줄에 표기할 페이지 개수
        countPerRow: Number
    },
    data() {
        return {}
    },
    created() {},
    watch: {
        currentPage(newValue) {
            if (newValue == this.currentPage) {
                return
            }
            if (newValue > this.lastIndex) {
                newValue = this.lastIndex
            }
            if (newValue < 0) {
                newValue = 0
            }

            this.move(newValue)
        }
    },
    computed: {
        indexes: function () {
            let current = this.currentPage
            let first = 0
            let last = this.lastIndex
            console.log('lastIndex = ', last)

            let start = 0
            let end = last

            if (current - first > 2) {
                start = current - 2
            }
            end = start + (this.countPerRow - 1)

            if (end >= last) {
                end = last
                start = end - (this.countPerRow - 1)
                if (start <= 0) {
                    start = 0
                }
            }

            let indexes = []
            for (var i = start; i <= end; i++) {
                var number = i + 1

                indexes.push(number)
            }
            return indexes
        },
        // 페이지 목록에 출력될 첫번째 인덱스
        rowFirstIndex: function () {
            if (this.indexes.length > 0) {
                return this.indexes[0]
            } else {
                return 0
            }
        },
        // 페이지 목록에 출력될 마지막 인덱스
        rowLastIndex: function () {
            if (this.indexes.length > 0) {
                return this.indexes[this.indexes.length - 1]
            } else {
                return 0
            }
        },
        firstIndex: function () {
            return 0
        },
        lastIndex: function () {
            return Math.ceil(this.totalCount / this.countPerPage) - 1
        }
    },
    methods: {
        move(page) {
            if (page - 1 == this.currentPage) {
                return
            }
            this.$emit('changePage', page - 1)
        },
        movePre() {
            let index = this.currentPage - 1
            if (index < this.firstIndex) {
                index = this.firstIndex
            }
            if (index == this.currentPage) {
                return
            }
            this.$emit('changePage', index)
        },
        moveNext() {
            let index = this.currentPage + 1
            if (index > this.lastIndex) {
                index = this.lastIndex
            }
            if (index == this.currentPage || index < 0) {
                return
            }
            this.$emit('changePage', index)
        },
        moveFirst() {
            let index = this.firstIndex
            if (index == this.currentPage) {
                return
            }
            this.$emit('changePage', index)
        },
        moveLast() {
            let index = this.lastIndex
            if (index == this.currentPage || index < 0) {
                return
            }
            this.$emit('changePage', index)
        }
    }
}
</script>
