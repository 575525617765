<template>
    <div class="pop_overlay_bg" v-if="isShow">
        <div class="answeringChat_pop_wrap">
            <PopupTitleSection title="설정 정보 확인" @action_Close="action_Close" />
            <div class="inner_wrap">
                <div class="content_wrap">
                    <div class="title">병원명</div>
                    <div class="content">{{ hospitalName }}</div>
                </div>
                <div class="content_wrap">
                    <div class="title">애프터닥 병원 ID</div>
                    <div class="content">{{ hospitalID }}</div>
                </div>
                <div class="content_wrap">
                    <div class="title">자동 응대 채팅 기능 사용여부</div>
                    <div>{{ isUsed }}</div>
                </div>
                <div class="content_wrap">
                    <div class="title">병원 운영시간 내 메시지 내용</div>
                        <textarea v-model="businessHourMessage" readonly />
                </div>
                <div class="content_wrap">
                    <div class="title">병원 운영시간 외 메시지 내용</div>
                        <textarea v-model="nonBusinessHourMessage" readonly />
                </div>
                <div class="content_wrap">
                    <div>
                        <div class="title">메세지 발송 대기시간</div>
                        <div class="description">메시지 발송까지의 분 단위 대기 시간입니다.</div>
                    </div>
                    <input class="interval_input" type="number" readonly v-model="pendingInterval"/>
                </div>
                <div class="form_list">
                    <div class="title" style="color: black">병원 진료 시간</div>
                    <div class="cont">
                        <div class="mgt_15" v-for="(t, index) in careSchedule" :key="index">
                            <select class="input_s" v-model="t.dayIndex" disabled>
                                <option value="1">월</option>
                                <option value="2">화</option>
                                <option value="3">수</option>
                                <option value="4">목</option>
                                <option value="5">금</option>
                                <option value="6">토</option>
                                <option value="0">일</option> 
                            </select>
                            <select class="input_s" v-model="t.s_Hour" disabled>
                                <option :value="index" v-for="(i, index) in 24" :key="i">{{ ('0' + index).slice(-2) }}시</option>
                            </select>
                            <select class="input_s" v-model="t.s_Minute" disabled>
                                <option :value="index" v-for="(i, index) in 60" :key="i">{{ ('0' + index).slice(-2) }}분</option>
                            </select>
                            ~
                            <select class="input_s" v-model="t.e_Hour" disabled>
                                <option :value="index" v-for="(i, index) in 24" :key="i">{{ ('0' + index).slice(-2) }}시</option>
                            </select>
                            <select class="input_s" v-model="t.e_Minute" disabled>
                                <option :value="index" v-for="(i, index) in 60" :key="i">{{ ('0' + index).slice(-2) }}분</option>
                            </select>
                        </div>                                
                    </div>
                </div>
            </div>
            <div class="btn_wrap">
                <button class="btn btn_l btn_gray" @click="action_Close">닫기</button>
            </div>
        </div>
    
    </div>
</template>


<script>
const moment = require('@/util/MPMoment')
import PopupTitleSection from '../../../../ShareComponents/PopupTitleSection.vue';

export default {
    components:{PopupTitleSection}, 
    props: {
        isShow:Boolean,
        selectedHospital: Object
    },
    data() {
        return {
            settingData: undefined,
            careSchedule: []
        }
    },
    watch: {
        selectedHospital:{
            immediate: true,
            handler: function(newValue) {
                if(newValue == undefined) {return}
                this.LoadAnsweringChatSettingInfo(newValue._id)
            }
        }
    },
    computed: {
        hospitalName: function() {
            if(this.selectedHospital == undefined) {return}
            return this.selectedHospital.shortName
        },
        hospitalID: function() {
            if(this.selectedHospital == undefined) {return}
            return this.selectedHospital._id
        },
        isUsed: function() {
            if(this.settingData == undefined) {return}
            if(this.settingData.usingAnsweringChat == true) {return '사용 중'}
            return '사용 안 함'
        },
        businessHourMessage: function() {
            if(this.settingData == undefined) {return}
            return this.settingData.businessHourMessage    
        },
        nonBusinessHourMessage: function() {
            if(this.settingData == undefined) {return}
            return this.settingData.nonBusinessHourMessage   
        },
        pendingInterval: function() {
            if(this.settingData == undefined) {return}
            return this.settingData.interval
        }
    },
    methods: {
        async LoadAnsweringChatSettingInfo(r) {
            let model = {
                hospitalID: r
            }
            let result = await this.API.AnsweringChat.find(model)
            result = result.data.data[0]
            this.settingData = result
            for(let i = 0; i < result.careSchedule.length; i++) {
                let s = moment(result.careSchedule[i].startedAt)
                let e = moment(result.careSchedule[i].endedAt)
                result.careSchedule[i].s_Hour     = s.hours()
                result.careSchedule[i].s_Minute   = s.minutes()
                result.careSchedule[i].e_Hour     = e.hours()
                result.careSchedule[i].e_Minute   = e.minutes()
            } this.careSchedule = result.careSchedule
        },
        action_Close() { this.$emit('action_Close') }
    }
}
</script>