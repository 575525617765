const state = {
    isShow: false,
    selectedANurse: {},
    selectedANurseID: ''
}

const actions = {
    setANurseAccountSettingPopupState: (store, param) => {
        store.commit('muANurseAccountSettingPopupState', param)
    }
}

const mutations = {
    muANurseAccountSettingPopupState: (state, param) => {
        state.isShow = param.isShow
        state.selectedANurse = param.selectedANurse
        state.selectedANurseID = param.selectedANurseID
    }
}

export default { state, actions, mutations }
