<template>
    <div class="pop_title">
        <span class="title">{{ title }}</span>
        <a class="mgl_auto" @click="action_Close">
            <v-icon large>mdi-close</v-icon>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        title: String
    },
    methods: {
        action_Close() {
            this.$emit('action_Close')
        }
    }
}
</script>
