<template>
    <tr>
        <td :title="data?.hospitalID?.shortName">{{ getHospitalName }}</td>
        <td :title="data?.hserviceID?.name">{{ getReceiverName }}</td>
        <td :title="data?.message">{{ getMessage }}</td>
        <td>{{ getStatus }}</td>
        <td>{{ getCreatedAt }}</td>
        <td>{{ getSendDateTime }}</td>
        <td>{{ getSendedAt }}</td>
        <td>{{ getCanceledAt }}</td>
        <td>{{ getCancelReason }}</td>
    </tr>
</template>

<script>
const moment = require('moment')

export default {
    props: {
        data: Object
    },
    data() {
        return {

        }
    },
    computed: {
        getHospitalName: function() {
            return this.getSubStringValue(this.data?.hospitalID?.shortName, 10)
        },
        getReceiverName: function() {
            return this.getSubStringValue(this.data?.hserviceID?.name, 4)
        },
        getMessage: function() {
            return this.getSubStringValue(this.data?.message, 25)
        },
        getStatus: function() {
            switch (this.data.status) {
                case 'waiting':
                    return '대기'
                case 'sended':
                    return '발송'
                case 'canceled':
                    return '취소'
                default:
                    return '-'
            }
        },
        getCreatedAt: function() {
            if (!this.data?.createdAt) { return '-' }
			return moment(this.data.createdAt).format('YYYY-MM-DD HH:mm')
        },
        getSendDateTime: function() {
            if (!this.data?.sendDateTime) { return '-' }
			return moment(this.data.sendDateTime).format('YYYY-MM-DD HH:mm')
        },
        getSendedAt: function() {
            if (!this.data?.sendedAt) { return '-' }
			return moment(this.data.sendedAt).format('YYYY-MM-DD HH:mm')
        },
        getCanceledAt: function() {
            if (!this.data?.canceledAt) { return '-' }
			return moment(this.data.canceledAt).format('YYYY-MM-DD HH:mm')
        },
        getCancelReason: function() {
            switch (this.data.cancelReason) {
                case 'start service':
                    return '서비스 시작'
                case 'suspended by user with remove user information':
                    return '정보 삭제 요청'
                case 'suspended by user with suspend service':
                    return '모바일 서비스 중단'
                case 'suspended by hospital':
                    return '병원 서비스 중단'
                case 'suspended by changed setting in hospital':
                    return '병원설정 변경'
                default:
                    return '-'
            }
        },
    },
    methods: {
        getSubStringValue(str, maxLength) {
            let count = str?.length ?? 0
            let returnValue = str?.substring(0, maxLength)?.trimEnd() ?? '-'
            if (count > maxLength) { returnValue += '...' }
            return returnValue
        }
    }
}
</script>