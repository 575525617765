<template>
    <tr>
        <td width="120px">
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <div></div>
                <a @click="action_Up">
                    <v-icon style="font-size: 24px;">mdi-arrow-up-bold-outline</v-icon>
                </a>
                <a @click="action_Down">
                    <v-icon style="font-size: 24px;">mdi-arrow-down-bold-outline</v-icon>
                </a>
                <div></div>
            </div>
        </td>
        <td width="180px">
            <select class="input_s w_full" v-model="newChat.chatType">
                <option v-for="(value, name, index) in chatType" :key="index" :value="value" :disabled="!allowChatTypes(name)">{{ name }}</option>
            </select>
        </td>
        <td>
            <div class="txt_preview_wrap" v-if="newChat.chatType==chatType.text">
                <textarea class="w_full" v-model="newChat.content"></textarea>
                <pre class="txt_preview" style="word-wrap: break-word;">{{ newChat.content }}</pre>
            </div>
            <div class="img_preview_wrap" v-if="newChat.chatType==chatType.image" style="display: flex; justify-content: space-between; align-items: center">
                <span v-for="fileID in newChat.fileIDs" :key="fileID" @mouseenter="showImage(fileID)" @mouseleave="hideImage">ID: {{ fileID }}</span>
                <a class="btn btn_s btn_blue" @click="action_SelectFile">파일 선택</a>
                <input ref="fileinput" v-show="false" type="file" accept="image/*" class="input_s w_full" @change="change_File($event)">
                <img 
                    class="img_preview"
                    :src="imagePath"
                    v-if="isShowImage"
                    style="max-width: 200px; max-height: 200px;"
                >
            </div>
        </td>
        <td width="66px">
            <a class="btn btn_s btn_gray_line" @click="action_Remove()">삭제</a>
        </td>
    </tr>
</template>


<script>
import ChatType from '../../../../../../../enum/ChatType'

export default {
    props: {
        chat: Object,
        index: Number
    },
    data() {
        return {
            isShowText: false,
            isShowImage: false,
            imagePath: undefined,

            newChat: {
                chatType: undefined,
                content: undefined,
                fileIDs: undefined,
            }
        }
    },
    watch: {
        chat: {
            immediate: true,
            handler: function(newValue) {
                this.newChat = JSON.parse(JSON.stringify(newValue))
            }
        },
        'newChat.chatType': {
            immediate: true,
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.updateValue()
            }
        },
        'newChat.content': {
            immediate: true,
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.updateValue()
            }
        },
        'newChat.fileIDs': {
            immediate: true,
            deep: true,
            handler: function(newValue, oldValue) {
                if (newValue == undefined) { return }
                if (oldValue == undefined) {/**/}
                else if (newValue.length == 0 && oldValue.length == 0) { return }
                else if (newValue.length > 0 && oldValue.length > 0) {
                    if (newValue[0] == oldValue[0]) { return }
                }
                this.updateValue()
            }
        }
    },
    computed: {
        typeValue: function() {
            if (this.chat == undefined) { return '-' }
            let returnValue = ChatType[this.chat.chatType]
            if (returnValue == undefined) { return '-' }
            return returnValue
        },
        content: function(){
            if (this.newChat.chatType != ChatType.text) { '-' }
            return this.newChat.content
        },
        chatType: function() {
            return ChatType
        },
        allowChatTypes: function() {
            return (type) => {
                if (type == 'text' || type == 'image') { return true }
                return false
            }
        }
    },
    methods: {
        async showImage(fileID) {
            this.isShowImage = true
            try {
                let result = await this.API.File.getFile({_id: fileID})
                this.imagePath = result.data.data[0].paths[0]
            } catch(err) {
                console.log(err)
            }
        },
        hideImage() {
            this.isShowImage = false
        },
        showText() {
            this.isShowText = true
        },
        hideText() {
            this.isShowText = false
        },

        updateValue() {
            if (this.newChat.chatType == ChatType.text) { delete this.newChat.fileIDs }
            else if (this.newChat.chatType == ChatType.image) { delete this.newChat.content }
            this.$emit('updateChat', this.newChat, this.index)
        },


        action_Up() {
            this.$emit('upTo', this.index)
        },
        action_Down() {
            this.$emit('downTo', this.index)
        },
        action_Remove() {
            this.$emit('remove', this.index)
        },
        action_SelectFile() {
            this.$refs['fileinput'].click()
        },

        async change_File(event) {
            let result = await this.uploadImageFile(event)
            if (result != undefined && result != ''){
                if (this.newChat.fileIDs == undefined) { this.newChat.fileIDs = [] }
                this.newChat.fileIDs.splice(0, this.newChat.fileIDs.length)
                let tempIDs = [result._id]
                for (let i=0 ; i<tempIDs.length ; i++) {
                    this.newChat.fileIDs.push(tempIDs[i])
                }
            }
            this.updateValue()
        },

        // file upload
        async uploadImageFile(event) {
            let files = event.currentTarget.files
            let sizes = []

            for (let i = 0; i < files.length; i++) {
                let file = files[i]
                var imageReader = new FileReader()
                imageReader.onload = function (e) {
                    var img = new Image()
                    img.src = e.target.result

                    img.onload = function () {
                        var w = this.width
                        var h = this.height
                        let size = {
                            width: w,
                            height: h
                        }
                        sizes.push(size)
                    }
                }
                // console.log(file)
                imageReader.readAsDataURL(file)
            }
            let mimeType = 'image/*'
            let type = 300
            let userID = this.$store.state.user._id
            let result = await this.MPAWS.uploadFiles(files, sizes, mimeType, type, userID)

            if (result.data.code != 0) {
                console.log(result.data.message)
                return
            }
            console.log(result.data.data)
            // return result.data.data._id
            return result.data.data
        },
    }
}
</script>