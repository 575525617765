import axios from 'axios'
import URL from './URL'
import Util from './Util'

const create = async function (m) {
    return axios({
        method: 'POST',
        url: URL.TREATMENTCATEGORY_CREATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const update = async function (m) {
    return axios({
        method: 'POST',
        url: URL.TREATMENTCATEGORY_UPDATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const remove = async function (m) {
    return axios({
        method: 'POST',
        url: URL.TREATMENTCATEGORY_REMOVE,
        data: m,
        headers: Util.getHeaders()
    })
}

export default {
    create: create,
    update: update,
    remove: remove
}
